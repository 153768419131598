import { useState, useCallback, useEffect } from 'react'

const storageName = 'user' //userData
const expiresIn = 8 * 60 * 60000

export const useAuth = () => {
    const [token, setToken] = useState(null)
    const [ready, setReady] = useState(null)
    const [userId, setUserId] = useState(null)
    const [role, setRole] = useState(null)
    const [company, setCompany] = useState(null)

    let check



    const login = useCallback((jwtToken, id, role, companyName) => {
        setToken(jwtToken)
        setUserId(id)
        setRole(role)
        setCompany(companyName)
        localStorage.setItem(storageName, JSON.stringify({
            userId: id, token: jwtToken, role, company: companyName, tokenExp: new Date().getTime() + expiresIn
        }))
        check = setInterval(() => {
            if (check && isTokenExpired()) {
                logout();
            }
        }, 10 * 60000); //10 min
    }, [])

    const logout = useCallback(() => {
        setToken(null)
        setUserId(null)
        setRole(null)
        setCompany(null)
        localStorage.removeItem(storageName)

        clearInterval(check);
    }, [])

    useEffect(() => {
        const data = JSON.parse(localStorage.getItem(storageName))
        if (data && data.token) {
            login(data.token, data.userId, data.role, data.company)
        }
        setReady(true)

    }, [login, logout])

    const isTokenExpired = () => {
        const data = JSON.parse(localStorage.getItem(storageName))
        let expirationTime = data?.tokenExp
        return expirationTime && new Date().getTime() > expirationTime;
    };

    const checkRole = (array) => {
        for (let i in role) {
            if (array.includes(role[i])) {
                return true;
            }
        }
        return false;
    }
    return { login, logout, token, userId, role, ready, checkRole, company }
}