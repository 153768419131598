import React, { useContext, useEffect, useState } from "react";
import { Table, Flex, DatePicker } from "antd";
import { useHttp } from "../../hooks/http.hook";
import { AuthContext } from "../../context/AuthContext";
// import { fetchWorkerLoad } from "./api"; // Function to fetch worker's load data

const WorkerLoadReport = () => {
  // const [workerLoad, setWorkerLoad] = useState([]);

  const [report, setReport] = useState([]);
  const { request, loading } = useHttp();
  const { token } = useContext(AuthContext);

  const fetchPeriodReport = async (from, to) => {
    try {
      const reportData = await request(
        "o/api/report/workersatperiod",
        "POST",
        { from, to },
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setReport(reportData);

    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  useEffect(() => {
    showPeriod();
  }, [])

  const showPeriod = (el) => {
    let startDate, finishDate
    if (Array.isArray(el)) {
      startDate = el[0] ? new Date(el[0]) : new Date('2000-01-01');
      finishDate = el[1] ? new Date(el[1]) : new Date();
    } else {
      let date = new Date();
      startDate = new Date(date.getFullYear(), date.getMonth(), 1);
      finishDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }

    fetchPeriodReport(startDate, finishDate);
  }

  // Columns configuration
  const columns = [
    {
      title: "#",
      width: "1%",
      key: "num",
      render: (_, r, index) => <div>{index + 1}</div>,
      // footer: (data) => {
      //   return <div>ИТОГО</div>
      // }
    },
    {
      title: 'Сотрудник',
      dataIndex: 'worker',
      key: 'worker',

    },
    {
      title: 'Создано',
      dataIndex: 'created',
      key: 'created',
      width: '15%',
      footer: (data) => {
        return <div>{data.reduce((a, b) => a + b, 0)}</div>
      }
    },
    {
      title: 'Запланировано',
      dataIndex: 'planned',
      key: 'planned',
      width: '15%'
    },
    {
      title: 'Назначено',
      dataIndex: 'assigned',
      key: 'assigned',
      width: '15%'
    },
    {
      title: 'В работе',
      dataIndex: 'inProgress',
      key: 'inProgress',
      width: '15%'
    },
    {
      title: 'Выполнено',
      dataIndex: 'completed',
      key: 'completed',
      width: '15%',
      sorter: (a, b) => +a.completed - +b.completed,
      sortDirections: ['ascend', 'descend'],
    },
    // Add more columns here if needed
  ];

  const calculateColumnTotal = (data, dataIndex) => {
    let total = 0;
    data.forEach(item => {
      total += item[dataIndex] || 0;
    });
    return total;
  };

  const columnTotal = {};
  columns.forEach(column => {
    columnTotal[column.key] = calculateColumnTotal(report, column.dataIndex);
  });



  // return <Table dataSource={report} columns={columns} />;
  return (
    <>
      <Flex gap='middle'>

        <DatePicker.RangePicker
          format={'DD.MM.YYYY'}
          popupStyle={{ maxWidth: '200px' }}
          style={{ marginBottom: '1rem' }}
          label="Выберите период"
          popupClassName='rangeDatePicker'
          onChange={showPeriod}
        />
        {/* <Button type="primary" onClick={showPeriod}>Показать</Button> */}
      </Flex>
      <Table
        dataSource={report}
        columns={columns}
        loading={loading}
        size="small"
        rowKey={"index"}
        pagination={false}
        scroll={{ x: 600 }}
        style={{ 'maxWidth': '900px', paddingBottom: '2rem', textAlign: 'left' }}
        // className="tableAlignCenter"
        summary={(pageData) => {
          return (
            <>
              <Table.Summary.Row style={{ backgroundColor: '#ededed' }}>
                <Table.Summary.Cell />
                <Table.Summary.Cell><b></b></Table.Summary.Cell>
                <Table.Summary.Cell><b>{columnTotal.created}</b></Table.Summary.Cell>
                <Table.Summary.Cell><b>{columnTotal.planned}</b></Table.Summary.Cell>
                <Table.Summary.Cell><b>{columnTotal.assigned}</b></Table.Summary.Cell>
                <Table.Summary.Cell><b>{columnTotal.inProgress}</b></Table.Summary.Cell>
                <Table.Summary.Cell><b>{columnTotal.completed}</b></Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </>
  )
};

export default WorkerLoadReport;
