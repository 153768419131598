import React, { useContext, useState, useEffect } from 'react';
import { List, Table, Space, Tooltip, Popconfirm, Typography } from 'antd';
import { DeleteOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { AuthContext } from '../../context/AuthContext';
import { GetColumnSearchProps } from '../SearchLine';

const { Text } = Typography


export const EquipmentList = ({ equipments, onEdit, onView, onDelete, loading }) => {

    const [tableCount, setTableCount] = useState(null)

    const { checkRole } = useContext(AuthContext)
    const windowSize = useSize()
    useEffect(() => {
        window.dispatchEvent(new Event("resize"))
    }, [])

    const columns = [
        {
            title: '№',
            key: 'num',
            render: (_, rec, index) => (
                <div>{index + 1}</div>
            ),
            width: (equipments.length).toString().length * 20,
        },
        {
            title: 'Наименование',
            dataIndex: 'description',
            key: 'description',
            ...GetColumnSearchProps('description', 'наименованию'),
            render: (text) => (<Text ellipsis>{text}</Text>),
            sorter: (a, b) => a?.description?.toLowerCase().localeCompare(b?.description.toLowerCase()),
            defaultSortOrder: 'ascend',
        },
        {
            title: 'Артикул',
            key: 'partno',
            dataIndex: 'partno',
            ...GetColumnSearchProps('partno', 'артикулу'),

            width: 100,
        },

        {
            title: 'ед.изм.',
            dataIndex: 'unitMeasure',
            key: 'unitMeasure',
            width: 70
        },
        {
            title: 'Действия',
            key: 'action',
            width: 120,
            render: (text, record) => (
                <Space size={'middle'}  >

                    <SearchOutlined
                        onClick={() => onView(record)}
                    />
                    {(checkRole(['admin'])) && <Tooltip title='Редактировать карточку' color={'geekblue'} placement="top">
                        <EditOutlined
                            onClick={() => {
                                onEdit(record)
                            }}
                        />
                    </Tooltip>}

                    {(checkRole(['admin'])) && <Popconfirm
                        title="Удаление записи"
                        description="Вы уверены, что хотите удалить эту запись?"
                        okText="Да"
                        cancelText="Нет"
                        onConfirm={() => onDelete(record._id)}
                    >
                        <DeleteOutlined style={{ color: 'red' }} />
                    </Popconfirm>}

                </Space >
            ),
        },
    ];

    return (
        <div style={{ marginTop: '1rem' }}>
            <Table
                columns={columns}
                dataSource={equipments}
                pagination={false}
                loading={loading}
                scroll={{ x: 550, y: +windowSize[1] - 220, }}
                size='small'
                rowKey='_id'
                footer={(data) => {
                    return < div style={{ textAlign: 'end' }}>Всего записей: {data?.length ?? 0}</div>
                }} // Modified from setting state
            />
        </div>

        // <List
        //     itemLayout="vertical"
        //     dataSource={equipments}
        //     renderItem={item => (
        //         <List.Item key={item._id}>
        //             <List.Item.Meta
        //                 title={item.description}
        //                 description={`Артикул: ${item.partno}, Ед.измерения: ${item.unitMeasure}`}
        //             />
        //             <div>{item.comment}</div>
        //         </List.Item>
        //     )}
        // />
    );
};




export const useSize = () => {
    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);

    useEffect(() => {
        const windowSizeHandler = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };
        window.addEventListener("resize", windowSizeHandler);

        return () => {
            window.removeEventListener("resize", windowSizeHandler);
        };
    }, []);

    return windowSize;
};

export default useSize;