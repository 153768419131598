import React, { useState, useRef } from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

export const GetColumnSearchProps = (dataIndex, sText) => {
    const [searchText, setSearchText] = useState('');
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys = [], confirm, dataIndex, clearFilters) => {
        confirm();
        setSearchText(selectedKeys[0] || '');
        if (!selectedKeys[0]) {
            clearFilters();
            searchInput.current?.focus();
        }
    };

    const dig = (obj, target) => {
        if (target && obj) {
            return target in obj
                ? obj[target]
                : Object.values(obj).reduce((acc, val) => {
                    if (acc !== undefined) return acc;
                    if (val && typeof val === 'object') return dig(val, target);
                    return undefined;
                }, undefined);
        }
        return undefined;
    };

    return {
        filterDropdown: ({ setSelectedKeys, selectedKeys = [], confirm, clearFilters }) => (
            <Input.Search
                ref={searchInput}
                autoFocus
                placeholder={`Поиск по ${sText}`}
                value={selectedKeys[0] || ''}
                onChange={(e) => {
                    setSelectedKeys(e.target.value ? [e.target.value] : []);
                }}
                onBlur={(e) => {
                    const key = e.target.value ? [e.target.value] : [];
                    handleSearch(key, confirm, dataIndex, clearFilters);
                }}
                onSearch={() => handleSearch(selectedKeys, confirm, dataIndex, clearFilters)}
                onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex, clearFilters)}
                allowClear
                style={{ width: 300, display: 'block' }}
            />
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => {
            if (!value || !record) return false;

            if (Array.isArray(dataIndex)) {
                const length = dataIndex.length;
                switch (length) {
                    case 3:
                        return record[dataIndex[0]]?.[dataIndex[1]]?.[dataIndex[2]]?.toString().toLowerCase().includes(value.toLowerCase());
                    case 2:
                        return record[dataIndex[0]]?.[dataIndex[1]]?.toString().toLowerCase().includes(value.toLowerCase());
                    case 1:
                        return record[dataIndex[0]]?.toString().toLowerCase().includes(value.toLowerCase());
                    default:
                        return false;
                }
            } else if (typeof dataIndex === 'string' && dataIndex.includes('userfield')) {
                // if (dataIndex.includes('userfield')) {
                //     vals = [...dataIndex.split('.')].splice(1)
                //     if (vals) {
                //         let searchObj = dig(record, vals[vals.length - 1])
                //         if (searchObj) { return JSON.stringify(searchObj).includes(value) }
                //     }
                const vals = [...dataIndex.split('.')].splice(1)
                const searchObj = dig(record, vals[vals.length - 1]);
                console.log('search', searchObj)
                return searchObj ? JSON.stringify(searchObj).toLowerCase().includes(value.toLowerCase()) : false;
            } else if (typeof dataIndex === 'string' && dataIndex.includes('whole#')) {
                const key = dataIndex.split('#')[1];
                const data = record[key];
                return data && typeof data === 'object'
                    ? JSON.stringify(data).toLowerCase().includes(value.toLowerCase())
                    : false;
            } else {
                return record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase());
            }
        },
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
    };
};



// import React, { useState, useRef, useMemo } from 'react';
// import { Input, } from 'antd';
// import { SearchOutlined } from '@ant-design/icons';

// export const GetColumnSearchProps = (dataIndex, sText) => {
//     const [searchText, setSearchText] = useState('');
//     const [searchedColumn, setSearchedColumn] = useState('');
//     const searchInput = useRef(null);

//     const handleSearch = (selectedKeys, confirm, dataIndex, clearFilters) => {
//         confirm();
//         setSearchText(selectedKeys[0]);
//         setSearchedColumn(dataIndex);
//         if (selectedKeys[0] === '') {
//             clearFilters();
//             searchInput.current.focus();
//         }

//     };

//     useMemo(() => {
//         setSearchText(searchText)
//     }, [searchText])

//     const dig = (obj, target) => {
//         if (target && obj) {
//             return target in obj
//                 ? obj[target]
//                 : Object.values(obj).reduce((acc, val) => {
//                     if (acc !== undefined) return acc;
//                     if (typeof val === 'object') return dig(val, target);
//                 }, undefined);
//         }


//     }




//     return {
//         filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
//             <Input.Search
//                 ref={searchInput}
//                 autoFocus
//                 placeholder={`Поиск по ${sText}`}
//                 value={selectedKeys[0]}
//                 onChange={(e) => {
//                     setSelectedKeys(e.target.value ? [e.target.value] : []);
//                     setSearchText(e.target.value)
//                 }}
//                 onBlur={(e) => {
//                     let key = e.target.value ? [e.target.value] : []
//                     handleSearch(key, confirm, dataIndex, clearFilters)
//                 }}
//                 onSearch={(e) => { handleSearch(selectedKeys, confirm, dataIndex, clearFilters) }}
//                 onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex, clearFilters)}
//                 allowClear
//                 style={{ width: 300, display: 'block' }}
//             />
//         ),
//         filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
//         // onFilter: (value, record) => {
//         //     let vals
//         //     if (record?.children?.length > 0 && dataIndex.includes('titleExt')) {
//         //         return (JSON.stringify(record?.children?.map(el => el?.list?.titleExt)).toLowerCase()).includes(value?.toLowerCase())
//         //     }
//         //     if (dataIndex.includes('userfield')) {
//         //         vals = [...dataIndex.split('.')].splice(1)
//         //         if (vals) {
//         //             let searchObj = dig(record, vals[vals.length - 1])
//         //             if (searchObj) { return JSON.stringify(searchObj).includes(value) }
//         //         }

//         //     } else if (dataIndex === 'whole#') {
//         //         if (typeof record?.machine === 'object') {

//         //             return (JSON.stringify(record?.machine).toLowerCase()).includes(value?.toLowerCase())
//         //         }
//         //     }
//         //     let condition = typeof (dataIndex) === 'object' ? dataIndex.length : null
//         //     if (condition) {
//         //         if (condition === 3) { return record[dataIndex[0]][dataIndex[1]][dataIndex[2]]?.toString().toLowerCase().includes(value?.toLowerCase()) }
//         //         if (condition === 2) { return record[dataIndex[0]][dataIndex[1]]?.toString().toLowerCase().includes(value?.toLowerCase()) }
//         //         if (condition === 1) { return record[dataIndex[0]]?.toString().toLowerCase().includes(value?.toLowerCase()) }
//         //     } else {

//         //         return record[dataIndex]?.toString().toLowerCase().includes(value?.toLowerCase())
//         //     }
//         // },
//         // onFilterDropdownOpenChange: (visible) => {
//         //     if (visible) {
//         //         setTimeout(() => searchInput?.current.select());
//         //     }
//         // },
//         onFilter: (value, record) => {

//             if (dataIndex.includes('userfield')) {
//                 const vals = [...dataIndex.split('.')].splice(1);
//                 const searchObj = dig(record, vals[vals.length - 1]);
//                 return searchObj ? JSON.stringify(searchObj).toLowerCase().includes(value.toLowerCase()) : false;
//             } else if (dataIndex.includes('whole#')) { //dataIndex === 'whole#'
//                 const data = dataIndex.split('#').splice(1)

//                 return typeof record[data] === 'object'
//                     ? JSON.stringify(record[data]?.length > 0 ? record[data][0] : record[data]).toLowerCase().includes(value.toLowerCase())
//                     : false;
//             } else if (Array.isArray(dataIndex)) {
//                 const length = dataIndex.length;
//                 if (length === 3) return record[dataIndex[0]][dataIndex[1]][dataIndex[2]].toString().toLowerCase().includes(value.toLowerCase());
//                 if (length === 2) return record[dataIndex[0]][dataIndex[1]].toString().toLowerCase().includes(value.toLowerCase());
//                 if (length === 1) return record[dataIndex[0]].toString().toLowerCase().includes(value.toLowerCase());
//             } else {

//                 return record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase());
//             }
//         },
//         onFilterDropdownOpenChange: (visible) => {
//             if (visible) {
//                 setTimeout(() => searchInput.current.select(), 100);
//             }
//         },
//     };
// };

