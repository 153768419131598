import React, { useEffect, useContext, useState } from 'react'
import { Form, Typography, Input, DatePicker, Col, Row, Button, Divider, Descriptions, Spin, List, Space, Modal, message } from 'antd'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { useHttp } from '../../hooks/http.hook'
import { AuthContext } from '../../context/AuthContext'
import { operationHoursCalculator } from './operationHours'
import { PersonForm } from '../Customer/PersonForm'
import { EditOutlined } from '@ant-design/icons'
import { DataContext } from '../../context/DataContext'

const { Text } = Typography

export const ActualForm = ({ isMobile, actualInfo, onSubmit }) => {
    const { token } = useContext(AuthContext)

    const { request, loading } = useHttp();
    const [details, setDetails] = useState({})
    const [forecast, setForecast] = useState(null)
    const [modalVisible, setModalVisible] = useState(false);
    const [persons, setPersons] = useState([]);
    const [contactItem, setContactItem] = useState({})
    const { customers, updateCustomer } = useContext(DataContext)
    const { settings } = useContext(DataContext)

    const inviteText = 'Результатом актуализации данных является внесение обновленной наработки техники на текущий период.'
    const [formd] = Form.useForm()

    const period = settings?.maintenancePeriod || 2002
    const onFinish = (values) => {
        onSubmit({
            ...values,
            date: new Date(values.date),
            ...forecast,
            passport: actualInfo?._id,
            machine: actualInfo?.machine?._id,
            lastHours: Math.max(...[...actualInfo?.operationHours.map(el => +el.hours), +values.hours]),
        })
    }



    useEffect(() => {
        const fetchData = async () => {
            try {
                const customer = await request(`o/api/customer/${actualInfo.customer._id}`, 'GET', null, {
                    Authorization: `Bearer ${token}`
                });
                const task = await fetchTask()
                setDetails({ task, customer })
            } catch (error) { }
        }
        const fetchTask = async () => {
            try {
                const response = await request(`o/api/task/${actualInfo?.installation}`, 'GET', null, {
                    Authorization: `Bearer ${token}`
                });
                return response
            } catch (error) { }
        }
        fetchData()

        formd.validateFields()
    }, [])

    const handleAddPerson = async (values) => {
        let newone = details.customer.persons.findIndex(el => el._id === values._id)
        if (newone >= 0) {
            // updated
            let upd = [...details?.customer?.persons?.slice(0, newone), values, ...details.customer.persons.slice(newone + 1),];
            setDetails({ ...details, customer: { ...details.customer, persons: upd } })
            const response = await request(`o/api/customer/persons/${details.customer._id}`, 'PUT', values,
                { Authorization: `Bearer ${token}` }
            );
            updateCustomer(response)
            if (response) {
                message.success(`${values.name} обновлен`)
            }

        } else {

            let newlist = [...details.customer.persons, values]
            setDetails({ ...details, customer: { ...details.customer, persons: newlist } })
            const response = await request(`o/api/customer/persons/${details.customer._id}`, 'POST', values,
                { Authorization: `Bearer ${token}` }
            );
            updateCustomer(response)
            if (response) { message.success(`${values.name} создан`) }
        }
        setModalVisible(false);

    };


    const validateOperationHours = async (_, value) => {
        // console.log('actual', actualInfo?.operationHours)
        let result = validateHoursLogic(actualInfo?.operationHours, { date: new Date(formd.getFieldValue('date')), hours: +value }, actualInfo?.lastMaintenanceDate)
        if (!result.isValid) {
            //return validated range based on date or last operation

            return Promise.reject(new Error(`[${Math.floor(result.minmax.min)} ... ${result.minmax.max > 0 ? Math.ceil(result.minmax.max) : ''}] м/ч`));
        }
        return Promise.resolve();
    };


    const validateHoursLogic = (hoursArray, newRecord, lastMaintenanceDate) => {
        let minmax = {}
        if (newRecord?.hours <= 0) {
            return {
                isValid: false, message: 'Error validating operation hours.',
                minmax: {
                    min: 1,
                    max: -1
                }
            }
        }
        // console.log(hoursArray, newRecord)
        try {
            let operationHours = [...hoursArray]; // Create a copy to avoid modifying the original array
            operationHours = operationHours.filter(h => +h.hours !== +newRecord.hours)
            const existingRecordIndex = operationHours.findIndex(record => new Date(record.date).setHours(0, 0, 0, 0) === new Date(newRecord.date).setHours(0, 0, 0, 0));
            const existingHours = operationHours.findIndex(record => +record.hours === +newRecord.hours);

            if (existingRecordIndex !== -1 || existingHours !== -1) {//|| existingRecordIndex === existingHours
                operationHours.splice(existingRecordIndex, 1, newRecord);

            } else {
                operationHours.push(newRecord); // Add the new record

            }
            const sortedOperationHours = operationHours.sort((a, b) => +(new Date(a.date)) - +(new Date(b.date)));
            for (let i = 1; i < sortedOperationHours.length; i++) {
                const currentDate = new Date(sortedOperationHours[i].date);
                const previousDate = new Date(sortedOperationHours[i - 1].date);
                const currentHours = sortedOperationHours[i].hours;
                if (currentHours <= 0) {
                    return { isValid: false, message: 'Validation failed. Incorrect date or hours difference.', minmax };
                }
                const previousHours = sortedOperationHours[i - 1].hours;

                const dateDiff = (currentDate - previousDate) / (60 * 60 * 1000);  //72
                let hoursDiff = currentHours - previousHours;
                if (hoursDiff === 0) { hoursDiff = 1 }
                minmax = { min: previousHours, max: previousHours + dateDiff - 1 }
                // console.log('validation', minmax, hoursDiff, dateDiff)
                if (dateDiff <= 0 || hoursDiff <= 0 || dateDiff < hoursDiff) {
                    return {
                        isValid: false,
                        message: 'Validation failed. Incorrect date or hours difference.',
                        minmax,
                        data: newRecord,
                    };
                }
            }
            let calcResults = operationHoursCalculator(sortedOperationHours,
                {
                    date: actualInfo?.deliveryDate,
                    hours: actualInfo?.deliveryHours
                }, lastMaintenanceDate[0]?.finishDate, settings?.maintenancePeriod ?? 1000)
            setForecast(calcResults)

            return { isValid: true, replace: existingRecordIndex !== -1 };
        } catch (error) {
            return { isValid: false, message: 'Error validating operation hours.', minmax };
        }
    }

    const onModalCancel = () => {
        // setContactItem({});
        setModalVisible(false);
    }

    const openModal = (item) => {
        setContactItem(item)
        setModalVisible(true)
    }

    return (<>
        <Text type="secondary">{inviteText}</Text>
        <div><Text type="secondary">
            Предусмотрена проверка вводимых данных на соответствие с предыдущими записями о моточасах.
        </Text></div>
        <Form form={formd}
            layout={isMobile ? "inline" : 'horizontal'}
            name="formModal"
            onFinish={onFinish}
            initialValues={{ date: dayjs(new Date()), hours: actualInfo?.machine?.lastOperationHours?.hours + 1 }}
            style={{ marginTop: '1rem' }}>
            <Row gutter={[16, 8]}>
                <Col xs={24} sm={10}>
                    <Form.Item
                        label="Дата"
                        name="date"
                        layout="horizontal"
                        rules={[{ required: true, message: 'Введите дату, когда зафиксированы моточасы' }]}
                    >
                        <DatePicker
                            format={'DD.MM.YYYY'}
                            onChange={() => formd.validateFields()}
                            style={{ width: '100%' }}
                            popupStyle={{ maxWidth: '200px' }} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={10}>
                    <Form.Item
                        label="Моточасы"
                        name="hours"
                        rules={[{ required: true, validator: validateOperationHours }]}
                    >
                        <Input type="number" min={1} style={{ width: '100%' }} onChange={() => formd.validateFields()} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={4}>
                    <Button type="primary" htmlType="submit"

                    >Записать</Button>
                </Col>
            </Row>
        </Form>

        {forecast?.averageHoursPerDay > 0 ?
            <Space split={<Divider type="vertical" />}>
                <Text type="secondary">Средняя расчетная наработка в месяц: <span style={{ fontWeight: 'bold' }}>{Math.ceil(forecast.averageHoursPerDay * 30)}</span> м.ч.</Text>

                <Text type="secondary">Расчетная дата следующего ТО: <span style={{ fontWeight: 'bold' }}>{forecast.nextMaintenanceDate ? new Date(forecast.nextMaintenanceDate).toLocaleDateString('ru-RU') : '-'}</span></Text>
            </Space>
            : <>Нарушен порядок записи наработки в моточасах. Проверьте карточку техники</>}

        <Divider orientation="left" orientationMargin="0">
            Система пожаротушения
        </Divider>


        <Row gutter={[16, 8]}>
            <Col xs={24} sm={20}>
                <Descriptions size='small'>
                    <Descriptions.Item label="Техника" span={3}>{

                        <>
                            <div>{actualInfo?.machine?.brand} {actualInfo?.machine?.model}&nbsp;</div>
                            <div style={{ fontSize: 14, color: '#a8a8a8' }}>{actualInfo?.machine?.serialNumber} / {actualInfo?.machine?.garageNumber}</div>
                        </>
                    }</Descriptions.Item>
                    {details?.customer?.location && <Descriptions.Item label="Локация" span={3}> {details?.customer?.location} </Descriptions.Item>}
                    {actualInfo?.deliveryDate && <Descriptions.Item label="Дата запуска системы" span={3}>{new Date(actualInfo?.deliveryDate).toLocaleDateString('ru-RU')}</Descriptions.Item>}
                    {actualInfo?.lastMaintenanceDate?.length > 0 && <Descriptions.Item label="Дата последнего ТО системы" span={3}>{new Date(actualInfo?.lastMaintenanceDate[0]?.finishDate).toLocaleDateString('ru-RU')}</Descriptions.Item>}
                    {actualInfo?.nextMaintenanceDate && <Descriptions.Item label="Дата следующего ТО системы" span={3}>{
                        new Date() < new Date(actualInfo?.nextMaintenanceDate)
                            ? <>{new Date(actualInfo?.nextMaintenanceDate).toLocaleDateString('ru-RU')}</>
                            : <span style={{ color: 'red' }}>{new Date(actualInfo?.nextMaintenanceDate).toLocaleDateString('ru-RU')} - просрочено на {
                                Math.floor((new Date() - new Date(actualInfo?.nextMaintenanceDate)) / (1000 * 60 * 60 * 24))
                            } дн.!</span>



                    }</Descriptions.Item>}

                    <Descriptions.Item label="Счетчик моточасов" span={3}>
                        {actualInfo?.machine?.lastOperationHours
                            ? <> {actualInfo?.machine?.lastOperationHours.hours} м.ч. &nbsp;
                                <span style={{ color: '#a8a8a8' }}>на {new Date(actualInfo?.machine?.lastOperationHours.date).toLocaleDateString('ru-RU')}</span>
                            </>
                            : <Spin size='small' />}
                    </Descriptions.Item>
                </Descriptions>
            </Col>
            <Col xs={24} sm={4}>
                {false && <Button type='default'>История</Button>}
            </Col>
        </Row>


        <Divider orientation="left" orientationMargin="0">
            Заказчик
        </Divider>
        <Descriptions size='small'>

            <Descriptions.Item label="Контрагент" span={2}>

                <Text type='normal'>{actualInfo.customer.form} {actualInfo.customer.name}</Text>&nbsp;
                <Text type="secondary">ИНН: {actualInfo.customer.inn}</Text>
            </Descriptions.Item>
            <Descriptions.Item label="Email" span={1}>{details?.customer?.email ? details?.customer?.email : <Spin size='small' />}</Descriptions.Item>

            <Descriptions.Item label="Адрес" span={2}>{details?.customer?.address ? details?.customer?.address : <Spin size='small' />}</Descriptions.Item>
            <Descriptions.Item label="Тел." span={1}>{details?.customer?.phone ? details?.customer?.phone : <Spin size='small' />}</Descriptions.Item>

        </Descriptions>

        <Divider orientation="left" orientationMargin="0">
            Контактные лица
        </Divider>
        {details?.customer?.persons?.length > 0 && <>
            <List
                // itemLayout="horizontal"

                dataSource={details?.customer?.persons}
                renderItem={(item, index) => (
                    item.status === 'Работает'
                        ? <List.Item style={{ paddingTop: 0, display: 'flex' }}>
                            {/* actions={[<div>{item?.phone}</div>, <div>{item?.email}</div>]}> */}
                            {/* // extra={<><div>{item?.phone}</div><div>{item?.email}</div></>}> */}
                            <List.Item.Meta
                                avatar={<>{index + 1}</>}
                                title={<div style={{ width: '100%' }}>{item.name}</div>}
                                description={
                                    <>{item.jobTitle}
                                        <Row gutter={[8, 8]}>
                                            <Col xs={24} sm={10}>
                                                тел.: {item.phone}
                                            </Col>
                                            <Col xs={24} sm={10}>
                                                <Link
                                                    to='#'
                                                    onClick={(e) => {
                                                        window.location.href = `mailto:${item.email}`;
                                                        e.preventDefault();
                                                    }}
                                                >{item.email}</Link>

                                            </Col>
                                            <Col xs={24} sm={4}>
                                                <Button type='default' onClick={(e) => openModal(item)}>
                                                    <EditOutlined />
                                                </Button>
                                            </Col>
                                        </Row>
                                    </>}
                            />
                        </List.Item>
                        : (loading ? <Spin size='small' /> : <></>)

                )}
            />


        </>}
        <Button type="primary" onClick={() => setModalVisible(true)}>+ Добавить новый контакт</Button>
        <Modal
            destroyOnClose={true}
            title="Добавить контактное лицо"
            open={modalVisible}
            onCancel={onModalCancel}
            footer={null}

        // afterClose={handleCloseModal}
        >
            <PersonForm onCreate={handleAddPerson} onCancel={onModalCancel} item={contactItem} />
        </Modal>
    </>
    )

}