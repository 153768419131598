import React, { useContext, useEffect, useState } from 'react';
import { Layout, Row, Col, Dropdown, Avatar, Divider, Space, message, Grid } from 'antd';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons'
import Logo from '../Logo';
import Backg from '../../components/Background';
import { ClientModal, ClientPassport } from '../../components/Client/ClientModal';
import { ClientDrawer } from '../../components/Client/ClientDrawer';
import { ClientCards } from '../../components/Client/ClientCards';
import { ClientList } from '../../components/Client/ClientList';
import { ClientHistory } from '../../components/Client/ClientHistory';
import { ClientCarousel } from '../../components/Client/ClientCarousel';
import { ClientDrawerSpecial } from '../../components/Client/ClientDrawerSpecial';
import { PassportDrawer } from '../../components/Client/PassportDrawer';
import { useHttp } from '../../hooks/http.hook';
import { AuthContext } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
const { useBreakpoint } = Grid

const { Header, Content, Footer } = Layout;

export const ClientPageV2 = () => {
    const { logout, token, userId, company } = useContext(AuthContext)
    const { request, loading } = useHttp()
    const navigate = useNavigate()
    const [equipment, setEquipment] = useState([]);
    const [visible, setVisible] = useState(false);
    const [passportVisible, setPassportVisible] = useState(false);
    const [opened, setOpened] = useState(false);
    const [openedPassport, setOpenedPassport] = useState(false);
    const [openedSpecial, setOpenedSpecial] = useState(false);
    const [selectedSpecial, setSelectedSpecial] = useState(false)
    const [selectedMachine, setSelectedMachine] = useState({})
    const [cards, setCards] = useState({ systems: 0, warranty: 0, mntc: 0, name: '' });
    const [actions, setActions] = useState([]);
    const [offers] = useState([
        {
            id: 1,
            title: "Подать заявку на обучение персонала",
            description: '',
            image: 'o/api/pictures/clientfiles/ptp1.0.jpg',
            type: 'study',
        },

        {
            id: 3,
            title: "Подать заявку на проведение испытаний",
            description: '',//"Предложение на установку пятой системы в парке",
            image: 'o/api/pictures/clientfiles/ptp1.1.jpg',
            type: 'test',

        },
        {
            id: 4,
            title: 'Своевременно выполняйте ТО',//"Монтаж системы в подарок",
            description: '',//"Предложение на установку пятой системы в парке",
            image: 'o/api/pictures/clientfiles/ptp1.2.jpg',
            type: 'maintenance',

        },

    ]);

    const isMobile = useBreakpoint().xs

    const logoutHandler = (e) => {
        logout()
        navigate('/home')
    }

    const clientName = company ?? 'ООО КОМПАНИЯ'

    const items = [
        {
            label: <div onClick={() => navigate('/useraccount')}>Мой аккаунт</div>,
            key: 'account',
        },
        {
            label: <div onClick={(e) => logoutHandler(e)} style={{ color: 'red' }}>Выход</ div>,
            key: 'logout',
            icon: <LogoutOutlined style={{ color: 'red' }} />,
        },

    ];



    const origin = window.location.origin;

    useEffect(() => {
        setCards({
            systems: equipment.length,
            mntc: equipment?.filter(el => el.maintenance === true).length,
            warranty: equipment?.filter(el => el.warranty === true).length,
            name: clientName
        })
    }, [equipment])

    useEffect(() => {
        const fetchEquipment = async () => {

            // const response = await axios.get('http://localhost:5000/api/equipment');
            try {
                const response = await request('o/api/clientinfo', 'POST', null, {
                    Authorization: `Bearer ${token}`
                });
                if (response) {
                    setEquipment([...response.data, ...response.data]);
                    setActions(response.history);

                }


            } catch (error) {
                console.log(error);
            }
            // setEquipment(sampleData);
        };

        fetchEquipment();
    }, []);

    const handleRequest = async (data) => {
        const id = 'request'
        try {
            const response = await request(`o/api/client/${id}`, 'POST', data, {
                Authorization: `Bearer ${token}`
            });
            if (response?.historyRecord) {
                setActions([response.historyRecord, ...actions])
            }
            return response;
        } catch (error) {
            console.log(error);
        }
    };

    const showModal = () => {
        setVisible(true);
    }

    const handleOk = () => {
        setVisible(false);
    }

    const handleCloseDrawer = () => {
        setOpened(false);
    }

    const handleClosePassport = () => {
        setOpenedPassport(false);
    }

    const openPassport = async (item) => {
        const id = item?._id
        setOpenedPassport(true)
        try {
            const response = await request(`o/api/client/passport/${id}`, 'GET');
            if (response) {
                setSelectedMachine(response)
                return
            }


        } catch (error) {
            console.log(error)
        }

    }

    const handleCloseSpecialDrawer = () => {
        setOpenedSpecial(false);
    }

    const handleOpenSpecialDrawer = (v) => {
        setSelectedSpecial(v.type)
        setOpenedSpecial(true);
    }

    const handleOpenDrawer = (item) => {
        setSelectedMachine(item)
        setOpened(true);

    }

    const handlePassOk = () => {
        // setPassportVisible(false);
    }

    const handleOpenPassport = (values) => {
        // setPassportVisible(true)
    }

    const handleSendRequest = async (values) => {
        // console.log(values)
        handleCloseDrawer()
        let response = await handleRequest(values)
        message.success(response.message)
    }

    const handleSendSpecialRequest = async (values) => {
        // console.log(values)
        handleCloseSpecialDrawer()
        await handleRequest(values)
        message.success('Сообщение отправлено')
    }

    const handleSendInstallRequest = async (values) => {
        // console.log(values)
        setVisible(false)
        await handleRequest(values)
        message.success('Сообщение отправлено')

    }

    return (
        <>
            <Layout style={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}>
                <Header
                    style={
                        {
                            padding: '10px',
                            display: 'flex',
                            position: 'fixed',
                            width: '100%',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            backgroundColor: 'rgb(5, 66, 150)',
                            zIndex: 50,
                        }
                    }>

                    <Logo style={{ width: '90px', fill: 'white', position: 'absolute' }} />
                    {/* <Flex align="center" justify='flex-start' gap='small' wrap='nowrap' > */}

                    {/* <Space> */}
                    <div className="container headerTitle"
                        style={{
                            color: 'white',
                            fontSize: 'large',
                            top: '34px',
                            zIndex: 99,

                        }}>
                        ПОРТАЛ&nbsp;ПОЛЬЗОВАТЕЛЯ
                    </div>
                    {/* </Space> */}

                    {/* </Flex> */}
                    <Backg style={{
                        width: '70vw', overflow: 'none',
                        // position: 'absolute',
                        // left: '50px',

                    }} />
                    <div className="avatar-menu"
                        style={{ position: 'relative', zIndex: 999 }}
                    >
                        <Dropdown menu={{ items }} placement="bottomRight">
                            <Avatar
                                style={{ backgroundColor: 'rgb(5, 43, 120)' }}
                                size="large" icon={<UserOutlined />} />
                        </Dropdown>
                    </div>
                </Header>

                <Content style={{
                    padding: '80px 20px 0px 20px',
                    marginBottom: '3em',
                    flexGrow: 1,
                }}><>
                        <div className="container site-layout-content">
                            <h2>{cards.name}</h2>
                            {isMobile && <>
                                <ClientCards {...cards} showModal={showModal} />
                                <Divider style={{
                                    marginTop: 14,
                                    marginBottom: 14
                                }} />
                            </>}
                            <Row gutter={[12, 12]}>
                                <Col xs={24} md={16}>
                                    <Space
                                        direction='vertical'
                                        size={0}
                                        style={{
                                            height: 45,
                                            width: '100%',
                                            backgroundColor: 'white',
                                            fontSize: 16,
                                            fontWeight: 700,
                                            justifyContent: 'center',
                                            paddingLeft: 10,
                                            marginTop: 10,
                                        }}
                                    >
                                        Установленные системы пожаротушения
                                    </Space>

                                    <ClientList equipment={equipment} handleOpenDrawer={handleOpenDrawer} openPassport={openPassport} />
                                </Col>

                                <Col xs={24} md={8} >
                                    {!isMobile && <>
                                        <ClientCards {...cards} showModal={showModal} />
                                        <Divider style={{
                                            marginTop: 14,
                                            marginBottom: 14
                                        }} />
                                    </>}
                                    <ClientHistory actions={actions} handleOpenPassport={handleOpenPassport} />
                                </Col>
                            </Row>

                            <Divider />

                            <h2 style={{
                                marginTop: '1em',
                                marginBottom: '1em',

                            }}>Специальные предложения</h2>
                            <ClientCarousel offers={offers} handleOpenCarousel={handleOpenSpecialDrawer} />
                        </div>
                    </>

                </Content >
                <Footer style={{
                    textAlign: 'center',
                    height: '64px',
                    backgroundColor: 'rgb(5,  66,  150)',
                    color: 'white',
                    width: '100%'
                }}>ПОЖТЕХПРОМ ©2024</Footer>
            </Layout >

            <ClientModal
                open={visible}
                onOk={handleOk}
                onFinish={handleSendInstallRequest}
                onClose={() => setVisible(false)}
            />

            <ClientDrawer
                open={opened}
                onClose={handleCloseDrawer}
                title={'Заявка на техническое обслуживание'}
                value={selectedMachine}
                onSendRequest={handleSendRequest}
            />

            <ClientDrawerSpecial
                open={openedSpecial}
                onClose={handleCloseSpecialDrawer}
                value={selectedSpecial}
                onFinish={handleSendSpecialRequest}
                options={
                    equipment.map((item, index) => {
                        return {
                            label: item.passport,
                            value: item.passport,
                            key: index,
                        }

                    })
                }
            />

            <ClientPassport
                open={passportVisible}
                onOk={handlePassOk}
            />

            <PassportDrawer
                open={openedPassport}
                onOk={handleClosePassport}
                value={selectedMachine}
                loading={loading}
            />
        </>
    );
};




const sampleData = [
    {
        name: 'Гусеничный экскаватор',
        operationHours: 1200,
        passport: '001020 HITACHI ZX470LC-5G №15521 / X99',
        nextMaintenance: new Date('2024-07-01'),
        maintenance: true,
        instaDate: '2023-12-12',
    },
    {
        name: 'Бульдозер',
        operationHours: 800,
        passport: 'BLD-2020-5678',
        nextMaintenance: new Date('2024-08-15'),
        instaDate: '2023-10-11',
    },
    {
        name: 'Гусеничный экскаватор',
        operationHours: 1500,
        passport: '001023 KOMATSU PC300-7 #001020 / K190',
        nextMaintenance: new Date('2024-07-31'),
        instaDate: '2024-05-20',
    },
    {
        name: 'Кран',
        operationHours: 1500,
        passport: 'CRN-2019-4321',
        nextMaintenance: new Date('2024-08-28'),
        instaDate: '2023-12-30',
    },
    {
        name: 'Бульдозер',
        operationHours: 12520,
        passport: '30020 LIEBHERR PR 716',
        nextMaintenance: new Date('2024-07-05'),
        maintenance: true,
        instaDate: '2024-03-01',
    },
    {
        name: 'Кран',
        operationHours: 1500,
        passport: 'CRN-2019-4321',
        nextMaintenance: new Date('2024-08-28'),
        instaDate: '2023-12-30',
    },
    {
        name: 'Кран',
        operationHours: 1500,
        passport: 'CRN-2019-4321',
        nextMaintenance: new Date('2024-08-28'),
        instaDate: '2023-12-30',
    },
    {
        name: 'Кран',
        operationHours: 1500,
        passport: 'CRN-2019-4321',
        nextMaintenance: new Date('2024-08-28'),
        instaDate: '2023-12-30',
    },
    {
        name: 'Кран',
        operationHours: 1500,
        passport: 'CRN-2019-4321',
        nextMaintenance: new Date('2024-08-28'),
        instaDate: '2023-12-30',
    },
];

// const actions = [
//     {
//         name: 'Монтаж системы',
//         equipment: 'KOMATSU PC300-7 #001020 / K190',
//         location: 'Карьер Березовский',
//         date: '2024-05-20',
//         hours: 1020
//     },
//     {
//         name: 'Монтаж системы',
//         equipment: 'CRN-2019-4321',
//         location: 'Карьер Березовский',
//         date: '2023-12-12',
//         hours: 3420
//     }
// ]