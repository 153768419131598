import React, { useContext, useEffect, useState } from "react";
import { Tabs } from "antd";
import TaskReportPeriod from "./Reports/TaskReportPeriod";
import TaskReportFuture from "./Reports/TaskReportFuture";
import WorkerLoadReport from "./Reports/WorkerLoadReport";
import { Dashboardv2 } from "./Reports/dashPage";
import { PivotReport, PivotTable } from "./Reports/pivotReport";
import { useHttp } from "../hooks/http.hook";
import { AuthContext } from "../context/AuthContext";


const ReportPage = () => {
  const [data, setData] = useState([])
  const { request } = useHttp()
  const { token } = useContext(AuthContext)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const reportData = await request(
          "o/api/report/pivotreport",
          "POST", null,
          {
            Authorization: `Bearer ${token}`,
          }
        );

        if (reportData?.data) {
          setData(reportData.data)

        }

      } catch (error) { }

    }
    // setData([])
    fetchData()
  }, [])

  return (
    <Tabs defaultActiveKey="1"
      items={[
        {
          label: "Отчет по заданиям",
          key: "1",
          children: <TaskReportPeriod />
        },
        // {
        //   label: "Планируемые задания",
        //   key: "2",
        //   children: <TaskReportFuture />

        // },
        {
          label: "Загрузка персонала",
          key: "3",
          children: <WorkerLoadReport />

        },
        {
          label: "Динамика",
          key: "4",
          children: <Dashboardv2 />

        },
        {
          label: "Сводный отчет",
          key: "5",
          children: <>
            <PivotTable data={data} />
            {/* <PivotReport /> */}
          </>

        }

      ]}

    />
  );
};

export default ReportPage;

let sampleData = [
  {
    "year": 2022,
    "month": 0,
    "type": "Монтаж",
    'free': true,
    "qty": 0
  },
  {
    "year": 2022,
    "month": 0,
    "type": "Диагностика",
    "qty": 0
  },
  {
    "year": 2022,
    "month": 0,
    "type": "Дефектовка",
    "qty": 0
  },
  {
    "year": 2022,
    "month": 0,
    "type": "Техническое обслуживание",
    "qty": 0
  },
  {
    "year": 2022,
    "month": 0,
    "type": "Осмотр",
    "qty": 0
  },
  {
    "year": 2022,
    "month": 0,
    "type": "Отключение",
    "qty": 0
  },
  {
    "year": 2022,
    "month": 0,
    "type": "Перезарядка",
    "free": true,

    "qty": 0
  },
  {
    "year": 2022,
    "month": 1,
    "type": "Монтаж",
    "qty": 0
  },
  {
    "year": 2022,
    "month": 1,
    "type": "Осмотр",
    "qty": 0
  },
  {
    "year": 2022,
    "month": 1,
    "type": "Отключение",
    "qty": 0
  },
  {
    "year": 2022,
    "month": 1,
    "type": "Перезарядка",
    "qty": 0
  },
  {
    "year": 2022,
    "month": 2,
    "type": "Монтаж",
    "qty": 0
  },
  {
    "year": 2022,
    "month": 2,
    "type": "Диагностика",
    "qty": 0
  },
  {
    "year": 2022,
    "month": 2,
    "type": "Дефектовка",
    "qty": 0
  },
  {
    "year": 2022,
    "month": 2,
    "type": "Техническое обслуживание",
    "qty": 0
  },
  {
    "year": 2024,
    "month": 2,
    "type": "Осмотр",
    "free": true,
    "qty": 0
  },
  {
    "year": 2022,
    "month": 2,
    "type": "Отключение",
    "qty": 0
  },
  {
    "year": 2022,
    "month": 3,
    "type": "Дефектовка",
    "qty": 0
  },
  {
    "year": 2022,
    "month": 3,
    "type": "Техническое обслуживание",
    "qty": 0
  },
  {
    "year": 2022,
    "month": 3,
    "type": "Осмотр",
    "qty": 0
  },
  {
    "year": 2022,
    "month": 3,
    "type": "Отключение",
    "qty": 0
  },
  {
    "year": 2022,
    "month": 3,
    "type": "Перезарядка",
    "qty": 0
  },
  {
    "year": 2022,
    "month": 4,
    "type": "Монтаж",
    "qty": 0
  },
  {
    "year": 2022,
    "month": 4,
    "type": "Диагностика",
    "qty": 0
  },
  {
    "year": 2022,
    "month": 4,
    "type": "Дефектовка",
    "qty": 0
  },
  {
    "year": 2022,
    "month": 4,
    "type": "Техническое обслуживание",
    "qty": 0
  },
  {
    "year": 2022,
    "month": 4,
    "type": "Осмотр",
    "qty": 0
  },
  {
    "year": 2022,
    "month": 4,
    "type": "Отключение",
    "qty": 0
  },
  {
    "year": 2022,
    "month": 4,
    "type": "Перезарядка",
    "qty": 0
  },
  {
    "year": 2022,
    "month": 5,
    "type": "Монтаж",
    "qty": 0
  },
  {
    "year": 2022,
    "month": 5,
    "type": "Диагностика",
    "qty": 0
  },
  {
    "year": 2022,
    "month": 10,
    "type": "Монтаж",
    "qty": 0
  },
  {
    "year": 2022,
    "month": 10,
    "type": "Диагностика",
    "qty": 0
  },
  {
    "year": 2022,
    "month": 10,
    "type": "Дефектовка",
    "qty": 0
  },
  {
    "year": 2022,
    "month": 10,
    "type": "Техническое обслуживание",
    "qty": 0
  },
  {
    "year": 2022,
    "month": 10,
    "type": "Осмотр",
    "qty": 0
  },
  {
    "year": 2022,
    "month": 10,
    "type": "Отключение",
    "qty": 0
  },
  {
    "year": 2022,
    "month": 10,
    "type": "Перезарядка",
    "qty": 0
  },
  {
    "year": 2022,
    "month": 11,
    "type": "Монтаж",
    "qty": 0
  },
  {
    "year": 2022,
    "month": 11,
    "type": "Диагностика",
    "qty": 0
  },
  {
    "year": 2022,
    "month": 11,
    "type": "Дефектовка",
    "qty": 0
  },
  {
    "year": 2022,
    "month": 11,
    "type": "Техническое обслуживание",
    "qty": 0
  },
  {
    "year": 2022,
    "month": 11,
    "type": "Осмотр",
    "qty": 0
  },
  {
    "year": 2022,
    "month": 11,
    "type": "Отключение",
    "qty": 0
  },
  {
    "year": 2022,
    "month": 11,
    "type": "Перезарядка",
    "qty": 0
  },
  {
    "year": 2023,
    "month": 0,
    "type": "Диагностика",
    "qty": 0
  },
  {
    "year": 2023,
    "month": 0,
    "type": "Дефектовка",
    "qty": 0
  },
  {
    "year": 2023,
    "month": 0,
    "type": "Техническое обслуживание",
    "qty": 0
  },
  {
    "year": 2023,
    "month": 0,
    "type": "Осмотр",
    "qty": 0
  },
  {
    "year": 2023,
    "month": 0,
    "type": "Отключение",
    "qty": 0
  },
  {
    "year": 2023,
    "month": 0,
    "type": "Перезарядка",
    "qty": 0
  },
  {
    "year": 2023,
    "month": 1,
    "type": "Монтаж",
    "qty": 0
  },
  {
    "year": 2023,
    "month": 1,
    "type": "Диагностика",
    "qty": 0
  }]
