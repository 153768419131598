import { Table, Space, Tag, Popover } from "antd"
import React, { useEffect, useState } from "react"
import { TagFilled, SearchOutlined, FolderOpenOutlined } from '@ant-design/icons'
import { NavLink } from "react-router-dom"
import { GetColumnSearchProps } from "../SearchLine"
import useSize from "../Equipment/EquipmentList"

const PassportList = ({ actualData, loading, onEdit }) => {
    const [actual, setActual] = useState([])
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 16,
        total: actual?.length,
        onChange: (page) => {
            setPagination({ ...pagination, current: page });
        },
    });

    const windowSize = useSize()

    useEffect(() => {
        window.dispatchEvent(new Event("resize"))
    }, [])

    useEffect(() => {
        setActual(actualData)
    }, [actualData]);

    let data = [
        {
            customer: 'Client', machine: 'PC300',
            _id: 1,
            passport: 'Passport 1',
            warranty: true, opHours: 'На 01.05.2024 - 400 м.ч.', nextMaintenance: '2025-10-04'
        }

    ]

    const openForm = (el) => {
        onEdit(el)
    }

    const columns = [
        {
            title: '#',
            width: (actual.length).toString().length * 20,
            key: 'num',
            render: (text, record, index) => {
                return <div style={{
                    textAlign: 'center'
                }}>{index + 1}</div>
            },
        },

        {
            title: 'Клиент',
            dataIndex: 'customer',
            key: 'customer',
            width: 190,
            ...GetColumnSearchProps(['customer', 'name'], 'клиенту'), //['customer', 'name'], 'клиенту'
            render: (val) => (
                <Popover>
                    <div >{val?.name} <span style={{ fontSize: 12, color: '#a8a8a8' }}>ИНН: {val?.inn} </span></div>
                </Popover>),
        },

        {
            title: 'Техника',
            dataIndex: 'machine',
            key: 'machine',
            width: 140,
            render: (el, item) => (
                <Popover content={equip(item.equipment)} title="Оборудование" trigger="hover">
                    <div>{el?.brand} {el?.model}</div>
                    <div style={{ fontSize: 12, color: '#a8a8a8' }}>{el?.serialNumber} / {el?.garageNumber}</div>
                </Popover>)
        },
        {
            title: 'Наработка',
            dataIndex: ['machine', 'lastOperationHours'],
            key: 'operationHours',
            width: 110,
            render: (text, record, index) => {
                return (<>
                    <span>{text?.hours} м.ч. </span>
                    <span style={{ color: 'lightgrey' }}>на {new Date(text?.date).toLocaleDateString('ru-RU')}</span>
                </>
                )
            }

        },
        {
            title: 'Дата запуска',
            dataIndex: '_id',
            key: '_id',
            width: 110,
            // render: (val, rec) => <NavLink to={`/passport/${val}`}>{new Date(rec.deliveryDate).toLocaleDateString('ru-RU')}</NavLink>
            render: (val, rec) => <NavLink to={`/passport?id=${val}`}>{new Date(rec.deliveryDate).toLocaleDateString('ru-RU')}</NavLink>

        },
        {
            title: 'Следующее ТО',
            dataIndex: 'nextMaintenanceDate',
            width: 110,
            key: 'nextMaintenanceDate',
            render: (val) =>
                new Date(val) > new Date()
                    ? (<div>{(new Date(val)).toLocaleDateString('ru-RU')}</div>)
                    : (<div style={{ color: 'red' }}>{val && (new Date(val)).toLocaleDateString('ru-RU')}</div>)
        },

        {
            title: 'Гарантия',
            dataIndex: 'warranty',
            key: 'warranty',
            width: 90,
            render: (val) => val ? <Tag color="red">ГАРАНТИЯ</Tag> : "-",
        },
        {
            title: 'Действия',
            key: 'action',
            width: 80,
            render: (text, record) => (
                <Space size={'middle'}  >

                    <FolderOpenOutlined style={{ fontSize: 22 }}
                        onClick={() => openForm(record)}
                    />
                </Space>
            ),
        }
    ]

    const columnsEq = [
        {
            title: '#',
            dataIndex: 'key',
            key: 'key',
            width: '4%',
            render: (_, r, index) => (<div>{index + 1}</div>)
        },
        {
            title: 'Наименование',
            dataIndex: 'description',
            key: 'description',
            width: '50%',
        },
        {
            title: 'Кол-во',
            dataIndex: 'qty',
            key: 'qty',
            width: '17%',
        },
        {
            title: 'Серийный №',
            dataIndex: 'comment',
            key: 'comment',
            width: '23%',
        }
    ]

    let equip = (data) => {
        return (
            <Table
                columns={columnsEq}
                size="small"
                dataSource={data}
                scroll={{ x: 500 }}
                loading={loading}
                pagination={false}
                rowKey={record => record._id}
            />
        )

    }

    return (
        <Table
            columns={columns} dataSource={actual} size="small"
            rowKey={"_id"}
            loading={loading}
            // scroll={{ x: 550 }}
            pagination={false}
            scroll={{ x: 1100, y: +windowSize[1] - 220, }}
            footer={(data) => {
                return < div style={{ textAlign: 'end' }}>Всего записей: {data?.length ?? 0}</div>
            }} // Modified from setting state
            onRow={(record, rowIndex) => {
                return {
                    onDoubleClick: event => { openForm(record) }, // click row
                    onTouchCancel: event => { openForm(record) },
                };
            }} />
    )

}


export default PassportList