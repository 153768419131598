import React, { useEffect, useState } from 'react';
import { Form, Input, Select, Button, Flex, Space, Popconfirm } from 'antd';
import { useForm } from 'antd/es/form/Form';


export const PersonForm = ({ onCreate, onCancel, item, onCreateUser }) => {
    const [fields, setFields] = useState(item);
    const [buttonActive, setButtonActive] = useState(false);
    const [personForm] = useForm()
    useEffect(() => {
        setFields(item);
    }, []);

    const changeFields = () => {
        if (item._id && personForm.getFieldValue('email') && personForm.getFieldValue('name') && personForm.getFieldValue('phone') && personForm.getFieldValue('status') === 'Работает') {
            setButtonActive(true)
        } else {
            setButtonActive(false)
        }

    }

    const onFinish = (values) => {
        if (fields?._id) {
            onCreate({ ...values, _id: fields._id });
        } else {
            onCreate(values);
        }
    };


    return (
        <Form
            name='person'
            initialValues={fields}
            layout="vertical"
            onFinish={onFinish}
            onCancel={onCancel}
            form={personForm}
            onFieldsChange={changeFields}
        >
            <Form.Item
                name="name"
                label="Фамилия Имя Отчество"
                rules={[{ required: true, message: 'Пожалуйста, введите Фамилию, Имя и Отчество' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="jobTitle"
                label="Должность"
                rules={[{ required: false, message: 'Пожалуйста, введите должность' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="phone"
                label="Контактные телефоны"
                rules={[{ required: true, message: 'Пожалуйста, введите номер телефона' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="email"
                label="Email"
                rules={[{ required: false, message: 'Пожалуйста, введите email' }]}
            >
                <Input disabled={fields?.account?.hasAccount} />
            </Form.Item>
            <Form.Item
                name="status"
                label="Статус"
                rules={[{ required: false, message: 'Выберите статус' }]}
            >
                <Select
                    options={[
                        { value: 'Работает', label: 'Работает' },
                        { value: 'Не работает', label: 'Не работает' },
                    ]}
                />
            </Form.Item>
            <Form.Item>
                <Flex justify='space-between' style={{ width: '100%' }}>
                    <Flex gap={'small'}>
                        {fields?._id
                            ? <Button type="primary" htmlType="submit">Обновить</Button>
                            : <Button type="primary" htmlType="submit">Добавить</Button>
                        }
                        <Button onClick={onCancel} style={{ marginLeft: 8 }}>Отмена</Button>
                    </Flex>
                    {buttonActive ? !fields?.account?.active ?? !fields?.account?.hasAccount
                        ? <Popconfirm
                            title="Создание учетной записи пользователя"
                            description={`Вы действительно уверены в создании учетной записи для пользователя ${fields.name} ( ${fields.email} ) ?`}
                            onConfirm={(e) => { onCreateUser(fields) }}
                            okText="Создать"
                            cancelText="Отмена"
                        >
                            <Button>Создать учетную запись</Button>
                        </Popconfirm>
                        // onClick={(e) => { onCreateUser(fields) }} 
                        : <Space>Аккаунт активирован</Space>
                        : <></>}
                </Flex>



            </Form.Item>
        </Form>
    );
};



// import React, { useEffect, useState } from 'react'
// import { Form, Input, Select, Button } from 'antd'

// export const PersonForm = ({ onCreate, onCancel, item }) => {

//     const [fields, setFields] = useState({})
//     const [forms] = Form.useForm();

//     const onFinish = (values) => {
//         if (fields?._id) {
//             console.log('upditems', values)
//         } else {
//             console.log('newitems', values)
//         }

//     };

//     useEffect(() => {
//         if (item) { setFields(item) }
//     }, [item])

//     useEffect(() => {
//         console.log('fields', fields)
//     }, [fields])

//     return (
//         <Form
//             form={forms}
//             name='person'
//             initialValues={fields}
//             layout="vertical" onFinish={onFinish} >
//             <Form.Item name="name" label="Фамилия Имя Отчество" rules={[{ required: true, message: 'Пожалуйста, введите Фамилию, Имя и Отчество' }]}>
//                 <Input />
//             </Form.Item>
//             <Form.Item name="jobTitle" label="Должность" rules={[{ required: true, message: 'Пожалуйста, введите должность' }]}>
//                 <Input />
//             </Form.Item>
//             <Form.Item name="phone" label="Контактные телефоны" rules={[{ required: true, message: 'Пожалуйста, введите номер телефона' }]}>
//                 <Input />
//             </Form.Item>
//             <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Пожалуйста, введите email' }]}>
//                 <Input />
//             </Form.Item>
//             <Form.Item name="status" label="Статус" rules={[{ required: false, message: 'Выберите статус' }]} >
//                 <Select

//                     options={[
//                         { value: 'Работает', label: 'Работает' },
//                         { value: 'Не работает', label: 'Не работает' },
//                     ]}
//                 />
//             </Form.Item>
//             <Form.Item>
//                 {fields?._id
//                     ? <Button type="primary" htmlType="submit">Обновить</Button>
//                     : <Button type="primary" htmlType="submit">Добавить</Button>
//                 }
//                 <Button onClick={onCancel} style={{ marginLeft: 8 }}>Отмена</Button>
//             </Form.Item>
//         </Form>
//     );
// };
