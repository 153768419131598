import React, { useState } from 'react'
import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { AuthContext } from "../context/AuthContext"
import { UserOutlined, BarsOutlined, CloseOutlined, LogoutOutlined, SettingOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import Logo from '../pages/Logo'
import { Layout, Menu, Flex, Avatar, Grid, Dropdown, Space } from "antd"
import { useHelp } from '../context/HelpContext';
import Help from './Help/Help';

const { Header } = Layout;


export const Navbar = ({ collapsed, onCollapse }) => {
    const auth = useContext(AuthContext)
    const { setPageId } = useHelp();
    const navigate = useNavigate()
    const { useBreakpoint } = Grid;

    const [isHelpVisible, setIsHelpVisible] = useState(false);

    const handleHelpClick = () => {
        setIsHelpVisible(true);
    };

    const logoutHandler = (e) => {
        auth.logout()
        navigate('/')
    }
    const items1 = [
        { key: "dashboard", label: "Отчеты", },
        // { key: "create", label: "Настройки" },
        // { key: "useraccount", label: "Мой аккаунт" },
    ];

    const openHelpPage = (pageId) => {
        // const helpPageUrl = `/help${pageId}`;

        setPageId(pageId)
        handleHelpClick()
        // window.open(helpPageUrl, 'link');
        // window.open(helpPageUrl, '_blank');
    };

    const isMobile = useBreakpoint().xs;

    const ptpVer = process.env.REACT_APP_PTPVER

    const clickMenu = ev => {
        // console.log(ev)
        navigate(`/${ev.key}`)
    }


    const items = [
        {
            label: <div onClick={() => navigate('/useraccount')}>Мой аккаунт</div>,
            key: 'account',
        },
        {
            label: <div onClick={(e) => logoutHandler(e)} >Выход</ div>,
            key: 'logout',
            icon: <LogoutOutlined />
        },

    ];



    return (
        <>
            {/* <Layout> */}
            <Header
                style={
                    {
                        padding: '10px',
                        display: 'flex',
                        position: 'fixed',
                        width: '100%',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        backgroundColor: 'rgb(5, 66, 150)',
                        zIndex: 50,
                    }
                }>

                <Flex

                    justify='left'
                    align='left'
                >
                    {collapsed
                        ? isMobile && (<BarsOutlined style={{ fontSize: '26px', color: '#fff', paddingRight: '1rem' }} onClick={onCollapse} />)
                        : isMobile && (<CloseOutlined style={{ fontSize: '26px', color: '#fff', paddingRight: '1rem' }} onClick={onCollapse} />)
                    }
                    <Logo style={{ width: '115px', fill: ptpVer === 'TEST' ? 'red' : 'white' }} className='navpanel' />
                </Flex>

                <Flex                >
                    {auth.checkRole(['admin', 'manager']) && <Menu
                        theme="dark"
                        mode="horizontal"
                        items={items1}
                        defaultSelectedKeys={['1']}
                        style={{
                            flex: 1,
                            // minWidth: 75,
                            width: 82,
                            backgroundColor: 'rgb(5, 66, 150)'
                        }}
                        onClick={(ev) => navigate(`/${ev?.key}`)}
                    />}
                    <Space size={3} >
                        <div className='help-menu'>
                            <Avatar style={{ backgroundColor: 'rgb(5, 66, 150)' }} size="large"
                                icon={<QuestionCircleOutlined />}
                                onClick={(e) => { openHelpPage(window.location.pathname) }} />
                        </div>
                        {auth.checkRole(['admin']) && <div className="settings-menu">
                            <Avatar
                                style={{ backgroundColor: 'rgb(5, 66, 150)' }}
                                size={'default'}
                                icon={<SettingOutlined />}
                                onClick={() => navigate('/settings')} />
                        </div>}

                        <div className="avatar-menu">
                            <Dropdown menu={{ items }} placement="bottomRight">
                                <Avatar
                                    style={{ backgroundColor: 'rgb(5, 43, 120)' }}
                                    size="large" icon={<UserOutlined />} />
                            </Dropdown>
                        </div>
                    </Space>

                </Flex>
            </Header >
            <Help visible={isHelpVisible} onClose={() => setIsHelpVisible(false)} />
            {/* </Layout> */}
        </>
    )
}