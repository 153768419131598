
import React, { useContext } from 'react';
import { Form, Input, Button, InputNumber, AutoComplete } from 'antd';
import { DataContext } from '../../context/DataContext';

const CarForm = ({ item, onSubmit }) => {
    const [form] = Form.useForm();
    const { branches } = useContext(DataContext)

    return (
        <Form
            form={form}
            initialValues={item}
            onFinish={onSubmit}
            layout="vertical"
        >
            <Form.Item
                name="title"
                label="Наименование"
                rules={[{ required: true, message: 'Пожалуйста, введите наименование' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="VIN"
                label="VIN"
                rules={[{ required: true, message: 'Пожалуйста, введите VIN номер' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="plateNumber"
                label="Гос.номер"
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="yearOfProduction"
                label="Год выпуска"
            >
                <InputNumber />
            </Form.Item>
            <Form.Item
                name="branch"
                label="Подразделение"
                rules={[{ required: false, message: 'Выберите подраздление' }]}
            >
                <AutoComplete
                    options={branches}
                    allowClear
                    placeholder="Выберите подразделение"
                    filterOption={(inputValue, option) =>
                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    } />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    {item?._id ? 'Записать' : `+ Добавить автомобиль`}
                </Button>
            </Form.Item>
        </Form>
    );
};

export default CarForm;
