import React from 'react'

export const Flame = ({ style }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={style.width} height={style.height} viewBox="0 0 2100 2100">
            <defs>
                <clipPath id="clip-0">
                    <path clipRule="nonzero" d="M 747 392 L 1353 392 L 1353 1708 L 747 1708 Z M 747 392 " />
                </clipPath>
                <clipPath id="clip-1">
                    <path clipRule="nonzero" d="M 998.027344 1707.398438 C 891.679688 1651.449219 804.066406 1556.769531 766.660156 1442.570312 C 729.257812 1328.371094 745.769531 1195.488281 818.507812 1099.839844 C 802.414062 1146.601562 799.890625 1197.941406 811.316406 1246.050781 C 817.554688 1272.328125 831.230469 1300.808594 857.28125 1307.949219 C 892.6875 1317.648438 924.503906 1281.519531 935.242188 1246.421875 C 953.023438 1188.289062 941.265625 1124.859375 919.425781 1068.128906 C 897.585938 1011.390625 866.0625 958.871094 842.476562 902.839844 C 806.539062 817.46875 789.480469 722.960938 802.476562 631.25 C 815.472656 539.539062 860.003906 451.070312 931.84375 392.601562 C 851.65625 512.269531 863.398438 685.558594 958.992188 793.320312 C 996.65625 835.78125 1044.679688 867.808594 1084.109375 908.628906 C 1174.671875 1002.378906 1213.648438 1143.128906 1184.21875 1270.109375 C 1206.621094 1215.351562 1228.910156 1159.738281 1236.738281 1101.101562 C 1244.578125 1042.460938 1236.570312 979.539062 1202.609375 931.101562 C 1284.789062 979.601562 1341.699219 1068.609375 1351.25 1163.558594 C 1360.800781 1258.519531 1322.75 1357.078125 1251.871094 1420.980469 C 1197.910156 1469.621094 1128.269531 1497.671875 1072.261719 1543.941406 C 1016.25 1590.210938 973.960938 1668.210938 998.027344 1707.398438 Z M 998.027344 1707.398438 " />
                </clipPath>
                <clipPath id="clip-2">
                    <path clipRule="nonzero" d="M 998.027344 1707.398438 C 891.679688 1651.449219 804.066406 1556.769531 766.660156 1442.570312 C 729.257812 1328.371094 745.769531 1195.488281 818.507812 1099.839844 C 802.414062 1146.601562 799.890625 1197.941406 811.316406 1246.050781 C 817.554688 1272.328125 831.230469 1300.808594 857.28125 1307.949219 C 892.6875 1317.648438 924.503906 1281.519531 935.242188 1246.421875 C 953.023438 1188.289062 941.265625 1124.859375 919.425781 1068.128906 C 897.585938 1011.390625 866.0625 958.871094 842.476562 902.839844 C 806.539062 817.46875 789.480469 722.960938 802.476562 631.25 C 815.472656 539.539062 860.003906 451.070312 931.84375 392.601562 C 851.65625 512.269531 863.398438 685.558594 958.992188 793.320312 C 996.65625 835.78125 1044.679688 867.808594 1084.109375 908.628906 C 1174.671875 1002.378906 1213.648438 1143.128906 1184.21875 1270.109375 C 1206.621094 1215.351562 1228.910156 1159.738281 1236.738281 1101.101562 C 1244.578125 1042.460938 1236.570312 979.539062 1202.609375 931.101562 C 1284.789062 979.601562 1341.699219 1068.609375 1351.25 1163.558594 C 1360.800781 1258.519531 1322.75 1357.078125 1251.871094 1420.980469 C 1197.910156 1469.621094 1128.269531 1497.671875 1072.261719 1543.941406 C 1016.25 1590.210938 973.960938 1668.210938 998.027344 1707.398438 " />
                </clipPath>
                <radialGradient id="radial-pattern-0" gradientUnits="userSpaceOnUse" cx="0" cy="0" fx="0" fy="0" r="947.377" gradientTransform="matrix(1, 0, 0, 1, 1004.71, 1541.41)">
                    <stop offset="0" stopColor="rgb(94.114685%, 77.113342%, 29.748535%)" stopOpacity="1" />
                    <stop offset="0.00390625" stopColor="rgb(94.113159%, 76.831055%, 29.638672%)" stopOpacity="1" />
                    <stop offset="0.0078125" stopColor="rgb(94.110107%, 76.550293%, 29.530334%)" stopOpacity="1" />
                    <stop offset="0.0117188" stopColor="rgb(94.107056%, 76.269531%, 29.420471%)" stopOpacity="1" />
                    <stop offset="0.015625" stopColor="rgb(94.104004%, 75.987244%, 29.312134%)" stopOpacity="1" />
                    <stop offset="0.0195312" stopColor="rgb(94.100952%, 75.706482%, 29.202271%)" stopOpacity="1" />
                    <stop offset="0.0234375" stopColor="rgb(94.0979%, 75.424194%, 29.093933%)" stopOpacity="1" />
                    <stop offset="0.0273438" stopColor="rgb(94.094849%, 75.143433%, 28.98407%)" stopOpacity="1" />
                    <stop offset="0.03125" stopColor="rgb(94.091797%, 74.862671%, 28.875732%)" stopOpacity="1" />
                    <stop offset="0.0351562" stopColor="rgb(94.088745%, 74.580383%, 28.765869%)" stopOpacity="1" />
                    <stop offset="0.0390625" stopColor="rgb(94.087219%, 74.299622%, 28.657532%)" stopOpacity="1" />
                    <stop offset="0.0429688" stopColor="rgb(94.084167%, 74.017334%, 28.547668%)" stopOpacity="1" />
                    <stop offset="0.046875" stopColor="rgb(94.081116%, 73.736572%, 28.439331%)" stopOpacity="1" />
                    <stop offset="0.0507812" stopColor="rgb(94.078064%, 73.455811%, 28.329468%)" stopOpacity="1" />
                    <stop offset="0.0546875" stopColor="rgb(94.075012%, 73.173523%, 28.22113%)" stopOpacity="1" />
                    <stop offset="0.0585938" stopColor="rgb(94.07196%, 72.892761%, 28.111267%)" stopOpacity="1" />
                    <stop offset="0.0625" stopColor="rgb(94.068909%, 72.610474%, 28.00293%)" stopOpacity="1" />
                    <stop offset="0.0664062" stopColor="rgb(94.065857%, 72.329712%, 27.893066%)" stopOpacity="1" />
                    <stop offset="0.0703125" stopColor="rgb(94.064331%, 72.04895%, 27.784729%)" stopOpacity="1" />
                    <stop offset="0.0742188" stopColor="rgb(94.061279%, 71.766663%, 27.674866%)" stopOpacity="1" />
                    <stop offset="0.078125" stopColor="rgb(94.058228%, 71.485901%, 27.566528%)" stopOpacity="1" />
                    <stop offset="0.0820312" stopColor="rgb(94.055176%, 71.203613%, 27.456665%)" stopOpacity="1" />
                    <stop offset="0.0859375" stopColor="rgb(94.052124%, 70.922852%, 27.348328%)" stopOpacity="1" />
                    <stop offset="0.0898438" stopColor="rgb(94.049072%, 70.64209%, 27.238464%)" stopOpacity="1" />
                    <stop offset="0.09375" stopColor="rgb(94.046021%, 70.359802%, 27.130127%)" stopOpacity="1" />
                    <stop offset="0.0976562" stopColor="rgb(94.042969%, 70.079041%, 27.020264%)" stopOpacity="1" />
                    <stop offset="0.101562" stopColor="rgb(94.039917%, 69.796753%, 26.911926%)" stopOpacity="1" />
                    <stop offset="0.105469" stopColor="rgb(94.038391%, 69.515991%, 26.802063%)" stopOpacity="1" />
                    <stop offset="0.109375" stopColor="rgb(94.035339%, 69.233704%, 26.693726%)" stopOpacity="1" />
                    <stop offset="0.113281" stopColor="rgb(94.032288%, 68.952942%, 26.583862%)" stopOpacity="1" />
                    <stop offset="0.117188" stopColor="rgb(94.029236%, 68.67218%, 26.475525%)" stopOpacity="1" />
                    <stop offset="0.121094" stopColor="rgb(94.026184%, 68.389893%, 26.365662%)" stopOpacity="1" />
                    <stop offset="0.125" stopColor="rgb(94.023132%, 68.109131%, 26.257324%)" stopOpacity="1" />
                    <stop offset="0.128906" stopColor="rgb(94.020081%, 67.826843%, 26.147461%)" stopOpacity="1" />
                    <stop offset="0.132812" stopColor="rgb(94.017029%, 67.546082%, 26.039124%)" stopOpacity="1" />
                    <stop offset="0.136719" stopColor="rgb(94.015503%, 67.26532%, 25.92926%)" stopOpacity="1" />
                    <stop offset="0.140625" stopColor="rgb(94.012451%, 66.983032%, 25.820923%)" stopOpacity="1" />
                    <stop offset="0.144531" stopColor="rgb(94.009399%, 66.702271%, 25.71106%)" stopOpacity="1" />
                    <stop offset="0.148438" stopColor="rgb(94.006348%, 66.419983%, 25.602722%)" stopOpacity="1" />
                    <stop offset="0.152344" stopColor="rgb(94.003296%, 66.139221%, 25.492859%)" stopOpacity="1" />
                    <stop offset="0.15625" stopColor="rgb(94.000244%, 65.858459%, 25.384521%)" stopOpacity="1" />
                    <stop offset="0.160156" stopColor="rgb(93.997192%, 65.576172%, 25.274658%)" stopOpacity="1" />
                    <stop offset="0.164062" stopColor="rgb(93.994141%, 65.29541%, 25.166321%)" stopOpacity="1" />
                    <stop offset="0.167969" stopColor="rgb(93.992615%, 65.013123%, 25.056458%)" stopOpacity="1" />
                    <stop offset="0.171875" stopColor="rgb(93.989563%, 64.732361%, 24.94812%)" stopOpacity="1" />
                    <stop offset="0.175781" stopColor="rgb(93.986511%, 64.451599%, 24.838257%)" stopOpacity="1" />
                    <stop offset="0.179688" stopColor="rgb(93.983459%, 64.169312%, 24.729919%)" stopOpacity="1" />
                    <stop offset="0.183594" stopColor="rgb(93.980408%, 63.88855%, 24.620056%)" stopOpacity="1" />
                    <stop offset="0.1875" stopColor="rgb(93.977356%, 63.606262%, 24.511719%)" stopOpacity="1" />
                    <stop offset="0.191406" stopColor="rgb(93.974304%, 63.3255%, 24.401855%)" stopOpacity="1" />
                    <stop offset="0.195312" stopColor="rgb(93.971252%, 63.044739%, 24.293518%)" stopOpacity="1" />
                    <stop offset="0.199219" stopColor="rgb(93.968201%, 62.762451%, 24.183655%)" stopOpacity="1" />
                    <stop offset="0.203125" stopColor="rgb(93.966675%, 62.481689%, 24.075317%)" stopOpacity="1" />
                    <stop offset="0.207031" stopColor="rgb(93.963623%, 62.199402%, 23.965454%)" stopOpacity="1" />
                    <stop offset="0.210938" stopColor="rgb(93.960571%, 61.91864%, 23.857117%)" stopOpacity="1" />
                    <stop offset="0.214844" stopColor="rgb(93.95752%, 61.637878%, 23.747253%)" stopOpacity="1" />
                    <stop offset="0.21875" stopColor="rgb(93.954468%, 61.355591%, 23.638916%)" stopOpacity="1" />
                    <stop offset="0.222656" stopColor="rgb(93.951416%, 61.074829%, 23.529053%)" stopOpacity="1" />
                    <stop offset="0.226562" stopColor="rgb(93.948364%, 60.792542%, 23.420715%)" stopOpacity="1" />
                    <stop offset="0.230469" stopColor="rgb(93.945312%, 60.51178%, 23.310852%)" stopOpacity="1" />
                    <stop offset="0.234375" stopColor="rgb(93.943787%, 60.229492%, 23.202515%)" stopOpacity="1" />
                    <stop offset="0.238281" stopColor="rgb(93.940735%, 59.94873%, 23.092651%)" stopOpacity="1" />
                    <stop offset="0.242188" stopColor="rgb(93.937683%, 59.667969%, 22.984314%)" stopOpacity="1" />
                    <stop offset="0.246094" stopColor="rgb(93.934631%, 59.385681%, 22.874451%)" stopOpacity="1" />
                    <stop offset="0.25" stopColor="rgb(93.93158%, 59.104919%, 22.766113%)" stopOpacity="1" />
                    <stop offset="0.253906" stopColor="rgb(93.928528%, 58.822632%, 22.65625%)" stopOpacity="1" />
                    <stop offset="0.257812" stopColor="rgb(93.925476%, 58.54187%, 22.547913%)" stopOpacity="1" />
                    <stop offset="0.261719" stopColor="rgb(93.922424%, 58.261108%, 22.438049%)" stopOpacity="1" />
                    <stop offset="0.265625" stopColor="rgb(93.919373%, 57.978821%, 22.329712%)" stopOpacity="1" />
                    <stop offset="0.269531" stopColor="rgb(93.917847%, 57.698059%, 22.219849%)" stopOpacity="1" />
                    <stop offset="0.273438" stopColor="rgb(93.914795%, 57.415771%, 22.109985%)" stopOpacity="1" />
                    <stop offset="0.277344" stopColor="rgb(93.911743%, 57.13501%, 22.001648%)" stopOpacity="1" />
                    <stop offset="0.28125" stopColor="rgb(93.908691%, 56.854248%, 21.891785%)" stopOpacity="1" />
                    <stop offset="0.285156" stopColor="rgb(93.90564%, 56.57196%, 21.783447%)" stopOpacity="1" />
                    <stop offset="0.289062" stopColor="rgb(93.902588%, 56.291199%, 21.673584%)" stopOpacity="1" />
                    <stop offset="0.292969" stopColor="rgb(93.899536%, 56.008911%, 21.565247%)" stopOpacity="1" />
                    <stop offset="0.296875" stopColor="rgb(93.896484%, 55.728149%, 21.455383%)" stopOpacity="1" />
                    <stop offset="0.300781" stopColor="rgb(93.894958%, 55.447388%, 21.347046%)" stopOpacity="1" />
                    <stop offset="0.304688" stopColor="rgb(93.891907%, 55.1651%, 21.237183%)" stopOpacity="1" />
                    <stop offset="0.308594" stopColor="rgb(93.888855%, 54.884338%, 21.128845%)" stopOpacity="1" />
                    <stop offset="0.3125" stopColor="rgb(93.885803%, 54.602051%, 21.018982%)" stopOpacity="1" />
                    <stop offset="0.316406" stopColor="rgb(93.882751%, 54.321289%, 20.910645%)" stopOpacity="1" />
                    <stop offset="0.320312" stopColor="rgb(93.8797%, 54.040527%, 20.800781%)" stopOpacity="1" />
                    <stop offset="0.324219" stopColor="rgb(93.876648%, 53.75824%, 20.692444%)" stopOpacity="1" />
                    <stop offset="0.328125" stopColor="rgb(93.873596%, 53.477478%, 20.582581%)" stopOpacity="1" />
                    <stop offset="0.332031" stopColor="rgb(93.870544%, 53.19519%, 20.474243%)" stopOpacity="1" />
                    <stop offset="0.335938" stopColor="rgb(93.869019%, 52.914429%, 20.36438%)" stopOpacity="1" />
                    <stop offset="0.339844" stopColor="rgb(93.865967%, 52.633667%, 20.256042%)" stopOpacity="1" />
                    <stop offset="0.34375" stopColor="rgb(93.862915%, 52.351379%, 20.146179%)" stopOpacity="1" />
                    <stop offset="0.347656" stopColor="rgb(93.859863%, 52.070618%, 20.037842%)" stopOpacity="1" />
                    <stop offset="0.351562" stopColor="rgb(93.856812%, 51.78833%, 19.927979%)" stopOpacity="1" />
                    <stop offset="0.355469" stopColor="rgb(93.85376%, 51.507568%, 19.819641%)" stopOpacity="1" />
                    <stop offset="0.359375" stopColor="rgb(93.850708%, 51.225281%, 19.709778%)" stopOpacity="1" />
                    <stop offset="0.363281" stopColor="rgb(93.847656%, 50.944519%, 19.60144%)" stopOpacity="1" />
                    <stop offset="0.367188" stopColor="rgb(93.84613%, 50.663757%, 19.491577%)" stopOpacity="1" />
                    <stop offset="0.371094" stopColor="rgb(93.843079%, 50.38147%, 19.38324%)" stopOpacity="1" />
                    <stop offset="0.375" stopColor="rgb(93.840027%, 50.100708%, 19.273376%)" stopOpacity="1" />
                    <stop offset="0.378906" stopColor="rgb(93.836975%, 49.81842%, 19.165039%)" stopOpacity="1" />
                    <stop offset="0.382812" stopColor="rgb(93.833923%, 49.537659%, 19.055176%)" stopOpacity="1" />
                    <stop offset="0.386719" stopColor="rgb(93.830872%, 49.256897%, 18.946838%)" stopOpacity="1" />
                    <stop offset="0.390625" stopColor="rgb(93.82782%, 48.974609%, 18.836975%)" stopOpacity="1" />
                    <stop offset="0.394531" stopColor="rgb(93.824768%, 48.693848%, 18.728638%)" stopOpacity="1" />
                    <stop offset="0.398438" stopColor="rgb(93.823242%, 48.41156%, 18.618774%)" stopOpacity="1" />
                    <stop offset="0.402344" stopColor="rgb(93.82019%, 48.130798%, 18.510437%)" stopOpacity="1" />
                    <stop offset="0.40625" stopColor="rgb(93.817139%, 47.850037%, 18.400574%)" stopOpacity="1" />
                    <stop offset="0.410156" stopColor="rgb(93.814087%, 47.567749%, 18.292236%)" stopOpacity="1" />
                    <stop offset="0.414062" stopColor="rgb(93.811035%, 47.286987%, 18.182373%)" stopOpacity="1" />
                    <stop offset="0.417969" stopColor="rgb(93.807983%, 47.0047%, 18.074036%)" stopOpacity="1" />
                    <stop offset="0.421875" stopColor="rgb(93.804932%, 46.723938%, 17.964172%)" stopOpacity="1" />
                    <stop offset="0.425781" stopColor="rgb(93.80188%, 46.443176%, 17.855835%)" stopOpacity="1" />
                    <stop offset="0.429688" stopColor="rgb(93.798828%, 46.160889%, 17.745972%)" stopOpacity="1" />
                    <stop offset="0.433594" stopColor="rgb(93.797302%, 45.880127%, 17.637634%)" stopOpacity="1" />
                    <stop offset="0.4375" stopColor="rgb(93.79425%, 45.597839%, 17.527771%)" stopOpacity="1" />
                    <stop offset="0.441406" stopColor="rgb(93.791199%, 45.317078%, 17.419434%)" stopOpacity="1" />
                    <stop offset="0.445312" stopColor="rgb(93.788147%, 45.036316%, 17.30957%)" stopOpacity="1" />
                    <stop offset="0.449219" stopColor="rgb(93.785095%, 44.754028%, 17.201233%)" stopOpacity="1" />
                    <stop offset="0.453125" stopColor="rgb(93.782043%, 44.473267%, 17.09137%)" stopOpacity="1" />
                    <stop offset="0.457031" stopColor="rgb(93.778992%, 44.190979%, 16.983032%)" stopOpacity="1" />
                    <stop offset="0.460938" stopColor="rgb(93.77594%, 43.910217%, 16.873169%)" stopOpacity="1" />
                    <stop offset="0.464844" stopColor="rgb(93.774414%, 43.629456%, 16.764832%)" stopOpacity="1" />
                    <stop offset="0.46875" stopColor="rgb(93.771362%, 43.347168%, 16.654968%)" stopOpacity="1" />
                    <stop offset="0.472656" stopColor="rgb(93.768311%, 43.066406%, 16.546631%)" stopOpacity="1" />
                    <stop offset="0.476562" stopColor="rgb(93.765259%, 42.784119%, 16.436768%)" stopOpacity="1" />
                    <stop offset="0.480469" stopColor="rgb(93.762207%, 42.503357%, 16.32843%)" stopOpacity="1" />
                    <stop offset="0.484375" stopColor="rgb(93.759155%, 42.221069%, 16.218567%)" stopOpacity="1" />
                    <stop offset="0.488281" stopColor="rgb(93.756104%, 41.940308%, 16.110229%)" stopOpacity="1" />
                    <stop offset="0.492188" stopColor="rgb(93.753052%, 41.659546%, 16.000366%)" stopOpacity="1" />
                    <stop offset="0.496094" stopColor="rgb(93.75%, 41.377258%, 15.892029%)" stopOpacity="1" />
                    <stop offset="0.5" stopColor="rgb(93.748474%, 41.096497%, 15.782166%)" stopOpacity="1" />
                    <stop offset="0.503906" stopColor="rgb(93.745422%, 40.814209%, 15.673828%)" stopOpacity="1" />
                    <stop offset="0.507812" stopColor="rgb(93.742371%, 40.533447%, 15.563965%)" stopOpacity="1" />
                    <stop offset="0.511719" stopColor="rgb(93.739319%, 40.252686%, 15.455627%)" stopOpacity="1" />
                    <stop offset="0.515625" stopColor="rgb(93.736267%, 39.970398%, 15.345764%)" stopOpacity="1" />
                    <stop offset="0.519531" stopColor="rgb(93.733215%, 39.689636%, 15.237427%)" stopOpacity="1" />
                    <stop offset="0.523438" stopColor="rgb(93.730164%, 39.407349%, 15.127563%)" stopOpacity="1" />
                    <stop offset="0.527344" stopColor="rgb(93.727112%, 39.126587%, 15.019226%)" stopOpacity="1" />
                    <stop offset="0.53125" stopColor="rgb(93.721008%, 38.859558%, 14.929199%)" stopOpacity="1" />
                    <stop offset="0.535156" stopColor="rgb(93.702698%, 38.626099%, 14.888%)" stopOpacity="1" />
                    <stop offset="0.539062" stopColor="rgb(93.67981%, 38.412476%, 14.875793%)" stopOpacity="1" />
                    <stop offset="0.542969" stopColor="rgb(93.656921%, 38.198853%, 14.862061%)" stopOpacity="1" />
                    <stop offset="0.546875" stopColor="rgb(93.634033%, 37.986755%, 14.849854%)" stopOpacity="1" />
                    <stop offset="0.550781" stopColor="rgb(93.611145%, 37.773132%, 14.836121%)" stopOpacity="1" />
                    <stop offset="0.554688" stopColor="rgb(93.588257%, 37.559509%, 14.822388%)" stopOpacity="1" />
                    <stop offset="0.558594" stopColor="rgb(93.565369%, 37.345886%, 14.810181%)" stopOpacity="1" />
                    <stop offset="0.5625" stopColor="rgb(93.54248%, 37.132263%, 14.796448%)" stopOpacity="1" />
                    <stop offset="0.566406" stopColor="rgb(93.519592%, 36.91864%, 14.784241%)" stopOpacity="1" />
                    <stop offset="0.570312" stopColor="rgb(93.496704%, 36.705017%, 14.770508%)" stopOpacity="1" />
                    <stop offset="0.574219" stopColor="rgb(93.47229%, 36.491394%, 14.756775%)" stopOpacity="1" />
                    <stop offset="0.578125" stopColor="rgb(93.449402%, 36.279297%, 14.744568%)" stopOpacity="1" />
                    <stop offset="0.582031" stopColor="rgb(93.426514%, 36.065674%, 14.730835%)" stopOpacity="1" />
                    <stop offset="0.585938" stopColor="rgb(93.403625%, 35.852051%, 14.718628%)" stopOpacity="1" />
                    <stop offset="0.589844" stopColor="rgb(93.380737%, 35.638428%, 14.704895%)" stopOpacity="1" />
                    <stop offset="0.59375" stopColor="rgb(93.357849%, 35.424805%, 14.691162%)" stopOpacity="1" />
                    <stop offset="0.597656" stopColor="rgb(93.334961%, 35.211182%, 14.678955%)" stopOpacity="1" />
                    <stop offset="0.601562" stopColor="rgb(93.312073%, 34.997559%, 14.665222%)" stopOpacity="1" />
                    <stop offset="0.605469" stopColor="rgb(93.289185%, 34.783936%, 14.651489%)" stopOpacity="1" />
                    <stop offset="0.609375" stopColor="rgb(93.266296%, 34.570312%, 14.639282%)" stopOpacity="1" />
                    <stop offset="0.613281" stopColor="rgb(93.243408%, 34.358215%, 14.625549%)" stopOpacity="1" />
                    <stop offset="0.617188" stopColor="rgb(93.22052%, 34.144592%, 14.613342%)" stopOpacity="1" />
                    <stop offset="0.621094" stopColor="rgb(93.197632%, 33.930969%, 14.599609%)" stopOpacity="1" />
                    <stop offset="0.625" stopColor="rgb(93.174744%, 33.717346%, 14.585876%)" stopOpacity="1" />
                    <stop offset="0.628906" stopColor="rgb(93.151855%, 33.503723%, 14.573669%)" stopOpacity="1" />
                    <stop offset="0.632812" stopColor="rgb(93.128967%, 33.2901%, 14.559937%)" stopOpacity="1" />
                    <stop offset="0.636719" stopColor="rgb(93.104553%, 33.076477%, 14.547729%)" stopOpacity="1" />
                    <stop offset="0.640625" stopColor="rgb(93.081665%, 32.862854%, 14.533997%)" stopOpacity="1" />
                    <stop offset="0.644531" stopColor="rgb(93.058777%, 32.649231%, 14.520264%)" stopOpacity="1" />
                    <stop offset="0.648438" stopColor="rgb(93.035889%, 32.437134%, 14.508057%)" stopOpacity="1" />
                    <stop offset="0.652344" stopColor="rgb(93.013%, 32.223511%, 14.494324%)" stopOpacity="1" />
                    <stop offset="0.65625" stopColor="rgb(92.990112%, 32.009888%, 14.482117%)" stopOpacity="1" />
                    <stop offset="0.660156" stopColor="rgb(92.967224%, 31.796265%, 14.468384%)" stopOpacity="1" />
                    <stop offset="0.664062" stopColor="rgb(92.944336%, 31.582642%, 14.454651%)" stopOpacity="1" />
                    <stop offset="0.667969" stopColor="rgb(92.921448%, 31.369019%, 14.442444%)" stopOpacity="1" />
                    <stop offset="0.671875" stopColor="rgb(92.89856%, 31.155396%, 14.428711%)" stopOpacity="1" />
                    <stop offset="0.675781" stopColor="rgb(92.875671%, 30.941772%, 14.416504%)" stopOpacity="1" />
                    <stop offset="0.679688" stopColor="rgb(92.852783%, 30.728149%, 14.402771%)" stopOpacity="1" />
                    <stop offset="0.683594" stopColor="rgb(92.829895%, 30.516052%, 14.389038%)" stopOpacity="1" />
                    <stop offset="0.6875" stopColor="rgb(92.807007%, 30.302429%, 14.376831%)" stopOpacity="1" />
                    <stop offset="0.691406" stopColor="rgb(92.784119%, 30.088806%, 14.363098%)" stopOpacity="1" />
                    <stop offset="0.695312" stopColor="rgb(92.759705%, 29.875183%, 14.349365%)" stopOpacity="1" />
                    <stop offset="0.699219" stopColor="rgb(92.736816%, 29.66156%, 14.337158%)" stopOpacity="1" />
                    <stop offset="0.703125" stopColor="rgb(92.713928%, 29.447937%, 14.323425%)" stopOpacity="1" />
                    <stop offset="0.707031" stopColor="rgb(92.69104%, 29.234314%, 14.311218%)" stopOpacity="1" />
                    <stop offset="0.710938" stopColor="rgb(92.668152%, 29.020691%, 14.297485%)" stopOpacity="1" />
                    <stop offset="0.714844" stopColor="rgb(92.645264%, 28.807068%, 14.283752%)" stopOpacity="1" />
                    <stop offset="0.71875" stopColor="rgb(92.622375%, 28.594971%, 14.271545%)" stopOpacity="1" />
                    <stop offset="0.722656" stopColor="rgb(92.599487%, 28.381348%, 14.257812%)" stopOpacity="1" />
                    <stop offset="0.726562" stopColor="rgb(92.576599%, 28.167725%, 14.245605%)" stopOpacity="1" />
                    <stop offset="0.730469" stopColor="rgb(92.553711%, 27.954102%, 14.231873%)" stopOpacity="1" />
                    <stop offset="0.734375" stopColor="rgb(92.530823%, 27.740479%, 14.21814%)" stopOpacity="1" />
                    <stop offset="0.738281" stopColor="rgb(92.507935%, 27.526855%, 14.205933%)" stopOpacity="1" />
                    <stop offset="0.742188" stopColor="rgb(92.485046%, 27.313232%, 14.1922%)" stopOpacity="1" />
                    <stop offset="0.746094" stopColor="rgb(92.462158%, 27.099609%, 14.179993%)" stopOpacity="1" />
                    <stop offset="0.75" stopColor="rgb(92.43927%, 26.885986%, 14.16626%)" stopOpacity="1" />
                    <stop offset="0.753906" stopColor="rgb(92.416382%, 26.673889%, 14.152527%)" stopOpacity="1" />
                    <stop offset="0.757812" stopColor="rgb(92.391968%, 26.460266%, 14.14032%)" stopOpacity="1" />
                    <stop offset="0.761719" stopColor="rgb(92.36908%, 26.246643%, 14.126587%)" stopOpacity="1" />
                    <stop offset="0.765625" stopColor="rgb(92.346191%, 26.03302%, 14.11438%)" stopOpacity="1" />
                    <stop offset="0.769531" stopColor="rgb(92.323303%, 25.819397%, 14.100647%)" stopOpacity="1" />
                    <stop offset="0.773438" stopColor="rgb(92.300415%, 25.605774%, 14.086914%)" stopOpacity="1" />
                    <stop offset="0.777344" stopColor="rgb(92.277527%, 25.392151%, 14.074707%)" stopOpacity="1" />
                    <stop offset="0.78125" stopColor="rgb(92.254639%, 25.178528%, 14.060974%)" stopOpacity="1" />
                    <stop offset="0.785156" stopColor="rgb(92.23175%, 24.964905%, 14.047241%)" stopOpacity="1" />
                    <stop offset="0.789062" stopColor="rgb(92.208862%, 24.752808%, 14.035034%)" stopOpacity="1" />
                    <stop offset="0.792969" stopColor="rgb(92.185974%, 24.539185%, 14.021301%)" stopOpacity="1" />
                    <stop offset="0.796875" stopColor="rgb(92.163086%, 24.325562%, 14.009094%)" stopOpacity="1" />
                    <stop offset="0.800781" stopColor="rgb(92.140198%, 24.111938%, 13.995361%)" stopOpacity="1" />
                    <stop offset="0.804688" stopColor="rgb(92.11731%, 23.898315%, 13.981628%)" stopOpacity="1" />
                    <stop offset="0.808594" stopColor="rgb(92.094421%, 23.684692%, 13.969421%)" stopOpacity="1" />
                    <stop offset="0.8125" stopColor="rgb(92.071533%, 23.471069%, 13.955688%)" stopOpacity="1" />
                    <stop offset="0.816406" stopColor="rgb(92.047119%, 23.257446%, 13.943481%)" stopOpacity="1" />
                    <stop offset="0.820312" stopColor="rgb(92.024231%, 23.043823%, 13.929749%)" stopOpacity="1" />
                    <stop offset="0.824219" stopColor="rgb(92.001343%, 22.831726%, 13.916016%)" stopOpacity="1" />
                    <stop offset="0.828125" stopColor="rgb(91.978455%, 22.618103%, 13.903809%)" stopOpacity="1" />
                    <stop offset="0.832031" stopColor="rgb(91.955566%, 22.40448%, 13.890076%)" stopOpacity="1" />
                    <stop offset="0.835938" stopColor="rgb(91.932678%, 22.190857%, 13.877869%)" stopOpacity="1" />
                    <stop offset="0.839844" stopColor="rgb(91.90979%, 21.977234%, 13.864136%)" stopOpacity="1" />
                    <stop offset="0.84375" stopColor="rgb(91.886902%, 21.763611%, 13.850403%)" stopOpacity="1" />
                    <stop offset="0.847656" stopColor="rgb(91.864014%, 21.549988%, 13.838196%)" stopOpacity="1" />
                    <stop offset="0.851562" stopColor="rgb(91.841125%, 21.336365%, 13.824463%)" stopOpacity="1" />
                    <stop offset="0.855469" stopColor="rgb(91.818237%, 21.122742%, 13.812256%)" stopOpacity="1" />
                    <stop offset="0.859375" stopColor="rgb(91.795349%, 20.910645%, 13.798523%)" stopOpacity="1" />
                    <stop offset="0.863281" stopColor="rgb(91.772461%, 20.697021%, 13.78479%)" stopOpacity="1" />
                    <stop offset="0.867188" stopColor="rgb(91.749573%, 20.483398%, 13.772583%)" stopOpacity="1" />
                    <stop offset="0.871094" stopColor="rgb(91.726685%, 20.269775%, 13.75885%)" stopOpacity="1" />
                    <stop offset="0.875" stopColor="rgb(91.702271%, 20.056152%, 13.745117%)" stopOpacity="1" />
                    <stop offset="0.878906" stopColor="rgb(91.679382%, 19.842529%, 13.73291%)" stopOpacity="1" />
                    <stop offset="0.882812" stopColor="rgb(91.656494%, 19.628906%, 13.719177%)" stopOpacity="1" />
                    <stop offset="0.886719" stopColor="rgb(91.633606%, 19.415283%, 13.70697%)" stopOpacity="1" />
                    <stop offset="0.890625" stopColor="rgb(91.610718%, 19.20166%, 13.693237%)" stopOpacity="1" />
                    <stop offset="0.894531" stopColor="rgb(91.58783%, 18.989563%, 13.679504%)" stopOpacity="1" />
                    <stop offset="0.898438" stopColor="rgb(91.564941%, 18.77594%, 13.667297%)" stopOpacity="1" />
                    <stop offset="0.902344" stopColor="rgb(91.542053%, 18.562317%, 13.653564%)" stopOpacity="1" />
                    <stop offset="0.90625" stopColor="rgb(91.519165%, 18.348694%, 13.641357%)" stopOpacity="1" />
                    <stop offset="0.910156" stopColor="rgb(91.496277%, 18.135071%, 13.627625%)" stopOpacity="1" />
                    <stop offset="0.914062" stopColor="rgb(91.473389%, 17.921448%, 13.613892%)" stopOpacity="1" />
                    <stop offset="0.917969" stopColor="rgb(91.4505%, 17.707825%, 13.601685%)" stopOpacity="1" />
                    <stop offset="0.921875" stopColor="rgb(91.427612%, 17.494202%, 13.587952%)" stopOpacity="1" />
                    <stop offset="0.925781" stopColor="rgb(91.404724%, 17.280579%, 13.575745%)" stopOpacity="1" />
                    <stop offset="0.929688" stopColor="rgb(91.381836%, 17.068481%, 13.562012%)" stopOpacity="1" />
                    <stop offset="0.933594" stopColor="rgb(91.358948%, 16.854858%, 13.548279%)" stopOpacity="1" />
                    <stop offset="0.9375" stopColor="rgb(91.334534%, 16.641235%, 13.536072%)" stopOpacity="1" />
                    <stop offset="0.941406" stopColor="rgb(91.311646%, 16.427612%, 13.522339%)" stopOpacity="1" />
                    <stop offset="0.945312" stopColor="rgb(91.288757%, 16.213989%, 13.510132%)" stopOpacity="1" />
                    <stop offset="0.949219" stopColor="rgb(91.265869%, 16.000366%, 13.496399%)" stopOpacity="1" />
                    <stop offset="0.953125" stopColor="rgb(91.242981%, 15.786743%, 13.482666%)" stopOpacity="1" />
                    <stop offset="0.957031" stopColor="rgb(91.220093%, 15.57312%, 13.470459%)" stopOpacity="1" />
                    <stop offset="0.960938" stopColor="rgb(91.197205%, 15.359497%, 13.456726%)" stopOpacity="1" />
                    <stop offset="0.964844" stopColor="rgb(91.174316%, 15.1474%, 13.442993%)" stopOpacity="1" />
                    <stop offset="0.96875" stopColor="rgb(91.151428%, 14.933777%, 13.430786%)" stopOpacity="1" />
                    <stop offset="0.972656" stopColor="rgb(91.12854%, 14.720154%, 13.417053%)" stopOpacity="1" />
                    <stop offset="0.976562" stopColor="rgb(91.105652%, 14.506531%, 13.404846%)" stopOpacity="1" />
                    <stop offset="0.980469" stopColor="rgb(91.082764%, 14.292908%, 13.391113%)" stopOpacity="1" />
                    <stop offset="0.984375" stopColor="rgb(91.059875%, 14.079285%, 13.37738%)" stopOpacity="1" />
                    <stop offset="0.988281" stopColor="rgb(91.036987%, 13.865662%, 13.365173%)" stopOpacity="1" />
                    <stop offset="0.992188" stopColor="rgb(91.014099%, 13.652039%, 13.35144%)" stopOpacity="1" />
                    <stop offset="0.996094" stopColor="rgb(90.989685%, 13.438416%, 13.339233%)" stopOpacity="1" />
                    <stop offset="1" stopColor="rgb(90.979004%, 13.33313%, 13.33313%)" stopOpacity="1" />
                </radialGradient>
            </defs>
            <g clipPath="url(#clip-0)">
                <g clipPath="url(#clip-1)">
                    <g clipPath="url(#clip-2)">
                        <path fillRule="nonzero" fill="url(#radial-pattern-0)" d="M 729.257812 392.601562 L 729.257812 1707.398438 L 1360.800781 1707.398438 L 1360.800781 392.601562 Z M 729.257812 392.601562 " />

                    </g>
                </g>
            </g>
        </svg>
    )
}
