import React, { useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom'
import { OfferListPage } from './pages/OfferListPage'
import { CreatePage } from './pages/CreatePage'
import { DetailPage } from './pages/DetailPage'
import { AuthPage } from './pages/AuthPage'
import { DashboardPage } from './pages/DashboardPage'
import { CustomerPage } from './pages/CustomerPage';
import { MachinesPage } from './pages/MachinesPage';
import { FormCreatePage } from './pages/FormCreatePage';
import { TaskPage } from './pages/TaskPage';
import { ContractsPage } from './pages/ContractPage';
import { WorkerPage } from './pages/WorkerPage';
import { EquipmentPage } from './pages/EquipmentPage';
import { RegisterPage } from './pages/Register/RegisterPage';
import PassportPage from './pages/PassportPage';
import PassportItem from './pages/PassportItem';
import UserPage from './pages/UserPage';
import PageNotFound from './pages/NotFound/PageNotFound';
import { UserAccountPage } from './pages/Register/UserAccountPage';
import { ForgotPasswordPage, ResetPasswordPage } from './pages/Register/ForgotPasswordPage';
// import { MyPlan } from './pages/Mytasks/MyPlan';
import { MyActual } from './pages/Mytasks/MyActual';
import CarPage from './pages/Cars/CarPage';
import { ActualPage } from './pages/Actual/ActualPage';
import { ForecastPage } from './pages/Forecast/ForecastPage';
import { Settings } from './pages/Settings/Settings';
import { AuthContext } from './context/AuthContext';
// import Help from './components/Help/Help';
import { ClientPageV2 } from './pages/ClientPage/ClientPageV2';
// import { ClientPage } from './pages/ClientPage/ClientPage';
import { NavigationPanel } from './pages/ClientPage/NavigationPanel';
// import { HelpProvider } from './context/HelpContext';


export const useRoutes = isAuthenticated => {


    const { checkRole } = useContext(AuthContext)

    if (isAuthenticated) {
        return (<>

            <Routes>
                <Route path="/offerlist" exact element={<OfferListPage />} />
                <Route path="/customers" exact element={<CustomerPage />} />
                <Route path="/machines" exact element={<MachinesPage />} />
                <Route path="/create" exact element={<CreatePage />} />
                <Route path="/forms" exact element={<FormCreatePage />} />
                <Route path="/tasks" exact element={<TaskPage field={'plan'} />} />
                <Route path="/taskssent" exact element={<TaskPage field={'submitted'} />} />
                <Route path="/tasksdone" exact element={<TaskPage field={'done'} />} />
                <Route path="/users" exact element={<UserPage />} />
                <Route path="/passports" exact element={<PassportPage />} />
                {/* <Route path="/passport/:id" exact element={<PassportItem key="pass" />} /> */}
                <Route path="/passport" exact element={<PassportItem key="pass" />} />
                {/* <Route path="/help/:pageId" render={(props) => <Help pageId={props.params.pageId} />} /> */}
                <Route path="/contracts" exact element={<ContractsPage />} />
                <Route path="/equipment" exact element={<EquipmentPage />} />
                <Route path="/dashboard" exact element={<DashboardPage />} />
                <Route path="/detail/:id" element={<DetailPage />} />
                <Route path="/workers" element={<WorkerPage />} />
                <Route path="/useraccount" element={<UserAccountPage />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/actual" element={<ActualPage />} />
                <Route path="/myactual" element={<MyActual />} />
                <Route path="/forecast" element={<ForecastPage />} />
                <Route path="/nodocs" exact element={<TaskPage field={'nodocs'} />} />
                <Route path="/alltasks" exact element={<TaskPage field={'alltasks'} />} />
                <Route path="/myalltasks" exact element={<TaskPage field={'alltasks'} rl={'user'} />} />
                <Route path="/mytaskssent" exact element={<TaskPage field={'submitted'} rl={'user'} />} />
                <Route path="/mytasksdone" exact element={<TaskPage field={'done'} rl={"user"} />} />
                <Route path='/myplan' element={<TaskPage field={'plan'} rl={'user'} />} />
                <Route path='/servicecars' element={<CarPage />} />
                <Route path="/404" exact element={<PageNotFound />} />

                <Route path="*" element={checkRole(["admin", "manager"])
                    ? <Route path="/dashboard" exact element={<DashboardPage />} replace />
                    : <Navigate to="/mytaskssent" />} replace />
            </Routes>
        </>
        )
    }
    return (
        <Routes>
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/" exact element={<AuthPage />} />
            {/* <Route path='/clientv2' element={<ClientPageV2 />} /> */}
            {/* <Route path='/client' element={<ClientPageV2 />} /> */}
            <Route path="/404" exact element={<PageNotFound />} />
            <Route path="/forgotpassword" exact element={<ForgotPasswordPage />} />
            <Route path="/resetpassword" exact element={<ResetPasswordPage />} />
            <Route path="*" element={<Navigate to="/" />} replace />
        </Routes>
    )

};

export const useClientRoutes = (isAuthenticated) => {

    if (isAuthenticated) {
        return (
            <Routes>
                <Route path='/home' element={<ClientPageV2 />} />
                <Route path="/useraccount" element={
                    <NavigationPanel>
                        <UserAccountPage />
                    </NavigationPanel>
                } />
                <Route path="/404" exact element={<PageNotFound />} />
                <Route path="*" element={<Navigate to="/home" />} replace />
            </Routes>
        )
    }

    return (
        <Routes>
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/" exact element={<AuthPage />} />
            <Route path="/404" exact element={<PageNotFound />} />
            <Route path="/forgotpassword" exact element={<ForgotPasswordPage />} />
            <Route path="/resetpassword" exact element={<ResetPasswordPage />} />
            <Route path="*" element={<Navigate to="/" />} replace />
        </Routes>
    )

}
