import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../context/AuthContext'
import { useHttp } from '../../hooks/http.hook'



import { Table, Select, Flex } from 'antd';

const { Option } = Select;

// const transformDataByMonth = (data, selectedType) => {
//     const pivotData = Array(12).fill(null).map(() => ({}));
//     const years = new Set();
//     const totalRow = {};

//     data
//         .filter(item => !selectedType || item.type === selectedType)
//         .forEach(({ year, month, qty, free }) => {
//             years.add(year);

//             if (!pivotData[month][year]) {
//                 pivotData[month][year] = { qty: 0, free: 0 };
//             }

//             if (free !== undefined) {
//                 pivotData[month][year].free += free;
//                 pivotData[month][year].qty += qty;
//             } else {
//                 pivotData[month][year].qty += qty;
//             }

//             if (!totalRow[year]) {
//                 totalRow[year] = { qty: qty, free: 0 };
//             }

//             if (free !== undefined) {
//                 totalRow[year].free += free;
//                 totalRow[year].qty += qty;
//             } else {
//                 totalRow[year].qty += qty;
//             }
//         });

//     return { pivotData, years: Array.from(years).sort(), totalRow };
// };

const transformDataByMonth = (data, selectedType) => {
    const pivotData = Array(12).fill(null).map(() => ({}));
    const years = new Set();
    let totalRow = {};

    data
        .filter(item => !selectedType || item.type === selectedType)
        .forEach(({ year, month, qty, free }) => {
            years.add(year);

            // Initialize the month data for the given year
            if (!pivotData[month][year]) {
                pivotData[month][year] = { total: 0, payable: 0, free: 0 };
            }

            // Update payable and total for the month
            pivotData[month][year].payable += qty;
            pivotData[month][year].total += qty;

            // If free is present, update free and total for the month
            if (free) {
                pivotData[month][year].free += free;
                pivotData[month][year].total += free;
            }

            // Initialize totalRow for the given year if not already done
            if (!totalRow[year]) {
                totalRow[year] = { total: 0, payable: 0, free: 0 };
            }

            // Update the totalRow for the year
            totalRow[year].payable += qty;
            totalRow[year].total += qty;

            if (free) {
                totalRow[year].free += free;
                totalRow[year].total += free;
            }
        });

    return { pivotData, years: Array.from(years).sort(), totalRow };
};

// const transformDataByMonth = (data, selectedType) => {
//     const pivotData = Array(12).fill(null).map(() => ({}));
//     const years = new Set();

//     data
//         .filter(item => !selectedType || item.type === selectedType)
//         .forEach(({ year, month, qty }) => {
//             years.add(year);

//             if (!pivotData[month][year]) {
//                 pivotData[month][year] = 0;
//             }

//             pivotData[month][year] += qty;
//         });

//     return { pivotData, years: Array.from(years).sort() };
// };


const transformData = (data) => {
    const pivotData = {};
    const years = new Set();


    data.forEach(({ year, month, type, qty }) => {
        years.add(year);

        if (!pivotData[type]) {
            pivotData[type] = {};
        }

        if (!pivotData[type][year]) {
            pivotData[type][year] = Array(12).fill(0); // Initialize an array for 12 months
        }

        pivotData[type][year][month] = qty;

    });


    return { pivotData, years: Array.from(years).sort() };
};





const calculatePercentageDifference = (current, previous) => {
    if (previous === 0) return current === 0 ? 0 : 100;
    return ((current - previous) / previous) * 100;
};



export const PivotTable = ({ data }) => { //{ data }
    const [filterType, setFilterType] = useState(null);
    let { pivotData, years, totalRow } = transformDataByMonth(data, filterType);

    const currentYear = years[years.length - 1];
    const previousYear = years[years.length - 2];







    const columns = [
        {
            title: 'Месяц',
            dataIndex: 'month',
            key: 'month',
            fixed: 'left',
            width: '100px',
            render: (text) => {
                const monthNames = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"];
                return monthNames[text];
            },
        },
        ...years.map(year => ({
            title: `${year} Итого`,
            dataIndex: `${year}-total`,
            key: `${year}-total`,
            align: 'center',
            render: (text, row) => row[year]?.total !== 0 ? row[year]?.total : '-',
        })),
        {
            title: `% ${currentYear} / ${previousYear}`,
            key: 'percentageChange',
            width: '100px',
            align: 'center',
            render: (_, row) => {
                if (!previousYear || !currentYear) return '-';
                const prevTotal = row[previousYear]?.total || 0;
                const currTotal = row[currentYear]?.total || 0;
                if (prevTotal === 0) return '-';
                const percentageChange = ((currTotal - prevTotal) / prevTotal) * 100;
                return `${percentageChange.toFixed(1)}%`;
            },
        }, {
            title: `${previousYear}`,
            children: [
                {
                    title: `Платно`,
                    dataIndex: `${previousYear}-payable`,
                    key: `${previousYear}-payable`,
                    align: 'center',
                    render: (text, row) => row[previousYear]?.payable !== 0 ? row[previousYear]?.payable : '-',
                },
                {
                    title: `Гарантия`,
                    dataIndex: `${previousYear}-free`,
                    key: `${previousYear}-free`,
                    align: 'center',
                    render: (text, row) => row[previousYear]?.free !== 0 ? row[previousYear]?.free : '-',
                }]
        }, {
            title: `${currentYear}`,
            children: [
                {
                    title: `Платно`,
                    dataIndex: `${currentYear}-payable`,
                    key: `${currentYear}-payable`,
                    align: 'center',
                    render: (text, row) => row[currentYear]?.payable !== 0 ? row[currentYear]?.payable : '-',
                },
                {
                    title: `Гарантия`,
                    dataIndex: `${currentYear}-free`,
                    key: `${currentYear}-free`,
                    align: 'center',
                    render: (text, row) => row[currentYear]?.free !== 0 ? row[currentYear]?.free : '-',
                },
            ]
        }

    ];

    const tableData = pivotData.map((monthData, monthIndex) => {
        const row = { month: monthIndex };
        years.forEach(year => {
            row[year] = monthData[year] || { total: 0, payable: 0, free: 0 };
        });
        return row;
    });

    return (
        <div>
            <Flex gap={'middle'} align='center'>
                <Select
                    placeholder="Выберите тип задания"
                    style={{ width: 200 }}
                    onChange={(value) => setFilterType(value)}
                    allowClear
                >
                    {[...new Set(data.map(item => item.type))].map(type => (
                        <Option key={type} value={type}>
                            {type}
                        </Option>
                    ))}
                </Select>
                <p style={{}}>Данные на {new Date().toLocaleString()}</p>
            </Flex>

            <Table
                columns={columns}
                dataSource={tableData}
                bordered
                size="small"
                style={{ maxWidth: '1100px' }}
                rowKey={(row, i) => row.id || `${i}-row`} // Use a unique identifier if available
                scroll={{ x: true }}
                pagination={false}
                summary={() => (
                    <Table.Summary.Row
                        style={{
                            backgroundColor: '#e4e4e4',
                            fontWeight: 900,
                            textAlign: 'center',
                        }}
                    >
                        <Table.Summary.Cell fixed index={0} key="colhead" className="leftCol">
                            ИТОГО
                        </Table.Summary.Cell>
                        {years.map((year) => (
                            <Table.Summary.Cell
                                key={`${year}-col`}
                                align="center"
                                style={{ fontWeight: 800 }}
                            >
                                {totalRow[year]?.total || 0}
                            </Table.Summary.Cell>
                        ))}
                        <Table.Summary.Cell>
                            {totalRow[years[years.length - 2]]?.total &&
                                totalRow[years[years.length - 1]]?.total
                                ? `${(
                                    ((totalRow[years[years.length - 1]].total -
                                        totalRow[years[years.length - 2]].total) /
                                        totalRow[years[years.length - 2]].total) *
                                    100
                                ).toFixed(2)}%`
                                : '-'}
                        </Table.Summary.Cell>
                        {/* Additional summary cells for "Payable" and "Free" */}
                        {years.map((y, i) => {
                            if (i === years.length - 1 || i === years.length - 2) {
                                return (
                                    <React.Fragment key={y}>
                                        <Table.Summary.Cell key={`${y}-tpay`}>
                                            {totalRow[y]?.payable || 0}
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell key={`${y}-tfree`}>
                                            {totalRow[y]?.free || 0}
                                        </Table.Summary.Cell>
                                    </React.Fragment>
                                );
                            }
                            return null; // Properly handle the non-rendering case
                        })}
                    </Table.Summary.Row>
                )}
            />

        </div>
    );
};


// let prevdata = () => (
//     <Table
//                 columns={columns}
//                 dataSource={tableData}
//                 bordered
//                 size='small'
//                 style={{ 'maxWidth': '1100px', }}
//                 rowKey={(row, i) => `${i}-row`}
//                 scroll={{ x: true }}
//                 pagination={false}
//                 summary={() => (
//                     <Table.Summary.Row style={{
//                         backgroundColor: '#e4e4e4',
//                         fontWeight: 900,
//                         textAlign: 'center',
//                     }}>
//                         <Table.Summary.Cell fixed index={0} key='colhead' className='leftCol'>ИТОГО</Table.Summary.Cell>
//                         {years.map(year => (
//                             <Table.Summary.Cell key={`${year}-col`} align='center' style={{ fontWeigth: 800 }}>
//                                 {totalRow[year]?.total || 0}
//                             </Table.Summary.Cell>
//                         ))}
//                         <Table.Summary.Cell>
//                             {
//                                 totalRow[years[years.length - 2]]?.total && totalRow[years[years.length - 1]]?.total
//                                     ? `${((totalRow[years[years.length - 1]].total - totalRow[years[years.length - 2]].total) / totalRow[years[years.length - 2]].total * 100).toFixed(2)}%`
//                                     : '-'
//                             }
//                         </Table.Summary.Cell>
//                         {/* Additional summary cells for "Payable" and "Free" */}
//                         {
//                             years.map((y, i) => {
//                                 if (i === years.length - 1 || i === years.length - 2) {
//                                     return (
//                                         <>
//                                             <Table.Summary.Cell key={`${totalRow[y]}-tpay`}>{totalRow[y]?.payable || 0}</Table.Summary.Cell>
//                                             <Table.Summary.Cell key={`${totalRow[y]}-tfree`}>{totalRow[y]?.free || 0}</Table.Summary.Cell>
//                                         </>
//                                     )
//                                 }
//                             })
//                         }


//                     </Table.Summary.Row>
//                 )}
//             />
// )



export const PivotTableOld = ({ data }) => {
    const [filterType, setFilterType] = useState(null);
    const { pivotData, years, totalRow } = transformDataByMonth(data, filterType);
    // console.log(pivotData, years, totalRow)
    const columns = [
        {
            title: 'Month',
            dataIndex: 'month',
            key: 'month',
            fixed: 'left',
            render: (text) => {
                if (text === 'Total') return <strong>{text}</strong>;
                const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                return monthNames[text];
            },
        },
        ...years.map((year, index) => {
            const columnsForYear = [
                {
                    title: `${year} (Payable)`,
                    dataIndex: `${year}`,
                    key: `${year}-payable`,
                    align: 'center',
                    render: (text, record) => record[year] ? record[year].payable : 0,
                },
                {
                    title: `${year} (Free)`,
                    dataIndex: `${year}`,
                    key: `${year}-free`,
                    align: 'center',
                    render: (text, record) => record[year] ? record[year].free : 0,
                }
            ];

            // If this is the last year, add a column for the percentage difference
            if (index === years.length - 1 && years.length > 1) {
                const previousYear = years[index - 1];
                columnsForYear.push({
                    title: `Difference % (${year} vs ${previousYear})`,
                    key: `diff-${year}`,
                    align: 'center',
                    render: (text, record) => {
                        const currentYearTotal = record[year] ? record[year].payable + record[year].free : 0;
                        const previousYearTotal = record[previousYear] ? record[previousYear].payable + record[previousYear].free : 0;
                        const diff = calculatePercentageDifference(currentYearTotal, previousYearTotal);
                        return `${diff.toFixed(2)}%`;
                    }
                });
            }

            return columnsForYear;
        }).flat(),
    ];

    const tableData = pivotData.map((monthData, monthIndex) => {
        const row = { month: monthIndex };
        years.forEach(year => {
            row[year] = monthData[year] || { payable: 0, free: 0 };
        });
        return row;
    });

    tableData.push(totalRow);

    return (
        <div>
            <Select
                placeholder="Select Type"
                style={{ marginBottom: 16, width: 200 }}
                onChange={(value) => setFilterType(value)}
                allowClear
            >
                {[...new Set(data.map(item => item.type))].map(type => (
                    <Option key={type} value={type}>
                        {type}
                    </Option>
                ))}
            </Select>
            <Table
                columns={columns}
                dataSource={tableData}
                bordered
                pagination={false}
                rowKey="month"
                size='small'
                scroll={{ x: true }}
            />
        </div>
    );
};











export const PivotReport2 = () => {
    const [data, setData] = useState([])
    const [filterData, setFilterData] = useState([])
    const [taskType, setTaskType] = useState('Монтаж')
    const { token } = useContext(AuthContext)
    const { request } = useHttp()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const reportData = await request(
                    "o/api/report/pivotreport",
                    "POST", null,
                    {
                        Authorization: `Bearer ${token}`,
                    }
                );

                if (reportData?.data) {
                    setData(reportData.data)

                }

            } catch (error) { }

        }
        setData([])
        // fetchData()
    }, [])

    useEffect(() => {

        let filtered = [...data]//data.filter(item => item.type === taskType)
        // console.log(filtered)
        setFilterData(filtered)
    }, [data])

    let columns = [

        {
            title: "#",
            width: "1%",
            key: "",
            render: (_, r, index) => <div key={index}>{index + 1}</div>,
        },
        {
            title: '2022',
            width: 100,
            dataIndex: '2022',
        }
    ]

    return (
        <>
            <h4>Сводный отчет</h4>
            <Table
                dataSource={filterData}
                columns={columns}
                size="small"
                rowKey={"index"}
            />
        </>
    )
}


