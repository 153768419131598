import React, { useContext, useEffect, useState } from 'react'
import { Drawer, Flex, Space, Grid, message } from 'antd'
import ActualList from '../../components/Actual/ActualList'
import { useHttp } from '../../hooks/http.hook'
import { AuthContext } from '../../context/AuthContext'
import { ActualForm } from '../../components/Actual/ActualForm'


const { useBreakpoint } = Grid;
export const ActualPage = () => {

    const [actual, setActual] = useState([])
    const [visible, setVisible] = useState(false)
    const [selected, setSelected] = useState({})

    const isMobile = useBreakpoint().xs
    const { token } = useContext(AuthContext)
    const { request, loading } = useHttp()


    const fetchActual = async () => {
        try {
            const response = await request('o/api/actualitems', 'GET', null, {
                Authorization: `Bearer ${token}`
            });
            setActual(response)
        } catch (error) {
            console.error('Error fetching tasks:', error);
        }
    };

    useEffect(() => {
        fetchActual()
    }, [])

    const openDrawer = (data) => {
        setSelected(data)
        setVisible(true);
    }

    const onSubmitHours = async (data) => {
        try {
            let response = await request('o/api/actualitems', 'POST', data,
                { Authorization: `Bearer ${token}` })

            if (response) {
                message.success('Информация записана!')
                // console.log(response)
                let hoursRecord = response?.mach?.operationHours[response?.mach?.operationHours?.length - 1] ?? null
                let updatedActual = actual.map(el => el._id === response?.psp?._id ? {
                    ...el,
                    machine: { ...el.machine, lastOperationHours: hoursRecord },
                    lastHours: response?.mach?.lastHours,
                    operationHours: response?.mach?.operationHours,
                    nextMaintenanceDate: response?.psp?.nextMaintenanceDate,
                } : el)
                // console.log(updatedActual)
                setActual(updatedActual)
            }
            setVisible(false);
        } catch (error) {
            message.error('Произошла ошибка! Попробуйте обновить позднее');
        }
    }

    return (
        <>
            <div >
                <Flex wrap="wrap" gap="small" align='center' justify='space-between'

                    style={{ marginBottom: '16px' }}>
                    <Space align='baseline' size={'middle'} >
                        <h2 style={{ marginBottom: 0, marginTop: 0 }}>Данные для актуализации наработки</h2>

                    </Space>
                </Flex>
                <ActualList actualData={actual} loading={loading} onEdit={openDrawer} />
                <Drawer
                    title={'Актуализация данных'}
                    width={isMobile ? 430 : 800}
                    open={visible}
                    maskClosable={false} //{isMobile ? false : true}
                    onClose={() => setVisible(false)}
                    destroyOnClose={true}>
                    <ActualForm isMobile={isMobile} actualInfo={selected} onSubmit={onSubmitHours} />
                </Drawer>
            </div>
        </>
    )

}