import React, { useEffect, useState, useContext } from 'react'
import { Form, Input, Button, Typography, Space, Divider, Row, Col, DatePicker, Select, Upload, List, Popconfirm } from 'antd'
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import { DataContext } from '../../context/DataContext';
const { Text } = Typography;
const { Option } = Select;

export const SubContract = ({ onFinish, initialValues, fileList, setFileList }) => {
    const [attachedFiles, setAttachedFiles] = useState([])
    const [contractTypes, setContractTypes] = useState([])

    const { settings } = useContext(DataContext)

    const [subform] = useForm()

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };
    useEffect(() => {
        setFileList([])
    }, [])

    useEffect(() => {
        subform.resetFields()
        setFileList([])
        setAttachedFiles(
            initialValues?.signedPdf?.length > 0
                ? initialValues.signedPdf.map(el => (
                    { _id: el._id, filename: el.filename, filePath: el.filePath }
                ))
                : []
        )
        setContractTypes(settings.contractTypes || [])

    }, [initialValues, subform])

    const handleSubmit = async () => {
        const values = await subform.validateFields();
        for (var key of Object.keys(values)) {
            if (!values[key]) { delete values[key] }
        }
        values.parent = initialValues.parentContract._id
        values.customer = initialValues.parentContract.customer._id

        onFinish({ ...values, files: fileList, updSignedPdf: attachedFiles });

        // subform.validateFields().then(values => {
        //     for (var key of Object.keys(values)) {
        //         if (!values[key]) { delete values[key] }
        //     }
        //     values.parent = initialValues.parentContract._id
        //     values.customer = initialValues.parentContract.customer._id
        //     onFinish({ ...values, files: fileList, updSignedPdf: attachedFiles });
        //     subform.resetFields();
        //     setFileList([]);
        // })
        //     .catch(errorInfo => {
        //         console.log('Form validation failed:', errorInfo);
        //     });
        // // console.log('submit', values)
    }

    const props = {
        onRemove: (file) => {
            setFileList(fileList.filter(f => f !== file));
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);
            return false;
        },
        fileList: fileList,
        progress: {
            strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
            },
            strokeWidth: 3,
            format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
        },
        listType: "picture-list",
        // defaultFileList: {[...fileList]}
    };


    const handleFilePreview = (file) => {
        // console.log('Хотите посмотреть?')
    }

    const handleDeleteFile = (id) => {
        let newAttached = attachedFiles.filter(rec => rec._id !== id)
        setAttachedFiles(newAttached)
    }

    return (

        <Form
            form={subform}
            initialValues={initialValues}
            name='subContract'
            layout="vertical"
        >
            <Text name='parent' value={initialValues?.parentContract?._id}>Основной договор: <span style={{ fontWeight: 600 }}>{initialValues?.parentContract?.title} от {(new Date(initialValues?.parentContract?.date)).toLocaleDateString('ru-RU')}</span></Text>
            <br />
            <Text>Контрагент: <span style={{ fontWeight: 600 }}>{initialValues?.parentContract?.customer?.name}</span></Text>
            <br />
            <Space direction='horizontal'>
                <Text>ИНН: <span style={{ fontWeight: 600 }}>{initialValues?.parentContract?.customer?.inn}</span></Text>
                {/* <Text>ОГРН: <span style={{ fontWeight: 600 }}>-</span></Text> */}
            </Space>
            <Divider />
            <Row gutter={[24, 2]} justify="space-between">
                <Col xs={16} sm={18}>
                    <Form.Item
                        name={'title'}
                        label={'Наименование соглашения'}
                        rules={[{ required: true, message: 'Укажите наименование и/или номер соглашения' }]}
                    >
                        <Input placeholder={'Номер или наименование'} />
                    </Form.Item>
                </Col>
                <Col xs={8} sm={6}>
                    <Form.Item
                        label={'Дата'}
                        name={'date'}
                        rules={[{ required: true, message: 'Укажите дату соглашения' }]}
                    >
                        <DatePicker format={'DD.MM.YYYY'} />
                    </Form.Item>

                </Col>
            </Row>
            <Row gutter={[24, 2]} justify="space-between">
                <Col xs={12} sm={6}>
                    <Form.Item
                        label="Срок действия с:"
                        name={"startDate"}
                        rules={[{ required: true, message: 'Укажите дату начала действия соглашения' }]}
                    >
                        <DatePicker format={'DD.MM.YYYY'} />
                    </Form.Item>
                </Col>
                <Col xs={12} sm={6}>
                    <Form.Item
                        label="по:"
                        name={"endDate"}
                    >
                        <DatePicker format={'DD.MM.YYYY'} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                    <Form.Item
                        label="Тип договора"
                        name={"type"}
                        rules={[{ required: true, message: 'Please select the contract type' }]}
                    >
                        <Select placeholder="Выберите тип договора">
                            {contractTypes.map(el => (
                                <Option key={el._id} value={el?.value}>{el?.label}</Option>
                            ))}
                            {/* <Option value="service">Сервисное обслуживание</Option>
                            <Option value="installation">Монтаж оборудования</Option>
                            <Option value="serviceBase">Рамочный сервисный договор</Option> */}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Form.Item
                label='Комментарий'
                name='comment'
            >
                <Input.TextArea
                    rows={5}
                    placeholder='Можно добавить любой комментарий к карточке договора, который будет виден всем'
                />
            </Form.Item>
            <Divider />
            <Form.Item
                label="Загрузить файл"
                // name={'load'}
                valuePropName="fileList"
                getValueFromEvent={normFile}
            >
                <>
                    <Upload  {...props}
                        multiple
                        showUploadList={true}
                    >
                        <Button icon={<UploadOutlined />}>Загрузить</Button>
                    </Upload>
                </>
            </Form.Item>

            <Form.Item            >
                {attachedFiles.length > 0 && <List
                    // header={<div >Прикрепленные файлы</div>}
                    bordered
                    size='small'

                    dataSource={attachedFiles}
                    style={{ marginBottom: '2rem' }}
                    renderItem={(file, n) => (
                        <List.Item
                            key={file._id}
                            actions={
                                [
                                    <div key="view" onClick={() => handleFilePreview(file)}>Открыть</div>,
                                    <Popconfirm
                                        title="Удаление файла"
                                        description="Вы уверены, что хотите удалить этот файл?"
                                        okText="Да"
                                        cancelText="Нет"
                                        onConfirm={() => { handleDeleteFile(file._id) }}
                                    >
                                        <DeleteOutlined style={{ color: 'red' }} />
                                    </Popconfirm>
                                ]}
                        >
                            {n + 1}. {file.filename}
                        </List.Item>
                    )}
                />}
            </Form.Item>

            <Form.Item>
                <Button type="primary" onClick={handleSubmit}>Записать</Button>
            </Form.Item>
        </ Form>
    )
} 