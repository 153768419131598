import React, { useState, useEffect, useContext } from 'react';
import { Button, Input, Modal, Spin, Typography, message } from 'antd';
import { EditOutlined, SaveOutlined } from '@ant-design/icons';
import { useHttp } from '../../hooks/http.hook'
import { AuthContext } from '../../context/AuthContext'
import { useHelp } from '../../context/HelpContext';

const { Title, Paragraph } = Typography;

const Help = ({ visible, onClose }) => {
    const [content, setContent] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [inputContent, setInputContent] = useState('');
    const { pageId } = useHelp()


    const { token, role, checkRole } = useContext(AuthContext); // Assuming you have a role-based system
    const { request, loading } = useHttp();
    useEffect(() => {
        const fetchHelpContent = async () => {
            try {
                if (pageId) {
                    const data = await request(`o/api/help${pageId}`, 'GET', null, {
                        Authorization: `Bearer ${token}`,
                    });
                    setContent(data.content);
                    setInputContent(data.content);
                }

            } catch (error) {
                setContent('<p>Нет данных или ошибка загрузки справочной информации...</p>')
                setInputContent('<p>Нет данных или ошибка загрузки справочной информации...</p>')
                // console.error(`Failed to load help content ${pageId}`);
            }
        };

        fetchHelpContent();

        // Check if the user is an admin
        setIsAuthorized(checkRole(['super']));
    }, [pageId, request, token, role]);

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleSave = async () => {
        try {
            const updatedContent = inputContent;
            await request(`o/api/help${pageId}`, 'PUT', { content: updatedContent }, {
                Authorization: `Bearer ${token}`,
            });
            setContent(updatedContent);
            setIsEditing(false);
            message.success('Help content updated successfully');
        } catch (error) {
            message.error('Failed to update help content');
        }
    };

    const handleInputChange = (e) => {
        setInputContent(e.target.value);
    };


    return (
        <Modal
            title="Помощь"
            open={visible}
            onCancel={onClose}
            footer={[
                <Button key="close" onClick={onClose}>
                    Закрыть
                </Button>,
            ]}
            width={'90vw'}
        >
            <div style={{ padding: '0px', maxWidth: '1440px', margin: '0 auto' }}>
                {isEditing ? (
                    <>
                        <Input.TextArea
                            rows={10}
                            value={inputContent}
                            onChange={handleInputChange}
                        />
                        <Button
                            type="primary"
                            icon={<SaveOutlined />}
                            onClick={handleSave}
                            style={{ marginTop: '10px' }}
                        >
                            Сохранить
                        </Button>
                    </>
                ) : loading ? (
                    <Spin loading={!content} />
                ) : (
                    <>
                        <Paragraph>
                            <span dangerouslySetInnerHTML={{ __html: content }} />
                        </Paragraph>
                        {isAuthorized && (
                            <Button
                                type="default"
                                icon={<EditOutlined />}
                                onClick={handleEdit}
                                style={{ marginTop: '10px' }}
                            >
                                Редактировать
                            </Button>
                        )}
                    </>
                )}
            </div>
        </Modal>
    );
};

export default Help;
