import { Form, Input, InputNumber } from 'antd';
// import { useForm } from 'antd/es/form/Form'
import React, { useEffect } from 'react'
import InputMask from 'react-input-mask'
import MaskedInput from 'antd-mask-input'
export const ClientFormStudy = (props) => {
    // const [form] = useForm()
    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const emptyFields = {
        person: '',
        phone: '',
        description: '',

    }

    const onFinish = (values) => {
        props.onFinish({ ...values, type: 'study' })
        props.form.setFieldsValue(emptyFields)
    }


    useEffect(() => {
        props.form.resetFields()
    }, [props])

    return (
        <div style={{
            marginTop: '0px',
        }}>

            <Form
                {...layout}
                form={props.form}
                name="control-study"
                onFinish={onFinish}
                style={{ maxWidth: 600 }}
            >


                <Form.Item name="person" label="Контактное лицо" rules={[{
                    required: true,
                    message: 'Укажите контактное лицо'
                }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="phone" label="Телефон" rules={[{
                    required: true,
                    message: 'Введите номер телефона'
                }]}>
                    {/* <InputMask

                        autoComplete="off"
                        value={props.phone}
                        mask="+7 (999) 999-99-99"
                    >
                        {() => <Input />}
                         
                    </InputMask> */}
                    <MaskedInput
                        name="formPhone"
                        value={props?.phone ?? ''}
                        mask={'+0 (000) 000-00-00'}
                    />
                </Form.Item>
                <Form.Item name="description" label="Информация"
                    rules={[{ required: false }]}>
                    <Input.TextArea rows={5}
                        placeholder='Здесь можно внести дополнительную информацию' />
                </Form.Item>
            </Form>
        </div >
    )

}