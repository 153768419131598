import React from 'react';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';

const PageNotFound = () => {
    return (
        <Result
            status="404"
            title="404"
            subTitle="Извиние, страница, которую вы ищете, не существует."
            extra={
                <Link to="/">
                    <Button type="primary">Домой</Button>
                </Link>
            }
        />
    );
};

export default PageNotFound;
