import { Drawer, Button } from 'antd'
import React from 'react'
import { ClientFormMntc } from './ClientFormMntc'
import { ClientFormTest } from './ClientFormTest'
import { ClientFormStudy } from './ClientFormStudy'
import { useForm } from 'antd/es/form/Form'


export const ClientDrawerSpecial = props => {


    const [formMntc] = useForm()
    const [formTest] = useForm()
    const [formStudy] = useForm()


    const titles = {
        'test': 'Заявка на проведение испытания',
        'maintenance': 'Заявка на техническое обслуживание',
        'study': "Заявка на обучение персонала"
    }



    const submitForm = item => {
        props.value === 'maintenance'
            ? formMntc.submit()
            : props.value === 'study'
                ? formStudy.submit() : formTest.submit()

    }


    return <Drawer
        open={props.open} onClose={props.onClose}
        title={titles[props.value]}
        onCancel={props.onOk}
        width={560}
        destroyOnClose={true}
        footer={
            < Button type="primary" className='noRadius' onClick={submitForm}//{props.onOk}
                style={{ height: 45 }}>
                Отправить заявку
            </Button >
        }
    >
        {props.value === 'maintenance'
            ? <><ClientFormMntc {...props} form={formMntc} /></>
            : (props.value === 'test'
                ? <ClientFormTest {...props} form={formTest} />
                : <ClientFormStudy {...props} form={formStudy} />)
        }

    </Drawer>
}