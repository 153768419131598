import React, { useState, useEffect, useContext } from 'react'
import { Form, Input, Select, Table, Typography, Button, Space, Modal, Divider, Row, Col, Popconfirm, AutoComplete } from 'antd'
import { CheckSquareOutlined, CloseSquareOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'

import { PersonForm } from './PersonForm'
import { DataContext } from '../../context/DataContext';

const { Text } = Typography;

export const CustomerForm = ({ onFinishForm, onClose, edValues, setEdValues, onCreateUser }) => {

    const [editPerson, setEditPerson] = useState({})
    const [persons, setPersons] = useState([])
    const [modalPersonVisible, setModalPersonVisible] = useState(false)
    const [form] = Form.useForm()
    const { customers } = useContext(DataContext)


    useEffect(() => {
        setPersons(edValues?.persons ?? [])
        form.resetFields()
    }, [])

    const handleAddPerson = (values) => {
        // const newPerson = { ...values, _id: Number(new Date()) }
        const newPerson = { ...values }
        // add to state persons
        setPersons([...persons, newPerson])
        // add to state edValues.persons
        setEdValues({ ...edValues, persons: [...persons, newPerson] })
    }

    const handleEditPerson = (values) => {
        const updatedPersons = persons.map(p => p._id === values._id ? values : p)
        setPersons(updatedPersons)
        setEdValues({ ...edValues, persons: updatedPersons })
    }

    const onSubmitPersonForm = (values) => {
        if (editPerson._id) {
            handleEditPerson(values)
        } else {
            handleAddPerson(values)
        }
        handleCloseModalPerson()

    }

    const onSave = async () => {
        try {
            const values = await form.validateFields()
            onFinishForm(values)
        } catch (error) { }
    }

    const onFinish = async (values) => {
        onFinishForm({ ...values, persons })
        // onClose()
    }

    const handleRemovePerson = (index) => {
        const newPersons = persons?.filter((item, i) => i !== index)
        setPersons(newPersons)
        setEdValues({ ...edValues, persons: newPersons })
    }

    const openModalPerson = (item) => {
        setEditPerson(item)
        setModalPersonVisible(true)

    }

    const addNewPerson = () => {
        setEditPerson({ status: "Работает" })
        setModalPersonVisible(true)
    }


    const handleCloseModalPerson = () => {
        setEditPerson({})
        setModalPersonVisible(false)

    }

    const columns = [
        {
            title: 'ФИО, должность',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <>
                    <div>{text}</div>
                    {record?.jobTitle && <div style={{ color: 'grey', fontStyle: 'italic' }}>{record?.jobTitle}</div>}
                </>
            )
        },
        {
            title: 'Телефон',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: ' ',
            key: 'action',
            render: (text, record, index) => (
                <Space size={'middle'}>
                    {record.status === 'Работает' ? <CheckSquareOutlined style={{ color: 'green' }} /> : <CloseSquareOutlined style={{ color: 'red' }} />}
                    <EditOutlined
                        onClick={(e) => {
                            openModalPerson(record)
                        }}
                    />
                    <Popconfirm
                        title="Удаление записи"
                        description="Вы уверены, что хотите удалить эту запись?"
                        okText="Да"
                        cancelText="Нет"
                        onConfirm={() => { handleRemovePerson(index) }}
                    >
                        <DeleteOutlined style={{ color: 'red' }} />

                    </Popconfirm>

                </Space>

            ),
        },
    ];
    return (
        <>
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                initialValues={edValues}
            >
                <Row gutter={[12, 24]}>
                    <Col span={18}>
                        <Form.Item name="name" label="Наименование" rules={[{ required: true, message: 'Введите наименование' }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="form" label="Форма" rules={[{ required: true, message: 'Выберите форму' }]}>
                            <Select
                                options={['ООО', 'АО', 'ЗАО', 'ПАО', 'ИП', 'ТОО'].map(opt => ({
                                    value: opt,
                                    label: opt
                                }))}
                            />
                        </Form.Item>
                    </Col>

                </Row>

                <Form.Item name="inn" label="ИНН" rules={[{ required: true, message: 'Введите ИНН' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="address" label="Адрес" rules={[{ required: true, message: 'Введите адрес' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Введите адрес электронной почты' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="phone" label="Телефон" rules={[{ required: false, message: 'Введите номер телефона' }]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Сектор экономики"
                    name="category"
                    rules={[{ required: true, message: 'Введите сектор экономики' }]}
                >
                    <AutoComplete
                        options={[...new Set(customers.map(el => el?.category))].filter(el => el).map(el => ({ value: el })).sort((a, b) => { return a.value.localeCompare(b.value) })}
                        allowClear
                        filterOption={(inputValue, option) =>
                            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }
                    />
                </Form.Item>


                <Divider />
                <Form.Item >
                    <Space size={'middle'}>
                        <Text strong>Контактные лица</Text>
                        {edValues?._id && <Button style={{ marginBottom: 0 }} onClick={addNewPerson}>+ Добавить</Button>}
                    </Space>

                    {/* <Modal
                        title="Добавить контактное лицо"
                        open={modalVisible}
                        onCancel={() => setModalVisible(false)}
                        footer={null}
                    // afterClose={handleCloseModal}
                    >

                        <PersonForm onCreate={handleAddPerson} onCancel={() => setModalVisible(false)} item={editPerson} />
                    </Modal> */}
                </Form.Item>
                {persons && persons.length > 0 && <Table
                    dataSource={persons}
                    columns={columns}
                    pagination={false}
                    size={'small'}
                    style={{ marginBottom: 12 }}
                    rowKey={(rec, i) => i}
                />}
                {edValues?._id
                    ? <></>
                    : <Typography style={{ color: 'red' }}>Внимание, контактные лица можно добавить только после записи.</Typography>}
                <Divider />

                <Form.Item style={{ marginTop: '2rem' }}>
                    <Space size={'small'}>
                        {!edValues?._id && <Button
                            style={{ color: 'blue' }}
                            onClick={onSave}
                        >+ Записать</Button>}
                        <Button type="primary" htmlType="submit">
                            {edValues?._id
                                ? <>Обновить</>
                                : <>Записать и закрыть</>}</Button>

                        {/* <Button onClick={onClose} style={{ marginLeft: 8 }}>Отмена</Button> */}
                    </Space>
                </Form.Item>
            </Form>
            <Modal
                title="Добавить контактное лицо"
                open={modalPersonVisible}
                onCancel={handleCloseModalPerson}
                footer={null}
                destroyOnClose={true}
            >
                <PersonForm onCreate={onSubmitPersonForm} onCancel={handleCloseModalPerson} item={editPerson} onCreateUser={onCreateUser} />
            </Modal>
        </>
    )
}