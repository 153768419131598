import React, { useContext, useEffect, useState } from "react";
import { Col, Statistic, Row, Card, Tooltip } from "antd";
import ReportPage from "./ReportPage";
import { LikeOutlined } from "@ant-design/icons/lib/icons";
import { useHttp } from "../hooks/http.hook";
import { AuthContext } from "../context/AuthContext";
// import { Dashboardv2 } from "./Reports/dashPage";


export const DashboardPage = () => {
  const [warranty, setWarranty] = useState(0);
  const [contractInfo, setContractInfo] = useState(0);
  const [tasksInfo, setTasksInfo] = useState({})
  const { request } = useHttp();
  const { token } = useContext(AuthContext);

  useEffect(() => {
    const fetchReports = async () => {
      try {
        let response = await request('o/api/reports', 'GET', null, { Authorization: `Bearer ${token}` });
        setWarranty(response.warranty);
        setContractInfo(response.ctr);
        setTasksInfo(response.tasks)
      } catch (error) {
        console.log(error);
      }
    }
    fetchReports();
  }, []);

  return (
    <>
      <div style={{ padding: "8px" }}>
        <Row gutter={[16, 16]}>
          <Col span={8}
            xs={24}
            sm={8}
          >
            <Card style={{ overflow: 'auto', minWidth: "50px" }}>
              <Row justify={'space-between'}>
                <Tooltip placement="topLeft" color="geekblue" title="Задания выполнены / назначены">
                  <Statistic
                    title="Текущий месяц"
                    prefix={<LikeOutlined />} value={tasksInfo?.curr?.done ?? 0} suffix={"/ " + (tasksInfo?.curr?.from ?? 0)} />
                </Tooltip>
                <Tooltip placement="topLeft" color="geekblue" title="Задания выполнены / назначены">
                  <Statistic title="Прошлый месяц" prefix={<LikeOutlined />} value={tasksInfo?.prev?.done ?? 0} suffix={"/ " + (tasksInfo?.prev?.from ?? 0)} />
                </Tooltip>
              </Row>
            </Card>
          </Col>
          <Col span={8} xs={24}
            sm={8}>
            <Card style={{ overflow: "hidden", minWidth: "50px" }}>
              <Statistic title="Оборудование на гарантии" value={warranty ?? 0} />
            </Card>
          </Col>
          <Col span={8} xs={24}
            sm={8}>
            <Card style={{ overflow: "hidden", minWidth: "50px" }}>
              <Statistic title="Договоры" value={contractInfo ?? 0} />
            </Card>
          </Col>
        </Row >
      </div >
      <ReportPage />
      {/* <Dashboardv2 /> */}
    </>
  );
};
