import { Button, Space, Drawer, Table, Grid, Descriptions, List, Flex, Modal, Tag, message, Spin } from 'antd'
import React, { useRef } from 'react'
import { useEffect, useState, useContext } from 'react'
import { useNavigate, Link, useSearchParams } from 'react-router-dom'
import { DoubleLeftOutlined } from '@ant-design/icons'
import { SearchOutlined, PlusOutlined, SmallDashOutlined } from '@ant-design/icons';
import { TaskForm } from '../components/Task/TaskForm'
import { useHttp } from '../hooks/http.hook';
import dayjs from 'dayjs'
import { AuthContext } from '../context/AuthContext';
import { TaskDescription } from '../components/Task/taskDescription'
import { DataContext } from '../context/DataContext'
import { ContractDetails } from '../components/Contract/ContractDetails'
import Logo from './Logo'

const { useBreakpoint } = Grid;

const PassportItem = ({ selectedPassport }) => {
    const [item, setItem] = useState(null)
    const [tasks, setTasks] = useState([])
    const [workers, setWorkers] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
    const [isContractModalOpen, setIsContractModalOpen] = useState(false);
    const [description, setDescription] = useState({})
    const [formInitial, setFormInitial] = useState({})
    const [contract, setContract] = useState({})
    const { request, loading } = useHttp();
    const { token, checkRole } = useContext(AuthContext)
    const { machines } = useContext(DataContext)
    // const { id } = useParams();
    const [params] = useSearchParams()
    const navigate = useNavigate()
    const isMobile = useBreakpoint().xs;
    const contentToPrint = useRef(null);

    useEffect(() => {
        const fetchPassports = async () => {
            try {

                const fetchedPassports = await request(`passport/${params.get('id')}`, 'GET', null, {
                    Authorization: `Bearer ${token}`
                });
                setItem(fetchedPassports.passport)
                setTasks(fetchedPassports.tasks.sort((a, b) => new Date(a.finishDate).getTime() - new Date(b.finishDate).getTime()))
                setWorkers(fetchedPassports.workers)
                // console.log(fetchedPassports)
            } catch (e) {
                console.log(e)
            }

        }

        fetchPassports()

    }, [])


    let mobileComponent = (
        <div>

            <List
                style={{
                    marginTop: '6px',
                    backgroundColor: '#f9f9f9',
                    overflow: 'auto',

                }}
                itemLayout="horizontal"
                dataSource={item?.equipment}
                renderItem={(record, index) => (
                    <List.Item style={{ marginLeft: 6, marginRight: 6 }}>
                        <List.Item.Meta
                            avatar={index + 1}
                            title={<div style={{ fontSize: '12px' }}>
                                {record.description}
                            </div>}
                            description={<Flex justify='space-between'>
                                <div style={{ fontSize: '12px' }}>Кол-во: {record.qty} ед.</div>
                                <div style={{ fontSize: '12px' }}>{record.comment ? `Серийный №: ${record.comment}` : ``}</div>
                            </Flex>
                            }
                        />
                    </List.Item>)}
            />
        </div>
    )

    // eslint-disable-next-line no-sparse-arrays
    const columns = [
        {
            title: 'Дата',
            dataIndex: 'finishDate',
            key: 'finishDate',
            render: (text, record) => {
                return (
                    <div>
                        {(new Date(record.finishDate)).toLocaleDateString()}
                    </div>
                )
            }
        },
        {
            title: 'Моточасы',
            dataIndex: 'lastOperationHours',
            key: 'lastOperationHours',
        },
        {
            title: 'Вид работ',
            dataIndex: 'taskTitle',
            key: 'taskTitle',
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Место выполнения работ',
            dataIndex: 'jobSite',
            key: 'jobSite',
        },
        {
            title: 'Отвественный',
            className: 'noprint',
            dataIndex: 'worker',
            key: 'worker',
            render: (text, record) => record?.worker[0] ? (`${record?.worker[0]?.lastname} ${record?.worker[0]?.firstname} ${record?.worker[0]?.surname ?? ''}`) : ''
            // render: (text, record) => record?.worker.map(el => (`${el.lastname} ${el.firstname} ${el.surname}`)).join(',\n'),
        },
        , {
            title: '...',
            className: 'noprint',
            key: 'action',
            render: (text, record) => (
                <Link className='noprint'>
                    <SearchOutlined
                        onClick={() => openTaskModal(record)}
                    />
                </Link>
            )

        }
    ];

    const columnsEquipment = [
        {
            title: '#',
            dataIndex: 'key',
            key: 'key',
            width: '4%',
            render: (_, r, index) => (<div>{index + 1}</div>)
        },
        {
            title: 'Наименование',
            dataIndex: 'description',
            key: 'description',
            // width: '50%',
        },
        {
            title: 'Кол-во',
            dataIndex: 'qty',
            key: 'qty',
            width: '80px',
        },
        {
            title: 'Серийный №',
            dataIndex: 'comment',
            key: 'comment',
            width: '23%',
        }
    ]

    const openTaskModal = (record) => {
        setDescription(record)
        setIsModalOpen(true)
    }

    const handleAddTask = async (values) => {
        try {
            let formDataToSend = createFormData(values)
            const response = await
                fetch(`o/api/task`, {
                    method: 'POST',
                    body: formDataToSend,
                    headers: { Authorization: `Bearer ${token}` }
                });

            if (!response.ok) {
                throw new Error('Failed to update task');
            }
            const jsonResponse = await response.json();
            setTasks([...tasks, jsonResponse])
            message.success('Задание добавлено успешно!')
        } catch (error) {
            console.error('Error adding task:', error);
        }
    };

    const createFormData = (values) => {
        const { worker, equipment, taskTransport, ...otherFormData } = values
        const formDataToSend = new FormData();
        Object.entries(otherFormData).forEach(([key, value]) => {
            formDataToSend.append(key, value);
        });
        equipment && equipment.forEach((el) => {
            formDataToSend.append('equipment', JSON.stringify(el))
        })

        taskTransport && formDataToSend.append('taskTransport', JSON.stringify(taskTransport))
        worker && worker.forEach((el) => {
            formDataToSend.append('worker', el)
        })
        return formDataToSend
    }

    const openContractModal = (record) => {
        setContract(record)
        setIsContractModalOpen(true)
    }

    const submitForm = async (values) => {
        try {
            await handleAddTask(values)
            // const response = await request(`o/api/task`, 'POST', values,
            //     { Authorization: `Bearer ${token}` })
            // setTasks([...tasks, response])
            setIsTaskModalOpen(false)
        } catch (error) {
            console.log(error)
        }
    }

    const onCloseTaskDrawer = () => {
        setIsTaskModalOpen(false)
    }

    const openNewTaskForm = () => {
        setFormInitial({
            taskTitle: 'Техническое обслуживание',
            startDateFinishDate: [dayjs(new Date()), dayjs(new Date().getTime() + 86400000)],
            priority: 'Средний',
            customer: item?.installation?.customer._id,
            paymentForm: 'Платно',
            worker: [],
            machine: item.installation.machine._id,
            // lastOperationHours: item.installation.lastOperationHours,
            garageNumber: item?.installation.machine?.garageNumber ?? '',
            serialNumber: item?.installation.machine?.serialNumber ?? '',
            branch: '',
            taskDescription: '',
            jobSite: item?.installation.machine?.location ?? '',
            status: 'Запланировано',
            fromPassport: true,
            passport: item._id
        })
        setIsTaskModalOpen(true)
    }

    let machineData = item?.installation.machine.serialNumber
        ? `#${item?.installation.machine.serialNumber} ` : ''
    machineData = machineData.concat(item?.installation.machine.garageNumber ? `/ ${item?.installation.machine.garageNumber}` : '')


    const contractRecord = (item) => {
        if (item?.installation?.contract?.parent) {
            return (
                <Flex vertical align='flex-start'>
                    <div type="link" onClick={() => openContractModal(item?.installation?.contract?.parent)}
                        style={{ padding: '0 0', border: 0, height: 'auto' }}
                        className='typeLink'
                    >
                        Дог. {item?.installation?.contract?.parent?.title} от {new Date(item?.installation?.contract?.parent?.date).toLocaleDateString('ru-RU')}
                    </div>
                    <div type="link" onClick={() => openContractModal(item?.installation?.contract)}
                        style={{ padding: '0 0', border: 0, fontWeight: '600', wordBreak: 'break-all' }}
                    >
                        <div className='typeLink'> <SmallDashOutlined />&nbsp;Доп.согл. {item?.installation?.contract?.title} от {new Date(item?.installation?.contract?.date).toLocaleDateString('ru-RU')}</div>

                    </div>
                </Flex>
            )
        } else {
            return (<div type="link" onClick={() => openContractModal(item?.installation?.contract)}
                style={{ padding: '0 0', border: 0, height: 'auto', fontWeight: '600' }}
                className='typeLink'
            >
                {item?.installation?.contract?.title &&
                    `Дог. ${item?.installation?.contract?.title} от ${new Date(item?.installation?.contract?.date).toLocaleDateString()}`
                }
            </div>)
        }
    }

    const handlePrint = (type) => {
        let contentToPrint = document.getElementById('passportItem')

        // Create a container div for the content to print
        let printContainer = document.createElement('div');
        printContainer.innerHTML = contentToPrint.innerHTML;
        printContainer.id = 'print-container';

        // Append the container to the body
        document.body.appendChild(printContainer);

        // Create a style element to hide the rest of the page
        let style = document.createElement('style');
        style.innerHTML = `
            @media print {
                body * {
                    visibility: hidden;
                    font-size: 10px;
                    
                }
                #print-container, #print-container * {
                    visibility: visible;
                }

                .titleLine {
                    font-size: 14px;
                }
                .noprint { 
                    display: none!important;
                } 
                #print-container {
                    position: absolute;
                    display: block;
                    top: 0;
                    left: 0;
                    width: 100%;
                    font-family: 'calibri';
                }
                @page {
                    margin-top: 1cm;
                    margin-bottom: 1cm;
                }
                footer {
                    display: none;
                    position: fixed;
                    bottom: 0;
                }
                header {
                    display: block;
                    position: fixed;
                    top: 0;
                }
            }
        `;

        // Append the style to the head
        document.head.appendChild(style);

        // Trigger the print dialog
        window.print();

        // Clean up: remove the container and the style element after printing
        document.body.removeChild(printContainer);
        document.head.removeChild(style);
    };

    const items = [
        {
            key: '1', label: 'Техника', span: 3,
            children: `${item?.installation.machine.brand} ${item?.installation.machine.model} ${machineData}`,
        },
        { key: '10', label: 'Клиент', span: 3, children: `${item?.installation.customer.form} ${item?.installation.customer.name} ИНН: ${item?.installation.customer.inn}`, },

        {
            key: '4', label: 'Договор', children: item?.installation?.contract?.title ? contractRecord(item) : 'Нет договора'

        },
        { key: '2', label: 'Дата монтажа', children: `${new Date(item?.installation.finishDate).toLocaleDateString()}`, },
        { key: '3', label: 'Дата запуска', children: `${new Date(item?.deliveryDate).toLocaleDateString()}`, },
        {
            key: '5', label: 'Акт монтажа', children: (
                <Flex justify="space-between">
                    <span style={{ alignContent: 'center' }}>№ {item?.installation?.documentNumber} от {new Date(item?.installation.finishDate).toLocaleDateString()}</span>
                    <Button type="default" className='noprint' icon={<SearchOutlined />} onClick={() => openTaskModal(item?.installation)} />
                </Flex>),
        },
        { key: '6', label: 'Место монтажа ', span: 2, children: `${item?.installation?.jobSite}`, },
        {
            key: '7', label: 'Исполнители', span: 3, className: 'noprint', children:
                item?.installation?.worker.map((item, index) => (
                    `${item?.lastname} ${item?.firstname} ${item?.surname ?? ''}`.trim().concat(item?.status !== "Уволен" ? '' : `(${item.status})`)
                )).join(', '),

        },
        { key: '8', label: 'Дата следующего ТО', children: `${(new Date(item?.nextMaintenanceDate)).toLocaleDateString()}`.concat(item?.maintenanceRule ? `, ТО каждые ${item?.maintenanceRule} м.ч.` : ''), },
        {
            key: '9', label: 'Статус', span: 2, children: (
                <Flex justify='space-between'>
                    <div>{new Date() < new Date(item?.warrantyFinishDate)
                        ? `На гарантии по ${new Date(item?.warrantyFinishDate).toLocaleDateString('ru-RU')}`
                        : `Гарантийный период истек ${new Date(item?.warrantyFinishDate).toLocaleDateString('ru-RU')}`}
                    </div>
                    {new Date() < new Date(item?.warrantyFinishDate) && <Tag className='noprint' color="red">ГАРАНТИЯ</Tag>}
                </Flex>
            ),
        },
    ]

    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleContractOk = () => {
        setIsContractModalOpen(false);
    };

    const handleContractCancel = () => {
        setIsContractModalOpen(false);

    }
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <Spin spinning={loading} style={{ marginTop: '5rem' }}>

            {item && (
                <div id='passportItem' >
                    <Descriptions

                        size="small"
                        title={(
                            <Flex justify={'space-between'} align='center'>
                                <Space>
                                    <Button type="default" icon={<DoubleLeftOutlined />}
                                        className='noprint'
                                        onClick={() => navigate(-1)}>
                                    </Button>

                                    <h3 className='titleLine'>Паспорт АСПТ</h3>

                                </Space >
                                <Logo style={{ width: '100px' }} fill={'black'} />
                                <Button type="default" className='noprint' size='large' onClick={() => {
                                    handlePrint(null, () => contentToPrint.current);
                                }}>Печать паспорта</Button>
                            </Flex>
                        )}
                        items={items}
                        bordered
                    />
                    <p style={{ marginTop: '1rem' }}><strong>Состав оборудования</strong></p>
                    {
                        isMobile
                            ? mobileComponent
                            : <Table dataSource={item?.equipment} columns={columnsEquipment} size={'small'} pagination={false} rowKey={'_id'}
                                style={{ maxWidth: 900 }} />

                    }
                    <Space align='baseline' size={'middle'} style={{ marginTop: '1rem' }}>
                        <p><strong>Проведенные работы:</strong></p>
                        {checkRole(['admin', 'manager']) && <Button type="default"
                            className='noprint'
                            onClick={openNewTaskForm}
                            shape={isMobile ? 'circle' : ''}
                            icon={<PlusOutlined />}
                            style={{ borderRadius: '20px' }}
                        >{isMobile ? '' : 'ДОБАВИТЬ ЗАДАНИЕ'}</Button>}
                    </Space>
                    <Table
                        dataSource={tasks}
                        columns={columns}
                        size={'small'}
                        scroll={{ x: 650 }}
                        rowKey={'_id'}
                        pagination={false}
                        onRow={(record, rowIndex) => {
                            return {
                                onDoubleClick: event => { openTaskModal(record) },
                                className: record.status !== 'Выполнено' ? 'noprint' : ''
                            }
                        }}
                        style={{ paddingBottom: '4rem' }}
                    />
                    <div className='logotype'>Информация на {new Date().toLocaleString('ru-RU')}</div>
                </div>
            )}
            <Modal
                okText={"Закрыть"}
                centered
                width={isMobile ? '96vw' : '80vw'}
                afterClose={() => setDescription(null)}
                open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
                // footer={(_, { OkBtn }) => (<OkBtn />)}
                footer={(_, { OkBtn }) => (
                    <Flex justify={description.passport ? 'space-between' : 'right'} >
                        {description.passport &&
                            (
                                <Button                                 >
                                    {/* <Link to={`/passport/${description.passport}`}> */}
                                    <Link to={`/passport?id=${description.passport}`}>

                                        Паспорт
                                    </Link>
                                </Button>
                            )

                        }

                        <OkBtn />
                    </Flex>
                )}
            >
                <TaskDescription values={description} />
            </Modal>

            <Modal
                open={isContractModalOpen}
                width={isMobile ? '96vw' : '80vw'}
                okText={"Закрыть"}
                centered
                onOk={handleContractOk}
                onCancel={handleContractCancel}
                footer={(_, { OkBtn }) => (<OkBtn />)}
            >
                <ContractDetails ctr={contract} />
            </Modal>

            <Drawer
                title={formInitial?._id ? "Редактирование" : "Новое задание"}
                width={800}
                open={isTaskModalOpen}
                onClose={onCloseTaskDrawer}
                extra={
                    <Space>
                        {`Статус: ${formInitial.status}`}
                    </Space>
                }
            >
                <TaskForm
                    onClose={onCloseTaskDrawer}
                    onSubmit={submitForm}
                    workers={workers}
                    // customers={customer}
                    machines={machines}
                    initialValues={formInitial}
                    setFileList={() => { }}
                />
            </Drawer>
            <div ref={contentToPrint} >
            </div>


        </Spin>

    )
}

export default PassportItem

