import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useHttp } from '../hooks/http.hook'
import { Loader } from '../components/Loader'
import { AuthContext } from '../context/AuthContext'
import { OffersList } from '../components/OffersList'

export const OfferListPage = () => {
  const [list, setList] = useState([])
  const  {loading, request} = useHttp()
  const {token} = useContext(AuthContext)

  const fetchOffers = useCallback(async() => {
    try {
      const fetched = await request('/o/offers', 'GET', null, {
        Authorization: `Bearer ${token}`
      })
      setList(fetched)
    } catch (e) {}
  }, [token, request])

  useEffect(()=> {
    fetchOffers()
  }, [fetchOffers])

  if(loading){
    return <Loader />
  }

  return (
    <>
        {!loading && <OffersList list={list} />}
    </>
  )
}