import React, { useCallback, useContext, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { OfferDetails } from '../components/OfferDetail'
import { useHttp } from '../hooks/http.hook'
import { AuthContext } from '../context/AuthContext'
import { Loader } from '../components/Loader'

export const DetailPage = () => {
  const { token } = useContext(AuthContext)
  const { loading, request } = useHttp()
  const [ offer, setOffer ] = useState(null)
  const offerId = useParams().id

  const getOffer = useCallback( async ()=> {
    try {
      const fetched = await request(`/o/details/${offerId}`, 'GET', null, {
        Authorization: `Bearer ${token}`
      })
      setOffer(fetched)
    } catch (e) {}
  }, [request, offerId, token])

useEffect(()=>{
  getOffer()
}, [getOffer])

if(loading){
  return <Loader />
}

  return (
    <>
        { !loading && offer && <OfferDetails offer = {offer}/> }
    </>
  )
}