import React, { useState, useEffect, useContext } from 'react';
import { Form, Input, Button, Select, Divider, AutoComplete } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { DataContext } from '../../context/DataContext';
const { Option } = Select;

const WorkerForm = ({ onSubmit, onClose, initialValues }) => {
    const [formValues, setFormValues] = useState(initialValues)
    const [optionsBranch, setOptionsBranch] = useState([])
    const [workerForm] = useForm()
    const { branches } = useContext(DataContext)

    useEffect(() => {
        workerForm.resetFields()
    }, [initialValues])

    useEffect(() => {
        setOptionsBranch(branches)
    }, [])

    const onFinish = (values) => {
        // console.log('onfinish', { _id: initialValues?._id, ...values })
        onSubmit({ _id: initialValues?._id, ...values })
        onClose()
    };
    const optionsTitle = [
        { value: 'Инженер', },
    ]


    const handleChange = (fieldName, value) => {
        setFormValues(prevState => ({
            ...prevState,
            [fieldName]: value
        }));
    };

    return (
        <div>
            {/* <h2>Добавление сотрудника</h2> */}
            <Form name="worker_form" onFinish={onFinish}
                initialValues={initialValues}
                form={workerForm}>
                <Form.Item label="Фамилия" name="lastname" rules={[{ required: true, message: 'Введите фамилию' }]}>
                    <Input />
                    {/* value={formValues.lastname} onChange={e => handleChange('lastname', e.target.value)} /> */}
                </Form.Item>
                <Form.Item label="Имя" name="firstname" rules={[{ required: true, message: 'Введите имя' }]}>
                    <Input />
                    {/* value={formValues.firstname} onChange={e => handleChange('firstname', e.target.value)} /> */}
                </Form.Item>
                <Form.Item label="Отчество" name="surname" rules={[{ required: true, message: 'Введите отчество' }]}>
                    <Input />
                    {/* value={formValues.surname} onChange={e => handleChange('surname', e.target.value)} /> */}
                </Form.Item>

                <Form.Item label="Должность" name="title" rules={[{ required: true, message: 'Введите должность' }]}>
                    <AutoComplete
                        options={optionsTitle}
                        placeholder="Введите должность сотрудника"
                        // value={formValues.title}
                        // onChange={value => handleChange('title', value)}
                        filterOption={(inputValue, option) =>
                            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        } />

                </Form.Item>
                <Form.Item label="Подразделение" name="branch" rules={[{ required: true, message: 'Введите подразделение' }]}>
                    <AutoComplete
                        options={optionsBranch}
                        placeholder="Введите наименование подразделения"
                        // value={formValues.branch}
                        // onChange={value => handleChange('branch', value)}
                        filterOption={(inputValue, option) =>
                            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        } />
                </Form.Item>
                <Divider />
                <Form.Item label="Контактный телефон" name="phone" rules={[{ required: false, message: 'Введите контактный телефон' }]}>
                    <Input />
                    {/* value={formValues.phone} onChange={e => handleChange('phone', e.target.value)} /> */}
                </Form.Item>
                <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Введите email' }]}>
                    <Input />
                    {/* value={formValues.email} onChange={e => handleChange('email', e.target.value)} /> */}
                </Form.Item>

                <Divider />
                <Form.Item label="Статус" name="status" rules={[{ required: true, message: 'Выберите статус' }]}>
                    <Select
                    // value={formValues.status}
                    // onChange={value => handleChange('status', value)}
                    >
                        <Option value="Работает">Работает</Option>
                        <Option value="Уволен">Уволен</Option>
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" style={{ marginTop: '1rem' }} htmlType="submit">
                        Записать
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default WorkerForm;
