import { Button, Divider, Form, Input, message } from 'antd';
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useHttp } from '../../hooks/http.hook';
import PageNotFound from '../NotFound/PageNotFound';

export const RegisterPage = () => {

    const [user, setUser] = useState({
        firstname: '',
        lastname: '',
        title: '',
        email: '',
    })
    const [isSuccess, setIsSuccess] = useState(false);
    const [searchParams] = useSearchParams();
    const { request } = useHttp();
    const [form] = Form.useForm();


    const onFinish = async (values) => {
        console.log('Received values:', values);
        try {
            await request(`o/api/register/${searchParams.get('token')}`, 'PUT', values)
            message.success('Регистрация завершена успешно!')
            setIsSuccess(true);
        } catch (error) {
            console.error('Error updating user information:', error);
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const validatePasswordConfirm = (_, value) => {
        const { password } = form.getFieldsValue(true);
        if (value !== password) {
            return Promise.reject(new Error('Пароли не совпадают!'));
        }
        return Promise.resolve();
    };

    const fetchUser = (async () => {
        try {
            const response = await request(`o/api/register/${searchParams.get('token')}`);
            setUser(response)
            form.resetFields()
        } catch (error) {
            console.log(error)
        }
    })

    useEffect(() => {
        fetchUser()
    }, [])

    if (!user._id) {
        return <PageNotFound />
    }
    return (
        <>
            {isSuccess ? (
                <div>
                    <p>Регистрация завершена!</p>
                    <p>Перейти на <a href="/">портал Пожтехпром</a></p>
                </div>
            ) : (

                <div>
                    <h3>Завершение регистрации</h3>
                    <Form
                        form={form}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        layout="horizontal"
                        style={{ width: '500px' }}
                        initialValues={{
                            firstname: user.firstname,
                            lastname: user.lastname,
                            title: user.title,
                            email: user.email,
                            branch: user.branch,
                        }}
                    >
                        <Form.Item label="Имя" name="firstname"  >
                            <Input />
                        </Form.Item>
                        <Form.Item label="Фамилия" name="lastname">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Подразделение" name='branch'>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Должность" name='title'>
                            <Input />
                        </Form.Item>
                        <Form.Item label="E-mail" name='email'>
                            <Input disabled />
                        </Form.Item>
                        <Divider />
                        <p>Придумайте пароль</p>
                        <Form.Item
                            label="Пароль"
                            name="password"
                            rules={[
                                { required: true, message: 'Пожалуйста, введите пароль!' },
                                { min: 6, message: 'Пароль должен иметь минимум 6 символов!' },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item
                            label="Повторите пароль"
                            name="confirmPassword"
                            dependencies={['password']}
                            rules={[
                                { required: true, message: 'Пожалуйста, повторите пароль!' },
                                { validator: validatePasswordConfirm },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{
                                    width: '100%',
                                    marginTop: '10px'
                                }}
                            > Зарегистрироваться
                            </Button>
                        </Form.Item>

                    </Form>
                </div >
            )
            }
        </>
    )
}

