import { Form, Input } from 'antd';
import React, { useEffect } from 'react'
import MaskedInput from 'antd-mask-input'

export const ClientForm = (props) => {
    // const [form] = useForm()

    const emptyFields = {
        machine: '',
        location: '',
        person: '',
        phone: '',
        description: '',

    }

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const onFinish = (values) => {
        props.onFinish({ ...values, type: 'install' })
        props?.form?.setFieldsValue(emptyFields)
    }

    useEffect(() => {
        props.form.resetFields()
    }, [props])


    return (
        <div style={{
            marginTop: '0px',
        }}>

            <Form
                {...layout}
                form={props?.form}
                name="control-inst"
                onFinish={onFinish}
                style={{ maxWidth: 600 }}
            >
                <Form.Item name="machine" label="Техника" rules={[{
                    required: true,
                    message: 'Пожалуйста, укажите технику'
                }]}>
                    <Input placeholder='Бренд, модель' />
                </Form.Item>
                <Form.Item name="location" label="Место монтажа" rules={[{
                    required: true,
                    message: 'Укажите предполагаемое место монтажа'
                }]}>
                    <Input placeholder='Место выполнения работ' />
                </Form.Item>
                <Form.Item name="person" label="Ваше имя" rules={[{
                    required: true,
                    message: 'Пожалуйста, укажите ваше имя'
                }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="phone" label="Ваш телефон" rules={[{
                    required: true,
                    message: 'Пожалуйста, укажите ваш телефон'
                }]}>
                    {/* <InputMask
                        name="formPhone"
                        autoComplete="off"
                        value={props?.phone ?? ''}
                        mask="+9 (999) 999-99-99"
                    >
                        {(inputProps) => <Input {...inputProps} />}
                    </InputMask> */}

                    {/* <Input /> */}

                    <MaskedInput
                        name="formPhone"
                        value={props?.phone ?? ''}
                        mask={'+0 (000) 000-00-00'}
                    />
                </Form.Item>
                <Form.Item name="description" label="Информация"
                    rules={[{ required: false }]}>
                    <Input.TextArea rows={5}
                        placeholder='Можно внести дополнительную информацию для специалистов' />
                </Form.Item>
            </Form>
        </div >
    )

}