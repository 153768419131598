import React from 'react'

export const OfferDetails = ( { offer } ) => {
    return (
        <div className="" style={{paddingTop: '2rem'}}>
            <div>
                <h3>{offer.title}</h3>
            </div>
            {offer.content.map(el => <div dangerouslySetInnerHTML={{ __html: el.html }} />)}
            <h5>Footer</h5>
        </div>
    )
}