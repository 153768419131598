import React from 'react'
import { Col, Row, Space } from 'antd'

const origin = window.location.origin;
export const ClientCarousel = ({ offers, handleOpenCarousel }) => {
    return (
        <>
            <Row gutter={[16, 16]}>
                {offers.map((offer) => (
                    <Col sm={8} xs={24} key={`col${offer.id}`}>
                        <div className='cardsImage' key={offer.id}
                            style={{ width: '100%', maxWidth: 500 }}
                        >
                            <div style={{
                                backgroundColor: 'grey',
                                backgroundImage: `url(${origin}/${offer.image})`,
                                filter: 'sepia(1) hue-rotate(178deg) brightness(.9) contrast(1) opacity(.3) saturate(2.5)', //radial-gradient(circle, rgba(2,0,36,0) 28%, rgba(0,0,0,.8) 100%), 
                                backgroundSize: 'auto 100%',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                width: '100%',
                                position: 'relative',
                                minHeight: 300,
                                display: 'block',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                            }}
                                className='cardsImage'
                            >
                                <Space
                                    direction='vertical'
                                    size={0}
                                    style={{
                                        padding: '0px 20px 0px 20px',
                                        color: 'white',
                                        position: 'relative',
                                        backgroundColor: 'rgb(5, 66, 150, 0.75)',
                                        bottom: -240,
                                        fontWeight: 500,
                                        fontSize: 18,
                                        justifyContent: 'center',
                                        width: '100%',
                                        height: 60,
                                        cursor: 'pointer',
                                        overflow: 'hidden',
                                    }}
                                    onClick={() => handleOpenCarousel(offer)}
                                >
                                    {offer.title}
                                </Space>

                            </div>
                        </div>
                    </Col >
                ))}
            </Row >
        </>
    )
}