import React from 'react'
import { Row, Col, Card, Statistic, Button } from 'antd'

export const ClientCards = ({ systems, warranty, mntc, showModal }) => {
    return <>

        <div style={{ padding: "8px" }}>
            <Row gutter={[16, 16]}>
                <Col span={8}
                    xs={12}
                    sm={12}
                >
                    <Card style={{ overflow: 'auto', minWidth: "50px" }}>
                        <Statistic title="Всего систем" prefix={''} value={systems ?? 0} suffix={'ед.'} />
                        {/* suffix="/ 100" */}
                    </Card>
                </Col>
                <Col span={8} xs={12}
                    sm={12}>
                    <Card style={{ overflow: "hidden", minWidth: "50px" }}>
                        <Statistic title="На гарантии" value={warranty ?? 0} suffix={'ед.'} />
                    </Card>
                </Col>
                <Col span={8} xs={12}
                    sm={12}>
                    <Card style={{ overflow: "hidden", minWidth: "50px" }}>
                        <Statistic title="Требуется ТО"
                            valueStyle={{ color: 'red' }}
                            value={mntc ?? 0} suffix={'ед.'} style={{ color: 'red' }} />
                    </Card>
                </Col>
                <Col span={8} xs={12}
                    sm={12}>
                    <Card style={{
                        overflow: "hidden",
                        minHeight: 115,
                        minWidth: "50px",
                        alignContent: 'center',
                        textAlign: 'center',
                        backgroundColor: 'rgb(5, 66, 150)',
                        color: 'white',

                    }}
                        onClick={showModal}
                        className={'addButton'}
                    >
                        + Заказать новый монтаж

                        {/* <Button
                            danger
                            className='noRadius'
                            type='primary'
                            // style={{ height: 50 }}
                            onClick={showModal}

                        >
                            + Заказать новый монтаж
                        </Button> */}
                    </Card>
                </Col>
            </Row >


        </div >
    </>

}