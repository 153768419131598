
import React, { useContext, useState } from 'react'
import { useHttp } from '../hooks/http.hook'
import { AuthContext } from '../context/AuthContext'
// import { useMessage } from '../hooks/message.hook'
import { Button, Form, Input, message } from 'antd';


export const CreatePage = () => {
  const auth = useContext(AuthContext)
  const [offer, setOffer] = useState('')
  const [title, setTitle] = useState('')
  const { request, loading }  = useHttp()
  const [form] = Form.useForm();

  // const message = useMessage()
  
  const clearFields = () => {
    form.setFieldValue('offer', '')
    form.setFieldValue('title', '')
    setOffer('');
    setTitle('')
  }
  
  const sendForm = async () => {
    if (offer && title) {
      try {
        const oData = await request('/o/create', 'POST', { 
          title: title,
          data: offer 
        }, {
          Authorization: `Bearer ${auth.token}`
        })
        message.info(`Документ ${oData.offer.title} создан`)
        // message(`Документ ${oData.offer.title} создан`)
        clearFields()
      } catch (e) {}
    }
  }

  const keyDownHandler = async event => {
    if(event.key === 'Enter'){
      sendForm()
    }
  }


  return (
    <Form
    form={form}
    name="wrap"
    // labelCol={{
    //   flex: '110px',
    // }}
    labelAlign="top"
    labelWrap
    wrapperCol={{
      flex: 1,
    }}
    colon={false}
    style={{
      maxWidth: 900,
    }}
    layout="vertical"
  >
    <Form.Item
      label="Наименование"
      name="title"
      id="title"
      onChange={e => setTitle(e.target.value)}
      // onKeyDown={keyDownHandler}
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Содержание"
      name="offer"
      id="offer"
      onChange={e => setOffer(e.target.value)}
      // onKeyDown={keyDownHandler}
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Input.TextArea />
    </Form.Item>

    <Form.Item label=" ">
      <Button type="primary" htmlType="submit" onClick={sendForm}>
        Создать новую запись
      </Button>
    </Form.Item>
  </Form>)
  // return (
  //   <div className='row'>
  //     <div className="col s8 offset-s2" style={{paddingTop: '2rem'}}>
  //       <div className="input-field">
  //         <input 
  //           placeholder='Заголовок КП'
  //           type='text'
  //           id='title'
  //           value={title}
  //           onChange={e => setTitle(e.target.value)}
  //           onKeyDown={keyDownHandler}
  //         />
  //       </div>
  //       <div className="input-field">
  //         <input 
  //           placeholder='Содержание'
  //           type='text'
  //           id='offer'
  //           value={offer}
  //           onChange={e => setOffer(e.target.value)}
  //           onKeyDown={keyDownHandler}
  //         />
  //       </div>
  //     </div>
  //   </div>
  // )
}