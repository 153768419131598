import React, { useState, useEffect, useContext } from 'react'
import { Table, Spin, Typography, Space, Popconfirm, Input, List } from 'antd'
import { EditOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons'
import { GetColumnSearchProps } from '../SearchLine'
import useSize from '../Equipment/EquipmentList'
import { AuthContext } from '../../context/AuthContext'
const { Paragraph } = Typography


export const CustomerList = ({ list, loading, onEdit, onView, onDelete, isMobile }) => {
    const { checkRole } = useContext(AuthContext)
    const [filteredCustomers, setFilteredCustomers] = useState(null)
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 16,
        total: list?.length,
        onChange: (page) => {
            setPagination({ ...pagination, current: page });
        },
    });
    const windowSize = useSize()


    useEffect(() => {
        window.dispatchEvent(new Event("resize"))
    }, [])
    const columns = [
        {
            title: '#',
            width: '3rem',
            key: 'num',
            // render: (_, r, index) => (<div style={{ textAlign: 'center' }}>{index + 1}</div>)
            render: (text, record, index) => {
                const currentPage = pagination.current || 1;
                return ((currentPage - 1) * pagination.pageSize) + index + 1;
            },
        },
        {
            title: 'Наименование',
            // fixed: true,
            dataIndex: 'name',
            width: '30%',
            ...GetColumnSearchProps('name', 'наименованию'),
            sorter: (a, b) => a?.name?.toLowerCase().localeCompare(b?.name.toLowerCase()),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'ИНН',
            dataIndex: 'inn',
            ...GetColumnSearchProps('inn', 'ИНН')
        },
        {
            title: 'Адрес',
            dataIndex: 'address',
            ...GetColumnSearchProps('address', 'адресу'),
            render: (_, r, index) => (<Typography.Paragraph ellipsis style={{ marginBottom: '0px' }}>{r.address}</Typography.Paragraph>)
        },
        {
            title: 'Email',
            dataIndex: 'email',
            ...GetColumnSearchProps('email', 'электронной почте'),

            render: (_, r, index) => (<Typography.Paragraph ellipsis style={{ marginBottom: '0px' }}>{r.email}</Typography.Paragraph>)
        },
        {
            title: 'Сектор',
            dataIndex: 'category',
            ...GetColumnSearchProps('category', 'сектору')
        },
        {
            title: 'Действия',
            key: 'action',
            width: '100px',
            render: (text, record) => (
                <Space size={'middle'}>
                    <SearchOutlined
                        onClick={() => {
                            onView(record)


                        }}
                    />
                    <EditOutlined
                        onClick={() => {
                            onEdit(record)
                        }}
                    />
                    <Popconfirm
                        title="Удаление записи"
                        description="Вы уверены, что хотите удалить эту запись?"
                        okText="Да"
                        cancelText="Нет"
                        onConfirm={() => { onDelete(record) }}
                    >
                        <DeleteOutlined style={{ color: 'red' }} />
                    </Popconfirm>
                </Space>
            ),
        },
    ]


    const mobileActions = (record, index) => {
        const actions = [
            (<SearchOutlined
                onClick={() => {
                    onView(record)


                }}
            />),
            (
                <EditOutlined
                    onClick={() => {
                        onEdit(record)
                    }}
                />

            ),
            (<Popconfirm
                title="Удаление записи"
                description="Вы уверены, что хотите удалить эту запись?"
                okText="Да"
                cancelText="Нет"
                onConfirm={() => { onDelete(record) }}
            >
                <DeleteOutlined style={{ color: 'red' }} />
            </Popconfirm>
            )]
        if (checkRole(['admin', 'manager'])) { return actions }
        else {
            return [
                (<SearchOutlined
                    onClick={() => {
                        onView(record)


                    }}
                />)
            ]

        }
    }

    let mobileComponent = (
        <div>
            <Input.Search
                style={{ marginTop: '1rem' }}
                allowClear
                placeholder='Поиск по всем полям паспорта'
                onSearch={(value) => {
                    console.log(value)
                    if (value === '') { setFilteredCustomers(null) }
                    let filtered = list?.filter((item) => {
                        const vals = value.split(' ');
                        let fil = true
                        if (typeof vals === 'object') {
                            for (let i in vals) {
                                fil = fil && JSON.stringify(item).toString().toLowerCase().includes(vals[i].toLowerCase())
                            }
                        } else {
                            fil = JSON.stringify(item).toString().toLowerCase().includes(value.toLowerCase())
                        }

                        return fil
                    })

                    // JSON.stringify(item).toString().toLowerCase().includes(value.toLowerCase()));
                    if (filtered) {
                        setFilteredCustomers(filtered);
                    } else {
                        setFilteredCustomers(null)
                    }

                }}
            />
            <p>Всего записей: {filteredCustomers?.length ?? list?.length}</p>
            <List
                style={{
                    marginTop: '6px',
                    height: 'calc(100vh - 230px)',
                    overflow: 'auto',
                }}
                loading={loading}
                itemLayout="horizontal"
                dataSource={filteredCustomers ?? list}
                renderItem={(record, index) => (
                    <List.Item
                        actions={mobileActions(record, index)}

                    >
                        <List.Item.Meta
                            onClick={() => onView(record)}
                            avatar={index + 1}
                            title={<span style={{ fontSize: '14px' }}>
                                {record.form} {record.name}
                            </span>}
                            description={<>
                                <Paragraph style={{ fontSize: '12px', color: 'rgba(0, 0, 0, 0.45)', margin: 0 }}>ИНН: {record.inn}</Paragraph>
                                <Paragraph style={{ fontSize: '12px', color: 'rgba(0, 0, 0, 0.45)', margin: 0 }} ellipsis={{ rows: 1, expandable: false, }}>Адрес: {record.address} </Paragraph>
                            </>
                            }
                        />
                    </List.Item>)}


            />

        </div>
    )


    return isMobile
        ? mobileComponent
        : (
            <Table size={'small'}
                style={{ marginTop: '1rem' }}
                loading={loading}
                dataSource={list}
                columns={columns}

                rowKey={"_id"}
                onRow={(record) => {
                    return { onDoubleClick: () => { onView(record) } }
                }}
                pagination={false}
                scroll={{ x: 600, y: +windowSize[1] - 220, }}
                footer={(data) => {
                    return < div style={{ textAlign: 'end' }}>Всего записей: {data?.length ?? 0}</div>
                }} // Modified from setting state
            />
        )
}