import { createContext } from "react";

const noop = () => { }

export const AuthContext = createContext({
        token: null,
        userId: null,
        login: noop,
        logout: noop,
        isAuthenticated: false,
        role: null,
        checkRole: noop,
        company: null,
})
