import { Flex, Image as ImageA } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import { useState } from "react";


export const PhotoModule = ({ files, size }) => {

    const [visible, setVisible] = useState(false);
    const [startIndex, setStartIndex] = useState(0);

    let images = files?.filter(f => f?.mimetype === 'image/jpeg' || f?.mimetype === 'image/png')
    let source = [...images ?? []]

    const getFirstImage = () => {
        let index = 0
        return index
    }

    return (
        <>
            {files?.length > 0 && <>
                <Flex wrap="wrap" gap="small">

                    {source.map((file, index) => (
                        <div key={file.filename} style={{ display: 'flex' }} >
                            <ImageA
                                alt="Preview"
                                preview={{
                                    maskClassName: 'show',
                                    visible: false,
                                    mask:
                                        <>
                                            <Paragraph
                                                ellipsis={{ rows: 3, }}
                                                style={{
                                                    color: 'white',
                                                    position: 'absolute',
                                                    bottom: 0,
                                                    left: 0,
                                                    margin: '5px 5px',
                                                    fontSize: '12px'
                                                }}
                                            >{file.filename}</Paragraph>
                                        </>
                                }}
                                height={size === 'small' ? '70px' : '100px'}
                                src={`\\o\\api\\pictures\\${file.filePath}`}
                                onClick={e => {
                                    if ((e.target.classList.value === 'ant-image-mask show' || e.target.parentNode.classList.value === 'ant-image-mask show') && file.mimetype !== 'application/pdf' && e.target.nodeName !== "svg") {
                                        setStartIndex(index - getFirstImage())
                                        setVisible(true)
                                    } else if (e.target.nodeName === 'ARTICLE' || e.target.nodeName === 'DIV') {
                                        if (file.mimetype !== 'application/pdf') {
                                            setStartIndex(index - getFirstImage());
                                            setVisible(true)

                                        }
                                    }
                                }}
                            />
                        </div>
                    ))}
                </Flex></>}
            <div
                style={{
                    display: 'none',
                }}
            >
                <ImageA.PreviewGroup
                    preview={{
                        visible,
                        onVisibleChange: (vis) => setVisible(vis),
                        onChange: (c) => { setStartIndex(c) },
                        current: startIndex,
                    }}
                >
                    {images?.map((file, ind) => {
                        if (file.mimetype !== 'application/pdf') {
                            return (<ImageA
                                key={ind}
                                src={`\\o\\api\\pictures\\${file?.filePath}`}
                            />)
                        }

                    })}
                </ImageA.PreviewGroup>
            </div>
        </>
    )
}