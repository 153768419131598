import React, { useContext, useEffect, useState } from 'react'
import { useHttp } from '../hooks/http.hook'
// import { useMessage } from '../hooks/message.hook'
import { AuthContext } from '../context/AuthContext'
import { Button, Form, Input, Card, Space } from 'antd'
import { message } from 'antd'
import Link from 'antd/es/typography/Link'
import Logo from '../pages/Logo'
import { useNavigate } from 'react-router-dom'

export const AuthPage = () => {

  const auth = useContext(AuthContext)
  const { error, request, clearError } = useHttp()
  const [forma, setForma] = useState({
    email: "", password: ""
  })

  const [form] = Form.useForm()
  const navigate = useNavigate()
  useEffect(() => {
    if (error) {
      message.info(error)
      clearError()
    }
  }, [error, clearError])

  const changeHandler = () => {
    const email = form.getFieldValue('email')
    const password = form.getFieldValue('password')
    setForma({ email, password })
  }


  const registerHandler = async () => {

    try {
      const data = await request('/api/auth/register', 'POST', { ...forma })
      // console.log('Data', data)
    } catch (e) { }
  }

  const loginHandler = async (values) => {
    if (!values.email) {
      return
    }
    try {
      const data = await request('/api/auth/login', 'POST', { ...values })  //...form
      auth.login(data.token, data.userId, data.role, data.companyName)
      navigate("/")
    } catch (e) { }
  }

  const onFinish = (values) => {
    console.log('Success:', values);
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    },
  };
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#e9e9e9' }}>
      <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}>
        <Card
          title="Авторизация"
          bordered={false}
          // cover={
          //   <div
          //     style={{
          //       width: '200px', height: '50px',
          //       top: 0, right: 0,
          //       display: 'flex',
          //       position: 'absolute',
          //       zIndex: 1,
          //       color: 'white',
          //       backgroundColor: 'rgb(5, 43, 120)',
          //       scale: '0.4',
          //     }}>
          //     <Logo />
          //   </div>}
          styles={{
            header: {
              backgroundColor: 'rgb(5, 66, 160, 1)',
              color: '#ffffff',
              borderRadius: 0,

            },
          }}

          style={{
            borderRadius: 0,

            maxWidth: 430,
            width: 'calc(100vw - 40px)'
          }}
        >


          <Form
            {...formItemLayout}
            form={form}
            name="auth"
            onFinish={loginHandler}
          >
            <Form.Item
              label="Email"
              name="email"
              id="email"
              onChange={changeHandler}

              rules={[
                {
                  required: true,
                  message: 'Введите email!',
                },
              ]}
            >
              <Input
                style={{ height: '2.2rem', borderRadius: 0 }}
              />
            </Form.Item>

            <Form.Item
              label="Пароль"
              name="password"
              id="password"
              onChange={changeHandler}
              rules={[
                {
                  required: true,
                  message: 'Введите пароль!',
                },
              ]}
            >
              <Input.Password
                style={{
                  height: '2.2rem',
                  borderRadius: 0,
                }} />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                xs: { span: 24 },
                sm: { span: 24 },
              }}
            >
              <Button type="primary"
                htmlType="submit"
                style={{
                  marginTop: '1rem', width: '100%',
                  borderRadius: 0,
                  height: '3rem',
                  backgroundColor: 'rgb(5,43,120)'
                }}
              >
                Войти
              </Button>

              {/* <Button type="primary"
          onClick={registerHandler}>
          Регистрация
          </Button> */}
            </Form.Item>
          </Form>
          <Link href="/forgotpassword">Забыли пароль?</Link>
        </Card>
        <Logo style={{ position: 'absolute', top: '30', right: '30', zIndex: 1, width: 200, fill: 'white' }} className='navpanel' />

      </Space>
    </div >
  )
}

