export const Telegram = ({ style }) => {
    return (
        <svg version="1.1"
            id="Capa_1"
            style={style}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 455.731 455.731" xmlSpace="preserve">
            <g>
                <rect x="0" y="0" style={{ fill: '#61A8DE', width: "455.731", height: "455.731" }} />
                <path style={{ fill: '#FFFFFF' }} d="M358.844,100.6L54.091,219.359c-9.871,3.847-9.273,18.012,0.888,21.012l77.441,22.868l28.901,91.706
       c3.019,9.579,15.158,12.483,22.185,5.308l40.039-40.882l78.56,57.665c9.614,7.057,23.306,1.814,25.747-9.859l52.031-248.76
       C382.431,106.232,370.443,96.08,358.844,100.6z M320.636,155.806L179.08,280.984c-1.411,1.248-2.309,2.975-2.519,4.847
       l-5.45,48.448c-0.178,1.58-2.389,1.789-2.861,0.271l-22.423-72.253c-1.027-3.308,0.312-6.892,3.255-8.717l167.163-103.676
       C320.089,147.518,324.025,152.81,320.636,155.806z"/>
            </g>
        </svg>)
}