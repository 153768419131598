import React, { useEffect } from 'react';
import { Table, Space, Tooltip, Popconfirm, Spin, List, Input, Tag } from 'antd';
import { DeleteOutlined, EditOutlined, SearchOutlined, CloseSquareOutlined, CheckSquareOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { GetColumnSearchProps } from '../SearchLine';
import useSize from '../Equipment/EquipmentList';


const WorkerList = ({ workers, isMobile, onDelete, onEdit, onView, loading, checkRole }) => {
    const [filteredWorkers, setFilteredWorkers] = React.useState(null);

    useEffect(() => {
        window.dispatchEvent(new Event("resize"))
    }, [])

    const windowSize = useSize()

    const statuses = {
        'Работает': {
            color: 'green',
            text: 'Работает',
            icon: <CheckCircleOutlined />,
        },
        "Уволен": {
            color: 'red',
            text: 'Уволен',
            icon: <CloseCircleOutlined />,

        }
    }
    const columns = [
        {
            title: '№',
            key: 'num',
            render: (_, rec, index) => (
                <div>{index + 1}</div>
            ),
            width: (workers.length).toString().length * 20,
        },
        {
            title: 'Сотрудник',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a?.lastname?.localeCompare(b.lastname),
            sortDirections: ['ascend', 'descend'],
            render: (_, record) => (
                <>{record.lastname} {record.firstname} {record.surname}</>
            ),
            ...GetColumnSearchProps('fullName', 'ФИО'),
            width: 320,
        },
        {
            title: 'Должность',
            dataIndex: 'title',
            key: 'title',
            width: 400,
            ...GetColumnSearchProps('title', 'должности'),

        },
        {
            title: 'Подразделение',
            dataIndex: 'branch',
            key: 'branch',
            width: 120,
            ...GetColumnSearchProps('branch', 'подраделению'),
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            width: 120,
            key: 'status',
            ...GetColumnSearchProps('status', 'статусу'),
            render: (text, record) => (
                <span>

                    <Tag key={record._id} color={statuses[record.status].color} icon={statuses[record.status].icon}>
                        {statuses[record.status].text}
                    </Tag>

                </span>

            )
        },
        {
            title: 'Действия',
            key: 'action',
            // responsive: ['sm'],
            width: 120,
            render: (text, record) => (
                <Space size={'middle'}  >

                    <SearchOutlined
                        onClick={() => onView(record)}
                    />
                    <Tooltip title='Редактировать карточку сотрудника' color={'geekblue'} placement="top">
                        <EditOutlined
                            onClick={() => {
                                onEdit(record)
                            }}
                        />
                    </Tooltip>

                    <Popconfirm
                        title="Удаление записи"
                        description="Вы уверены, что хотите удалить эту запись?"
                        okText="Да"
                        cancelText="Нет"
                        onConfirm={() => onDelete(record._id)}
                    >
                        <DeleteOutlined style={{ color: 'red' }} />
                    </Popconfirm>

                </Space >
            ),
        },
    ];

    const mobileActions = (record, index) => {
        const actions = [

            (<SearchOutlined
                onClick={() => {
                    onView(record)


                }}
            />),
            (
                <Tooltip title='Редактировать карточку' color={'geekblue'} placement="top">
                    <EditOutlined
                        onClick={() => {
                            onEdit(record)
                        }}
                    />
                </Tooltip>

            ),
            (<Popconfirm
                title="Удаление записи"
                description="Вы уверены, что хотите удалить эту запись?"
                okText="Да"
                cancelText="Нет"
                onConfirm={() => onDelete(record._id)}
            >
                <DeleteOutlined style={{ color: 'red' }} />
            </Popconfirm>)]
        return actions
    }

    let mobileComponent = (
        <div>
            <Input.Search
                style={{ marginTop: '1rem' }}
                allowClear
                placeholder='Поиск'
                onSearch={(value) => {
                    let filtered = workers.filter((item) => {
                        const vals = value.split(' ');
                        let fil = true
                        if (typeof vals === 'object') {
                            for (let i in vals) {
                                fil = fil && JSON.stringify(item).toString().toLowerCase().includes(vals[i].toLowerCase())
                            }
                        } else {
                            fil = JSON.stringify(item).toString().toLowerCase().includes(value.toLowerCase())
                        }

                        return fil
                    })

                    // JSON.stringify(item).toString().toLowerCase().includes(value.toLowerCase()));
                    if (filtered) {
                        setFilteredWorkers(filtered);
                    } else {
                        setFilteredWorkers([])
                    }

                }}
            />

            <List
                style={{
                    marginTop: '6px',
                    height: 'calc(100vh - 220px)',
                    overflow: 'auto',
                }}
                itemLayout="horizontal"
                dataSource={filteredWorkers ?? workers}
                renderItem={(record, index) => (
                    <List.Item
                        actions={mobileActions(record, index)}>
                        <List.Item.Meta
                            avatar={index + 1}
                            title={<div style={{ fontSize: '14px' }}>
                                {record?.firstname} {record?.surname} {record?.lastname}&nbsp;
                                {
                                    record.status === 'Уволен' ? <CloseSquareOutlined style={{ fontSize: '16px', color: 'red' }} />
                                        : <CheckSquareOutlined style={{ fontSize: '16px', color: 'green' }} />
                                }
                            </div>}
                            description={<>
                                <div style={{ fontSize: '12px' }}>{record?.title}{record?.branch && ', ' + record?.branch}</div>
                                <div style={{ fontSize: '12px' }}>{record?.phone}</div>


                            </>
                            }
                        />
                    </List.Item>)}
            />
        </div>
    )

    return isMobile
        ? mobileComponent
        : (
            <div>
                <Table
                    loading={{ indicator: <div><Spin /></div>, spinning: loading }}
                    style={{ marginTop: '1rem' }}
                    columns={columns}
                    dataSource={workers}
                    // scroll={{ x: 750 }}
                    size='small'
                    rowKey='_id'
                    pagination={false}
                    // scroll={{ x: 550 }}
                    scroll={{ x: 600, y: +windowSize[1] - 220, }}
                    footer={(data) => {
                        return < div style={{ textAlign: 'end' }}>Всего записей: {data?.length ?? 0}</div>
                    }} // Modified from setting state
                // footer={(data) => {
                //     return < div style={{ textAlign: 'end' }}>Всего записей: {data?.length ?? 0}</div>
                // }} // Modified from setting state
                />
            </div>
        );
};

export default WorkerList;
