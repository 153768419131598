import { Popover, Table, Tag } from 'antd'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import useSize from '../Equipment/EquipmentList';
import { GetColumnSearchProps } from '../SearchLine';

export const ForecastList = ({ forecastTasks, loading }) => {

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 16,
        total: forecastTasks?.length,
        onChange: (page) => {
            setPagination({ ...pagination, current: page });
        },
    });

    const windowSize = useSize()

    useEffect(() => {
        window.dispatchEvent(new Event("resize"))
    }, [])
    const columns = [
        {
            title: '#',
            width: (forecastTasks.length).toString().length * 20,
            key: 'num',
            render: (text, record, index) => {
                return <div style={{
                    textAlign: 'center'
                }}>{index + 1}</div>
            },
        },
        {
            title: 'Тип',
            dataIndex: 'type',
            key: 'type',
            width: 100,

        },
        {
            title: 'Клиент',
            dataIndex: 'customer',
            key: 'customer',
            width: 200,
            ...GetColumnSearchProps(['customer', 'name'], 'клиенту'),
            render: (item, record) => {
                return (
                    <>
                        <>{item?.form} {item?.name}</>
                        <div style={{ color: 'grey', fontStyle: 'italic' }}>ИНН: {item?.inn}</div>
                    </>)
            }
        },
        {
            title: 'Техника',
            dataIndex: 'machine',
            key: 'machine',
            width: 200,
            render: (el, item) => {
                return item?.machine ?
                    <>
                        {item?._id && item?.type === 'Прогноз'
                            ? <Popover content={equip(item?.equipment)} title="Оборудование" trigger="hover">
                                <Link to={`/passport?id=${item?._id}`}>{el?.brand} {el?.model}</Link>
                            </Popover>
                            : item?.passport?._id && item?.type !== 'Прогноз'
                                ? <Popover content={equip(item?.equipment)} title="Оборудование" trigger="hover">
                                    <Link to={`/passport?id=${item?.passport?._id}`}>{el?.brand} {el?.model}</Link>
                                </Popover>
                                : <>{el?.brand} {el?.model}</>}
                        {/* <Link to={`/passport/${item?._id}`}>{el?.brand} {el?.model}</Link> */}

                        <div style={{ fontSize: 12, color: '#a8a8a8' }}>{el?.serialNumber} {el?.garageNumber
                            ? " /" + el?.garageNumber
                            : ""}</div>
                    </>
                    : <>-</>
            }
        },
        {
            title: 'Прогноз ТО',
            dataIndex: 'nextMaintenanceDate',
            key: 'nextMaintenanceDate',
            width: 200,
            defaultSortOrder: 'descend',
            sorter: (a, b) => new Date(a?.nextMaintenanceDate) - new Date(b?.nextMaintenanceDate),
            render: (item, record) => {
                let inTime = new Date(item) > new Date();
                return inTime
                    ? <>
                        {new Date(item).toLocaleDateString('ru-Ru')}
                        &nbsp;<span style={{ color: 'grey', fontStyle: 'italic' }}>через {Math.round((new Date(item) - new Date()) / (1000 * 60 * 60 * 24))} дн.</span>
                    </>
                    : <>
                        {new Date(item).toLocaleDateString('ru-Ru')}
                        &nbsp;<span style={{ color: 'red', fontStyle: 'italic' }}>просрочено на {Math.floor((new Date() - new Date(item)) / (1000 * 60 * 60 * 24))} дн.</span>
                    </>

            }
        },
        {
            title: 'Гарантия',
            dataIndex: 'warrantyFinishDate',
            key: 'warrantyFinishDate',
            width: 70,
            render: (item) => {
                return <>
                    {new Date(item) > new Date()
                        ? (<Tag color="red">ГАРАНТИЯ</Tag>)
                        : (<></>)}
                </>

            }
        },
    ]

    const columnsEq = [
        {
            title: '#',
            dataIndex: 'key',
            key: 'key',
            width: '4%',
            render: (_, r, index) => (<div>{index + 1}</div>)
        },
        {
            title: 'Наименование',
            dataIndex: 'description',
            key: 'description',
            width: '50%',
        },
        {
            title: 'Кол-во',
            dataIndex: 'qty',
            key: 'qty',
            width: '17%',
        },
        {
            title: 'Серийный №',
            dataIndex: 'comment',
            key: 'comment',
            width: '23%',
        }
    ]

    let equip = (data) => {
        if (data?.length > 0) {
            return (
                <Table
                    columns={columnsEq}
                    size="small"
                    dataSource={data}
                    loading={loading}
                    scroll={{ x: 550 }}
                    pagination={false}
                    rowKey={record => record._id}
                />
            )
        }

    }

    return (
        <>
            <Table dataSource={forecastTasks}
                size='small'

                columns={columns}
                loading={loading}
                pagination={false}
                scroll={{ x: 1000, y: +windowSize[1] - 220, }}
                footer={(data) => {
                    return < div style={{ textAlign: 'end' }}>Всего записей: {data?.length ?? 0}</div>
                }} // Modified from setting state
                rowKey={'_id'}
            />
        </>
    )

}