import { Button, Input, Row, Col, Space, Form, DatePicker, InputNumber, Select, Divider, Table, Modal, Typography, Popconfirm, AutoComplete } from 'antd'
import React, { useEffect, useState, useContext, useCallback } from 'react'
import { useHttp } from '../hooks/http.hook'
import { AuthContext } from '../context/AuthContext'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
const { Text } = Typography;
const { Option } = Select;

export const MachinesForm = ({ onFinish, edValues, setEdValues, forma, machines, version }) => {
  const { request } = useHttp()
  const { token, checkRole } = useContext(AuthContext)
  const [select, setSelect] = useState([])
  const [brandModel, setBrandModel] = useState([])
  const [form] = Form.useForm()
  const [formd, formown] = Form.useForm()
  const [operationHours, setOperationHours] = useState([]);
  const [isModalVisibleOwn, setIsModalVisibleOwn] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState({ date: null, hours: null, rowIndex: null });


  const handleOwnerChange = (ownerId, val) => {
    // Update the owner in the form state
    if (val?.value) {
      form.setFieldsValue({ owner: { _id: val?.value, value: val?.label } });
      setEdValues({ ...edValues, owner: { _id: val?.value, name: val?.label } }) //{ _id: val?.value, name: val?.label }
      setIsModalVisibleOwn(false)

    }

  };
  const fetchContent = useCallback(async () => {
    if (!(select.length > 0)) {
      try {
        let response = await request('/o/api/customer', 'GET', null, { Authorization: `Bearer ${token}` })
        let customersList = response.map(el => ({ value: el._id, label: `${el.form} ${el.name} ИНН: ${el.inn}` })) //label: `${el.name}, ИНН: ${el.inn}`
        setSelect(customersList)
        // console.log(customersList)
      } catch (e) { }
    }
  }, [request, token, select])

  const columns = [
    {
      title: 'Дата наработки',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Наработка м.ч.',
      editable: true,
      dataIndex: 'hours',
      key: 'hours',
    },
    {
      title: 'Действия',
      key: 'action',
      width: 120,
      render: (text, record, index) => (
        <Space size={'middle'}>
          <EditOutlined
            onClick={() => handleEditRow(index)}
          />
          {checkRole(['admin', 'manager']) && <Popconfirm
            title="Удаление записи"
            description="Вы уверены, что хотите удалить эту запись?"
            okText="Да"
            cancelText="Нет"
            onConfirm={() => handleDeleteRow(index)}
          >
            <DeleteOutlined style={{ color: 'red' }} />
          </Popconfirm>}
          {record?.document?.name === 'task' && <div>*</div>}
        </Space>
      ),
    }
  ]


  useEffect(() => {
    // setFields(edValues)
    setOperationHours(edValues?.operationHours.sort((a, b) => new Date(a.date) - new Date(b.date)))
  }, [edValues]) //edValues,setFields

  useEffect(() => {
    fetchContent()
  }, [fetchContent])

  useEffect(() => {
    formd.setFieldsValue(modalData)
  }, [modalData, formd])

  useEffect(() => {
    let data = machines.map(el => ({ model: el.model, brand: el.brand, category: el?.category ?? null, machineType: el?.machineType ?? null }))
      .sort((a, b) => { return a.model.localeCompare(b.model) })
    // console.log(data)
    setBrandModel(data)
  }, [])

  const handleAddRow = () => {
    setModalData({ date: dayjs(new Date()), hours: null, rowIndex: null })
    setIsModalVisible(true);

  };

  const handleEditRow = (index) => {
    const rowData = operationHours[index];
    setModalData({ date: rowData.date, hours: +rowData.hours, rowIndex: index });
    setIsModalVisible(true);
  };

  const handleDeleteRow = (index) => {

    const updatedOperationHours = [...operationHours];
    updatedOperationHours.splice(index, 1);
    setOperationHours(updatedOperationHours);
    setEdValues({ ...edValues, operationHours: updatedOperationHours })
  };



  const handleModalSubmit = (values) => {
    // formd.validateFields(['date', 'hours']).then(values => {
    if (modalData.rowIndex !== null) {
      const updatedOperationHours = [...operationHours];
      updatedOperationHours[modalData.rowIndex] = { date: values.date, hours: +values.hours };
      setOperationHours(updatedOperationHours);
    } else {
      setOperationHours([...operationHours, { date: values.date, hours: values.hours }]);
      // setEdValues({...edValues, operationHours: [...operationHours, { date: values.date, hours: values.hours }], lastHours: values.hours })
    }

    setIsModalVisible(false);
    // });
  };

  const handleNewOwner = (value) => {
    form.setFieldsValue({ owner: value })
    setEdValues({ ...edValues, owner: value })

  }

  const onSubmit = (values) => {

    let updatedHoursArray = [...operationHours].filter(el => el?.hours && el?.hours > 0)
    let updatedEdValues = { ...edValues, ...values }
    if (updatedHoursArray.length > 0) {
      updatedEdValues.operationHours = updatedHoursArray
      updatedEdValues.lastHours = updatedHoursArray[updatedHoursArray.length - 1].hours
      // setEdValues(updatedEdValues)
    }
    onFinish(updatedEdValues, updatedHoursArray)
  }


  return (
    <Form
      layout="vertical"
      onFinish={onSubmit}
      form={form}
      initialValues={edValues}
    >
      <Row gutter={[12, 6]} justify="space-between">
        <Col xs={24} sm={16}>
          <Form.Item
            label="Модель"
            name="model"
            rules={[{ required: true, message: 'Введите наименование модели' }]}
          >
            {/* <Input /> */}
            <AutoComplete
              options={[...new Set(brandModel.map(el => el.model))].map(el => ({ value: el }))}
              allowClear

              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            label="Бренд"
            name="brand"
            rules={[{ required: true, message: 'Введите наименование бренда' }]}
          >
            {/* <Input /> */}
            <AutoComplete
              options={[...new Set(brandModel.map(el => el?.brand))].map(el => ({ value: el })).sort((a, b) => { return a.value.localeCompare(b.value) })}
              allowClear
              showSearch

              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[12, 6]} justify="space-between">
        <Col xs={24} sm={12}>
          <Form.Item
            label="Тип техники"
            name="machineType"
            rules={[{ required: true, message: 'Введите тип техники' }]}
          >
            {/* <Input /> */}
            <AutoComplete
              options={[...new Set(brandModel.map(el => el?.machineType))].filter(el => el).map(el => ({ value: el })).sort((a, b) => { return a.value.localeCompare(b.value) })}
              allowClear
              showSearch

              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Категория"
            name="category"
            rules={[{ required: true, message: 'Введите категорию техники' }]}
          >
            {/* <Input /> */}
            <AutoComplete
              options={[...new Set(brandModel.map(el => el?.category))].filter(el => el).map(el => ({ value: el })).sort((a, b) => { return a.value.localeCompare(b.value) })}

              allowClear

              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[12, 6]} >
        <Col xs={24} sm={11}>
          <Form.Item
            label="Сер.номер"
            name="serialNumber"
            rules={[{ required: false, message: 'Пожалуйста, введите серийный номер' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={12} sm={4}>
          <Form.Item
            label="Гар.номер"
            name="garageNumber"
            width={'100%'}
            rules={[{ required: false, message: 'Введите гар.номер' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={12} sm={9}>
          <Form.Item
            label="Год выпуска"
            name="year"
            width={'100%'}
            rules={[{ required: false, message: 'Введите год выпуска' }]}
          >
            <InputNumber />
          </Form.Item>
        </Col>
        {/* <Col xs={12} sm={5}>
          <Form.Item
            label="Дата запуска"
            name="delivery"
            rules={[{ required: false, message: 'Введите дату ввода машиины в жксплуатацию' }]}
          >
            <DatePicker
              format={'DD.MM.YYYY'}
              popupStyle={{ width: '290px' }} />
          </Form.Item>
        </Col> */}
      </Row>
      {!edValues?._id ? (
        <Form.Item
          label="Владелец"
          name="owner"

          rules={[{ required: true, message: 'Укажите владельца техники' }]}
        >

          <Select
            allowClear
            showSearch
            filterOption={(inputValue, option) => {
              let val = JSON.stringify(option?.children)
              return val.toLowerCase().includes(inputValue?.toLowerCase())
            }}
            optionLabelProp="children"
            disabled={forma === 'TaskForm' ? true : false}>
            {select.map(
              customer => (
                <Option key={customer.value} value={customer.value}>{customer?.label}</Option>
              )
            )}
          </Select>


        </Form.Item>
      ) : (
        <Form.Item
          label="Владелец"
          name="owner"
          rules={[{ required: true, message: 'Укажите владельца техники' }]}
        >
          <Space.Compact style={{ width: '100%' }}>
            <Input
              readOnly
              value={edValues.owner.name ? edValues.owner.name : ''}
              id={edValues.owner._id}
            />
            <Button type="primary" onClick={() => setIsModalVisibleOwn(true)}>
              Изменить
            </Button>
          </Space.Compact>
        </Form.Item>
      )}
      <Form.Item
        label="Локация техники"
        name={"location"}
      >
        <Input placeholder='Введите местонахождение техники' />
      </Form.Item>
      <Divider />
      {/* {version !== 'newmachinetask' ? ( */}
      <Form.Item>
        <Space size={'middle'} style={{ paddingBottom: '1rem' }}>
          <Text strong>Наработка техники</Text>
          <Button onClick={handleAddRow} style={{ marginBottom: 0 }}>+ Добавить</Button>
        </Space>

        {operationHours?.length > 0 && <Table
          size='small'
          columns={columns}
          dataSource={
            operationHours.map((el, n) => (
              {
                key: n,
                date: (new Date(el.date)).toLocaleDateString('ru-RU'),
                hours: el.hours,
                document: el?.document
              }))
          } //edValues?.op
          pagination={false}
          scroll={{
            y: 200,
          }}
        />
        }

      </Form.Item>
      {/* )
        : (<></>
          // <Form.Item
          //   name='operationHoursNew'
          //   label='Счетчик моточасов'
          //   rules={[{ required: true, message: 'Введите показания счетчика моточасов' }]}
          // >
          //   <Input type="number" style={{ width: '150px' }} min={1} />
          // </Form.Item>
        )
      } */}

      <Divider />


      <Modal
        title="Добавить наработку"
        open={isModalVisible}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
      // onOk={handleModalSubmit}
      // initialValues = {modalData}
      >
        <Form form={formd} layout="horizontal" name="formModal" onFinish={handleModalSubmit}
          style={{
            width: '100%',
            height: '100%',
            paddingTop: '1rem',
            paddingBottom: 0
          }}
        >
          <Form.Item
            label="Дата"
            name="date"
            initialValue={modalData.date}
            rules={[{ required: true, message: 'Введите дату, когда зафиксированы моточасы' }]}
          >
            <DatePicker
              format={'DD.MM.YYYY'}
              popupStyle={{ maxWidth: '200px' }} />
          </Form.Item>
          <Form.Item
            label="Моточасы"
            name="hours"
            initialValue={modalData.hours}
            rules={[{ required: true, message: 'Введите покаазания счетчика моточасов' }]}
          >
            <Input type="number" min={1} />
          </Form.Item>
          <Form.Item style={{ textAlign: 'end' }}>
            <Button type="primary" htmlType="submit" >Записать</Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Выберите нового владельца"
        open={isModalVisibleOwn}
        onCancel={() => setIsModalVisibleOwn(false)}
        footer={null}
      >
        <Form form={formown} layout="vertical">
          <Form.Item
            label="Новый владелец"
            name="newOwner"
            rules={[{ required: true, message: 'Выберите нового владельца техники' }]}
          >

            <Select
              allowClear
              showSearch
              filterOption={(inputValue, option) =>
                option.label.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
              }
              onChange={handleOwnerChange}
              options={select}
              placeholder="Поиск по строке. Наименование или ИНН"
            >
              {select.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

        </Form>
      </Modal>
      <Form.Item>
        {edValues?._id
          ? <Button type="primary" htmlType="submit" >Обновить</Button>
          : <Button type="primary" htmlType="submit" >Записать</Button>
        }
      </Form.Item>
    </Form>
  )
}


