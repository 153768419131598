import React, { useEffect, useState, useContext, useCallback } from "react";
import { DatePicker, Table, message } from "antd";
import { useHttp } from "../../hooks/http.hook";
import { AuthContext } from "../../context/AuthContext";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

const TaskReportPeriod = () => {
  const [period, setPeriod] = useState([dayjs().startOf('month'), dayjs().endOf('month')]);
  const [report, setReport] = useState([]);
  const { request } = useHttp();
  const { token } = useContext(AuthContext);

  // API Call to fetch report data
  const fetchPeriodReport = useCallback(
    async (from, to) => {
      try {
        const reportData = await request(
          "o/api/report/tasksatperiod", // Corrected API endpoint path
          "POST",
          { from, to },
          { Authorization: `Bearer ${token}` }
        );
        setReport(reportData);
      } catch (error) {
        message.error("Error fetching tasks, please try again later.");
        console.error("Error fetching tasks:", error);
      }
    },
    [request, token]
  );

  // Function to determine and fetch data for the default or selected period
  const showPeriod = useCallback(
    (range) => {
      let startDate, finishDate;

      if (Array.isArray(range) && range.length === 2 && range[0] && range[1]) {
        // Ensure valid date selection
        [startDate, finishDate] = range.map(date => date.toISOString()); // Ensure ISO format for API
        setPeriod([dayjs(startDate), dayjs(finishDate)]); // Set the period state correctly
      } else {
        // Default to the current month if no date range is provided
        startDate = dayjs().startOf('month').toISOString();
        finishDate = dayjs().endOf('month').toISOString();
        setPeriod([dayjs(startDate), dayjs(finishDate)]);
      }

      fetchPeriodReport(startDate, finishDate);
    },
    [fetchPeriodReport]
  );

  // Fetch data for the default period on component mount
  useEffect(() => {
    showPeriod(period);
  }, [showPeriod]);

  // Utility to calculate column totals
  const calculateColumnTotal = (data, dataIndex) =>
    data.reduce((total, item) => total + (item[dataIndex] || 0), 0);

  // Table column configuration
  const columns = [
    {
      title: "#",
      key: "num",
      width: "1%",
      render: (_, __, index) => <div>{index + 1}</div>,
    },
    {
      title: "Вид задания",
      dataIndex: "taskType",
      key: "taskType",
    },
    {
      title: "Создано",
      dataIndex: "created",
      key: "created",
      width: "15%",
      sorter: (a, b) => a.created - b.created,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Запланировано",
      dataIndex: "planned",
      key: "planned",
      width: "15%",
    },
    {
      title: "Назначено",
      dataIndex: "assigned",
      key: "assigned",
      width: "15%",
    },
    {
      title: "В работе",
      dataIndex: "inProgress",
      key: "inProgress",
      width: "15%",
      sorter: (a, b) => a.inProgress - b.inProgress,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Выполнено",
      dataIndex: "completed",
      key: "completed",
      width: "15%",
      sorter: (a, b) => a.completed - b.completed,
      sortDirections: ["ascend", "descend"],
    },
  ];

  // Calculating totals for the summary row
  const columnTotal = columns.reduce((acc, column) => {
    if (column.dataIndex) {
      acc[column.dataIndex] = calculateColumnTotal(report, column.dataIndex);
    }
    return acc;
  }, {});

  return (
    <div style={{ padding: "16px" }}>
      <div style={{ marginBottom: "1rem" }}>
        <RangePicker
          format="DD.MM.YYYY"
          style={{ maxWidth: "300px" }}
          onChange={showPeriod}
          value={period}
        />
      </div>
      <Table
        dataSource={report}
        columns={columns}
        size="small"
        rowKey="taskType"
        pagination={false}
        scroll={{ x: 600 }}
        style={{ maxWidth: "900px", paddingBottom: "2rem" }}
        summary={() => (
          <Table.Summary.Row style={{ backgroundColor: "#ededed" }}>
            <Table.Summary.Cell />
            <Table.Summary.Cell>
              <b>ИТОГО</b>
            </Table.Summary.Cell>
            {columns.slice(2).map((col) => (
              <Table.Summary.Cell key={col.key}>
                <b>{columnTotal[col.dataIndex] || 0}</b>
              </Table.Summary.Cell>
            ))}
          </Table.Summary.Row>
        )}
      />
    </div>
  );
};

export default TaskReportPeriod;



// import React, { useEffect, useState, useContext } from "react";
// import { Button, DatePicker, Flex, Space, Table, Typography } from "antd";
// import { useHttp } from "../../hooks/http.hook";
// import { AuthContext } from "../../context/AuthContext";

// const TaskReportPeriod = () => {
//   const [tasks, setTasks] = useState([]);
//   const [report, setReport] = useState([]);
//   const { request } = useHttp();
//   const { token } = useContext(AuthContext);


//   const fetchPeriodReport = async (from, to) => {
//     try {
//       const reportData = await request(
//         "o/api/report/tasksatperiod",
//         "POST",
//         { from, to },
//         {
//           Authorization: `Bearer ${token}`,
//         }
//       );
//       setReport(reportData);

//     } catch (error) {
//       console.error("Error fetching tasks:", error);
//     }
//   };


//   useEffect(() => {
//     showPeriod();
//   }, [])

//   const showPeriod = (el) => {
//     let startDate, finishDate
//     if (Array.isArray(el)) {
//       startDate = el[0] ? new Date(el[0]) : new Date('2000-01-01');
//       finishDate = el[1] ? new Date(el[1]) : new Date();
//     } else {
//       let date = new Date();
//       startDate = new Date(date.getFullYear(), date.getMonth(), 1);
//       finishDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
//     }

//     fetchPeriodReport(startDate, finishDate);
//   }

//   // const columns2 = [
//   //   {
//   //     title: "#",
//   //     width: "1%",
//   //     key: "num",
//   //     render: (_, r, index) => <div>{index + 1}</div>,
//   //   },
//   //   {
//   //     title: "Вид задания",
//   //     dataIndex: "taskTitle",
//   //     key: "taskTitle",
//   //   },
//   //   {
//   //     title: "Клиент",
//   //     dataIndex: ["customer", "name"],
//   //     key: "customer",
//   //   },
//   //   {
//   //     title: "Техника",
//   //     dataIndex: ["machine", "brand"],
//   //     key: "machine",
//   //   },
//   // ];

//   // Sample data
//   // const data = [
//   //   { taskType: 'Дефектовка', planned: 5, inProgress: 10, completed: 15 },
//   //   { taskType: 'Ремонт', planned: 8, inProgress: 12, completed: 20 },
//   //   { taskType: 'Техническое обслуживание', planned: 15, inProgress: 2, completed: 15 },
//   //   { taskType: 'Осмотр', planned: 7, inProgress: 2, assigned: 6, completed: 2 },
//   //   { taskType: 'Монтаж', planned: 14, inProgress: 12, completed: 5 },
//   //   { taskType: 'Подключение', planned: 3, inProgress: 1, assigned: 3, completed: 22 },
//   //   { taskType: 'Диагностика', planned: 12, inProgress: 5, completed: 4 },
//   // Add more sample data here
//   // ];

//   // const data2 = [{ "taskType": "Монтаж", "created": 3, "planned": 0, "inProgress": 0, "completed": 1, "assigned": 0 },
//   // { "taskType": "Диагностика", "created": 2, "planned": 0, "inProgress": 1, "completed": 1, "assigned": 0 },
//   // { "taskType": "Подключение", "created": 1, "planned": 0, "inProgress": 0, "completed": 1, "assigned": 1 },
//   // { "taskType": "Техническое обслуживание", "created": 2, "planned": 1, "inProgress": 1, "completed": 0, "assigned": 0 }]

//   const calculateColumnTotal = (data, dataIndex) => {
//     let total = 0;
//     data.forEach(item => {
//       total += item[dataIndex] || 0;
//     });
//     return total;
//   };

//   // Columns configuration
//   const columns = [
//     {
//       title: "#",
//       width: "1%",
//       key: "num",
//       render: (_, r, index) => <div>{index + 1}</div>,
//       // footer: (data) => {
//       //   return <div>ИТОГО</div>
//       // }
//     },
//     {
//       title: 'Вид задания',
//       dataIndex: 'taskType',
//       key: 'taskType',

//     },
//     {
//       title: 'Создано',
//       dataIndex: 'created',
//       key: 'created',
//       width: '15%',
//       sorter: (a, b) => +a.created - +b.created,
//       sortDirections: ['ascend', 'descend'],
//       footer: (data) => {
//         return <div>{data.reduce((a, b) => a + b, 0)}</div>
//       }
//     },
//     {
//       title: 'Запланировано',
//       dataIndex: 'planned',
//       key: 'planned',
//       width: '15%'
//     },
//     {
//       title: 'Назначено',
//       dataIndex: 'assigned',
//       key: 'assigned',
//       width: '15%'
//     },
//     {
//       title: 'В работе',
//       dataIndex: 'inProgress',
//       key: 'inProgress',
//       width: '15%',
//       sorter: (a, b) => +a.inProgress - +b.inProgress,
//       sortDirections: ['ascend', 'descend']
//     },
//     {
//       title: 'Выполнено',
//       dataIndex: 'completed',
//       key: 'completed',
//       width: '15%',
//       sorter: (a, b) => +a.completed - +b.completed,
//       sortDirections: ['ascend', 'descend'],
//     },
//     // Add more columns here if needed
//   ];

//   const columnTotal = {};
//   columns.forEach(column => {
//     columnTotal[column.key] = calculateColumnTotal(report, column.dataIndex);
//   });


//   return (
//     <>
//       <Flex gap='middle'>
//         <DatePicker.RangePicker
//           format={'DD.MM.YYYY'}
//           popupStyle={{ maxWidth: '200px' }}
//           style={{ marginBottom: '1rem' }}
//           label="Выберите период"
//           popupClassName='rangeDatePicker'
//           onChange={showPeriod}
//         />
//         {/* <Button type="primary" onClick={showPeriod}>Показать</Button> */}
//       </Flex>
//       <Table
//         dataSource={report}
//         columns={columns}
//         size="small"
//         rowKey={"taskType"}
//         pagination={false}
//         scroll={{ x: 600 }}
//         style={{ 'maxWidth': '900px', paddingBottom: '2rem', textAlign: 'left' }}
//         summary={(pageData) => {
//           return (
//             <>
//               <Table.Summary.Row style={{ backgroundColor: '#ededed' }}>
//                 <Table.Summary.Cell />
//                 <Table.Summary.Cell><b></b></Table.Summary.Cell>
//                 <Table.Summary.Cell><b>{columnTotal.created}</b></Table.Summary.Cell>
//                 <Table.Summary.Cell><b>{columnTotal.planned}</b></Table.Summary.Cell>
//                 <Table.Summary.Cell><b>{columnTotal.assigned}</b></Table.Summary.Cell>
//                 <Table.Summary.Cell><b>{columnTotal.inProgress}</b></Table.Summary.Cell>
//                 <Table.Summary.Cell><b>{columnTotal.completed}</b></Table.Summary.Cell>
//               </Table.Summary.Row>
//             </>
//           );
//         }}
//       />

//       {/* <Table dataSource={data2}
//         columns={columns.map(column => ({ ...column, }))}
//         size="small" rowKey={"taskType"} pagination={false}
//         scroll={{ x: 600 }}
//         style={{ 'maxWidth': '900px', paddingBottom: '2rem', textAlign: 'center' }}
//         className="tableAlignCenter"
//         summary={(pageData) => {
//           let acreated = 0, aplanned = 0, aassigned = 0, ainProgress = 0, acompleted = 0, adone = 0
//           pageData?.forEach(
//             ({ created, planned, assigned, inProgress, completed, done }) => {
//               acreated += created
//               aplanned += planned
//               aassigned += assigned
//               ainProgress += inProgress
//               acompleted += completed
//               adone += done
//             });
//           return (<>
//             <Table.Summary.Row style={{ backgroundColor: '#ededed' }}>
//               <Table.Summary.Cell />
//               <Table.Summary.Cell ><b></b></Table.Summary.Cell>
//               <Table.Summary.Cell ><b>{acreated}</b></Table.Summary.Cell>
//               <Table.Summary.Cell ><b>{aplanned}</b></Table.Summary.Cell>
//               <Table.Summary.Cell ><b>{aassigned}</b></Table.Summary.Cell>
//               <Table.Summary.Cell ><b>{ainProgress}</b></Table.Summary.Cell>
//               <Table.Summary.Cell ><b>{acompleted}</b></Table.Summary.Cell>
//             </Table.Summary.Row>
//           </>);
//         }}

//       /> */}
//       {/* <Table dataSource={tasks} columns={columns} size="small" rowKey={"_id"} /> */}
//     </>
//   )
// };

// export default TaskReportPeriod;
