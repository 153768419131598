import React, { useContext, useEffect, useState } from 'react'
import { Flex, Space, DatePicker } from 'antd'
import { ForecastList } from '../../components/Forecast/ForecastList'
import { useHttp } from '../../hooks/http.hook'
import { AuthContext } from '../../context/AuthContext'
import { DataContext } from '../../context/DataContext'

export const ForecastPage = () => {
    const [fsct, setFsct] = useState([])
    const { token } = useContext(AuthContext)
    const { request, loading } = useHttp()
    const { settings } = useContext(DataContext)




    useEffect(() => {
        const fetchForecast = async () => {
            try {
                const response = await request(`/o/api/forecastitems`, 'GET', null, {
                    Authorization: `Bearer ${token}`
                })
                setFsct(response)
            } catch (e) { }
        }
        fetchForecast()
    }, [])

    return (
        <>
            <div>
                <Flex wrap="wrap" gap="small" align='center' justify='space-between' style={{ marginBottom: '16px' }}>
                    <Space align='baseline' size={'middle'} >
                        <h2 style={{ marginBottom: 0, fontWeight: 700, marginTop: 0 }}>Планирование работ</h2>

                    </Space>
                    {/* <Space align='baseline' size={'small'} justify={'end'} >
                        <p>Период: </p>
                        <DatePicker.RangePicker
                            format={'DD.MM.YYYY'}
                            popupStyle={{ width: '290px' }}
                            style={{ marginTop: '1rem' }}
                            // onChange={handlePeriodFilter}
                            popupClassName='rangeDatePicker'
                        // style={{ marginBottom: '1rem' }}
                        />
                    </Space> */}
                    <div style={{ fontWeight: 400 }}>на период {settings.forecastDays} дн.</div>
                </Flex>
                <ForecastList forecastTasks={fsct} loading={loading} />
            </div >


        </>
    )
}