// import React, { useState } from "react";
// import { NavLink, BrowserRouter as MyRouter } from "react-router-dom";
// import { useRoutes } from "./routes.js";
// import { useAuth } from "./hooks/auth.hook.js";
// import { AuthContext } from "./context/AuthContext.js";
// import { DataContext } from "./context/DataContext.js";
// import { Navbar } from "./components/Navbar";
// import { Loader } from "./components/Loader";
// import { ConfigProvider, Layout, Menu, Grid } from "antd";
// import { DatabaseOutlined, ProfileFilled, AlignLeftOutlined, UserOutlined, FileProtectOutlined, CalendarOutlined, FormOutlined } from "@ant-design/icons";
// import locale from "antd/locale/ru_RU";
// import './App.css'
// import { theme } from 'antd';
// import { useLoadData } from "./hooks/load.hook.js";
// import "dayjs/locale/ru";

// const { Content, Sider } = Layout;
// const { useBreakpoint } = Grid;

// function App() {
//   const [collapsed, setCollapsed] = useState(false);
//   const { login, logout, token, userId, ready, role, checkRole } = useAuth();

//   const isAuth = !!token || false;
//   const routes = useRoutes(isAuth);
//   const isMobile = useBreakpoint().xs;

//   const { settings, changeSettings, customers, firstload, contracts, addCustomer, updateCustomer, deleteCustomer, addContract, updateContract, deleteContract } = useLoadData(token);
//   const { branches, loadCustomers, machines, loadContracts } = useLoadData(token);


//   const {
//     token: { colorBgContainer, borderRadiusLG },
//   } = theme.useToken();


//   const menuItems = [
//     checkRole(['admin', 'manager']) && { //(role.includes("admin") || role.includes("manager")) &&
//       key: "tasks",
//       label: "Задания",
//       icon: <ProfileFilled />,
//       children: [
//         {
//           key: "plan",
//           label: <NavLink to="/tasks"> Запланированные задания </NavLink>,
//         },
//         {
//           key: "actual",
//           label: <NavLink to="/taskssent">Выданные задания</NavLink>,
//         },
//         {
//           key: "finished",
//           label: <NavLink to="/tasksdone">Выполненные задания</NavLink>,
//         },
//         {
//           key: "finishedwoph",
//           label: <NavLink to="/nodocs"> Задания без фотографий </NavLink>,
//         },
//         {
//           key: "alltasks",
//           label: <NavLink to="/alltasks"> Все задания</NavLink>,
//         },
//       ],
//     },
//     checkRole(['user']) && {
//       key: "mytasks",
//       label: "Мои задания",
//       icon: <AlignLeftOutlined />,
//       children: [
//         // {
//         //   key: "myplan",
//         //   label: <NavLink to="/myplan"> Мои запланированные задания </NavLink>,
//         // },
//         {
//           key: "actual",
//           label: <NavLink to="/mytaskssent">Выданные задания</NavLink>,
//         },
//         {
//           key: "finished",
//           label: <NavLink to="/mytasksdone">Мои завершенные задания </NavLink>,
//         },
//         {
//           key: "nodocs",
//           label: <NavLink to="/nodocs"> Задания без фотографий </NavLink>,
//         },
//         {
//           key: "myalltasks",
//           label: <NavLink to="/myalltasks"> Все задания </NavLink>,
//         },
//       ]
//     },
//     {
//       key: "passport",
//       label: <NavLink to="/passports"> Паспорт оборудования </NavLink>,
//       icon: <FileProtectOutlined />,
//     },

//     checkRole(['admin', 'manager']) && { //
//       key: "actualdata",
//       label: <NavLink to="/actual"> Актуализация данных</NavLink>,
//       icon: <FormOutlined />

//     },
//     checkRole(['admin', 'manager']) && { //
//       key: "forecast",
//       label: <NavLink to="/forecast"> Планирование работ</NavLink>,
//       icon: <CalendarOutlined />

//     },
//     checkRole(['admin']) && { //
//       key: "users",
//       label: <NavLink to="/users"> Пользователи </NavLink>,
//       icon: <UserOutlined />

//     },
//     {
//       key: "dictionary",
//       label: "Справочник",
//       icon: <DatabaseOutlined />,
//       children: [
//         checkRole(['admin', 'manager']) && {
//           key: "customer",
//           label: <NavLink to="/customers"> Клиенты </NavLink>,
//         },
//         checkRole(['admin', 'manager']) && {
//           key: "machines",
//           label: <NavLink to="/machines"> Техника </NavLink>,
//         },
//         checkRole(['admin', 'manager']) && {
//           key: "contracts",
//           label: <NavLink to="/contracts"> Договоры </NavLink>,
//         },
//         checkRole(['admin', 'manager']) && {
//           key: "workers",
//           label: <NavLink to="/workers"> Исполнители </NavLink>,
//         },
//         {
//           key: "equipment",
//           label: <NavLink to="/equipment"> Комплектующие </NavLink>,
//         },
//         {
//           key: "servicecars",
//           label: <NavLink to="/servicecars"> Сервисные автомобили </NavLink>,
//         },
//       ],
//     },
//   ];

//   if (!ready) {
//     return <Loader size="large" />;
//   }
//   const clickMenu = (ev) => {
//     isMobile && setCollapsed(true)
//   };


//   if (!isAuth) {
//     return (
//       <AuthContext.Provider value={{ token, login, logout, userId, isAuth, role, checkRole }}>
//         <MyRouter>
//           {routes}
//         </MyRouter>
//       </AuthContext.Provider>
//     )
//   }

//   // if (firstload) {
//   //   return (
//   //     <div style={{
//   //       display: 'flex',
//   //       justifyContent: 'center',
//   //       alignItems: 'center',
//   //       height: '100vh',
//   //       width: '100vw',
//   //       backgroundColor: colorBgContainer,
//   //       borderRadius: borderRadiusLG,

//   //     }}>
//   //       <Loader />
//   //     </div>
//   //   )
//   // }


//   return (
//     <AuthContext.Provider value={{ token, login, logout, userId, isAuth, role, checkRole }}>
//       <DataContext.Provider value={{ contracts, customers, branches, machines, settings, loadCustomers, loadContracts, addCustomer, updateCustomer, deleteCustomer, addContract, updateContract, deleteContract, changeSettings }}>
//         <ConfigProvider locale={locale}>
//           <MyRouter>
//             <Layout style={{
//               maxHeight: "calc(100% - 64px)",
//               height: "100vh",
//               width: "100vw",
//               overflow: 'auto',
//             }}>
//               <Layout.Header style={{
//                 padding: 0,
//                 background: colorBgContainer,
//               }}>
//                 {isAuth && <Navbar collapsed={collapsed} onCollapse={(value) => setCollapsed(pre => !pre)} />}
//               </Layout.Header>
//               <Layout >
//                 {isAuth && (
//                   <Sider collapsedWidth={0}
//                     width={280}
//                     zeroWidthTriggerStyle={{ display: 'none' }}
//                     collapsed={isMobile ? collapsed : false}
//                     defaultCollapsed={isMobile ? collapsed : false}
//                     onCollapse={() => setCollapsed(pre => !pre)}
//                     style={{
//                       position: 'sticky',
//                       top: 64,
//                       height: '100%',
//                       zIndex: 20,
//                       // boxShadow: 'rgb(106 106 106 / 30%) 5px 0px 3px',
//                     }}

//                   >
//                     <div className="demo-logo-vertical" />
//                     <Menu theme="dark" defaultSelectedKeys={['1']}
//                       mode="inline"
//                       items={menuItems}
//                       style={{ position: 'sticky', paddingBottom: '24px' }}
//                       onClick={clickMenu}
//                     />

//                     {/* <div
//                       style={{
//                         color: 'white',
//                         backgroundColor: 'rgb(5, 66, 150)',
//                         position: 'fixed',
//                         bottom: 0,
//                         padding: '5px',
//                         width: "100%",
//                       }}
//                       value={`Версия: ${12}`}
//                     > (C) {new Date().getFullYear()} Пожтехпром</div> */}
//                   </Sider>)}
//                 <Content style={{
//                   padding: "10px 20px 20px 20px",
//                   // position: 'sticky',//isMobile ? 'relative' : 'fixed',
//                   left: isMobile ? '0' : '280px',
//                   width: '-webkit-fill-available',
//                   zIndex: 15,
//                   top: 64,
//                   bottom: 0,

//                   position: 'fixed',
//                   overflowY: 'scroll',
//                   overflowX: 'hidden',
//                 }}
//                 >
//                   {routes}
//                 </Content>
//               </Layout>
//             </Layout>
//           </MyRouter>
//         </ConfigProvider>
//       </DataContext.Provider>
//       {/* {!isAuth && routes} */}
//     </AuthContext.Provider >
//   );
// }

// export default App;





//corrected code

import React, { useState, useContext, useEffect } from "react";
import { NavLink, BrowserRouter as MyRouter } from "react-router-dom";
import { useClientRoutes, useRoutes } from "./routes.js";
import { useAuth } from "./hooks/auth.hook.js";
import { AuthContext } from "./context/AuthContext.js";
import { DataContext } from "./context/DataContext.js";
import { Navbar } from "./components/Navbar.js";
import { Loader } from "./components/Loader.js";
import { Flame } from "./components/Flame.js";
import { ConfigProvider, Layout, Menu, Grid, Flex } from "antd";
import { DatabaseOutlined, ProfileFilled, AlignLeftOutlined, UserOutlined, FileProtectOutlined, CalendarOutlined, FormOutlined } from "@ant-design/icons";
import locale from "antd/locale/ru_RU";
import './App.css';
import { theme } from 'antd';
import { useLoadData } from "./hooks/load.hook.js";
import "dayjs/locale/ru";
import { HelpProvider } from "./context/HelpContext.js";

const { Content, Sider } = Layout;
const { useBreakpoint } = Grid;



const App = () => {
  const [collapsed, setCollapsed] = useState(true);
  const { login, logout, token, userId, ready, role, checkRole, company } = useAuth();
  // const [isAuth, setIsAuth] = useState(!!token)

  // useEffect(() => {
  //   setIsAuth(!!token)
  // }, [token])
  const isAuth = !!token;
  const routes = useRoutes(isAuth) // && !checkRole(['customer']));
  const routesClient = useClientRoutes(isAuth)// && checkRole(['customer']))
  const isMobile = useBreakpoint().xs;

  const { settings, changeSettings, customers, firstload, contracts, loadContracts, addCustomer, updateCustomer, deleteCustomer, addContract, updateContract, deleteContract } = useLoadData(token, checkRole(['customer']));
  const { branches, loadCustomers, machines, } = useLoadData(token, checkRole(['customer']));





  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const menuItems = [
    checkRole(['admin', 'manager']) && {
      key: "tasks",
      label: "Задания",
      icon: <ProfileFilled />,
      children: [
        { key: "plan", label: <NavLink to="/tasks"> Запланированные задания </NavLink> },
        { key: "actual", label: <NavLink to="/taskssent">Выданные задания</NavLink> },
        { key: "finished", label: <NavLink to="/tasksdone">Выполненные задания</NavLink> },
        { key: "finishedwoph", label: <NavLink to="/nodocs"> Задания без фотографий </NavLink> },
        { key: "alltasks", label: <NavLink to="/alltasks"> Все задания</NavLink> },
      ],
    },
    checkRole(['user']) && {
      key: "mytasks",
      label: "Мои задания",
      icon: <AlignLeftOutlined />,
      children: [
        { key: "actual", label: <NavLink to="/mytaskssent">Выданные задания</NavLink> },
        { key: "finished", label: <NavLink to="/mytasksdone">Мои завершенные задания </NavLink> },
        { key: "nodocs", label: <NavLink to="/nodocs"> Задания без фотографий </NavLink> },
        { key: "myalltasks", label: <NavLink to="/myalltasks"> Все задания </NavLink> },
      ],
    },
    { key: "passport", label: <NavLink to="/passports"> Паспорт оборудования </NavLink>, icon: <FileProtectOutlined /> },
    checkRole(['admin', 'manager']) && {
      key: "actualdata",
      label: <NavLink to="/actual"> Актуализация данных</NavLink>,
      icon: <FormOutlined />,
    },
    checkRole(['admin', 'manager']) && {
      key: "forecast",
      label:
        <Flex
          align="center"
        ><NavLink to="/forecast"> Планирование работ</NavLink>
          {settings?.forecast > 0 && <>
            <Flame style={{ width: 45, height: 45 }} />
            <p style={{ marginLeft: '-11px', fontWeight: 700 }}>{settings.forecast}</p>
          </>}
        </Flex>,
      icon: <CalendarOutlined />,
    },
    checkRole(['admin']) && {
      key: "users",
      label: <NavLink to="/users"> Пользователи </NavLink>,
      icon: <UserOutlined />,
    },
    {
      key: "dictionary",
      label: "Справочник",
      icon: <DatabaseOutlined />,
      children: [
        checkRole(['admin', 'manager']) && { key: "customer", label: <NavLink to="/customers"> Клиенты </NavLink> },
        checkRole(['admin', 'manager']) && { key: "machines", label: <NavLink to="/machines"> Техника </NavLink> },
        checkRole(['admin', 'manager']) && { key: "contracts", label: <NavLink to="/contracts"> Договоры </NavLink> },
        checkRole(['admin', 'manager']) && { key: "workers", label: <NavLink to="/workers"> Исполнители </NavLink> },
        { key: "equipment", label: <NavLink to="/equipment"> Комплектующие </NavLink> },
        { key: "servicecars", label: <NavLink to="/servicecars"> Сервисные автомобили </NavLink> },
      ],
    },
  ].filter(Boolean);

  if (!ready) {
    return <Loader size="large" />;
  }

  const clickMenu = () => {
    if (isMobile) setCollapsed(true);
  };

  if (!isAuth) {
    return (
      <AuthContext.Provider value={{ token, login, logout, userId, isAuth, role, checkRole }}>
        <MyRouter>
          {routes}
        </MyRouter>
      </AuthContext.Provider>
    );
  }

  // if (isAuth && checkRole(['customer'])) {
  //   return (
  //     <AuthContext.Provider value={{ token, login, logout, userId, isAuth, role, checkRole, company }}>
  //       <ConfigProvider locale={locale}>
  //         <MyRouter>
  //           {routesClient}
  //         </MyRouter>
  //       </ConfigProvider>
  //     </AuthContext.Provider>
  //   );
  // }

  // if (isAuth && !checkRole(['customer'])) {

  if (isAuth) {
    return (
      <AuthContext.Provider value={{ token, login, logout, userId, role, isAuth, checkRole, company }}>
        <DataContext.Provider value={{ contracts, customers, branches, machines, settings, loadCustomers, loadContracts, addCustomer, updateCustomer, deleteCustomer, addContract, updateContract, deleteContract, changeSettings }}>
          <ConfigProvider locale={locale}>
            <MyRouter>
              {isAuth && !checkRole(['customer']) ?
                <HelpProvider>
                  <Layout
                    style={{
                      position: 'fixed',
                      height: '-webkit-fill-available',
                      width: "100%",
                      overflow: 'auto',

                    }}
                  >
                    <Layout.Header style={{ padding: 0, background: colorBgContainer }}>
                      {isAuth && <Navbar collapsed={collapsed} onCollapse={() => setCollapsed(!collapsed)} />}
                    </Layout.Header>
                    <Layout >
                      {isAuth && (
                        <Sider
                          collapsedWidth={0}
                          width={280}
                          zeroWidthTriggerStyle={{ display: 'none' }}
                          collapsed={isMobile ? collapsed : false}
                          onCollapse={() => setCollapsed(!collapsed)}
                          style={{ position: 'sticky', top: 64, height: '100%', zIndex: 20 }}
                        >
                          <div className="demo-logo-vertical" />
                          <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" items={menuItems}
                            style={{ position: 'sticky', paddingBottom: '24px' }}
                            onClick={clickMenu}
                          />
                        </Sider>

                      )}
                      <Content style={{ padding: "10px 20px 20px 20px", position: 'fixed', left: isMobile ? '0' : '280px', width: '-webkit-fill-available', zIndex: 15, top: 64, bottom: 0, overflowY: 'scroll', overflowX: 'hidden' }}>
                        {routes}
                      </Content>
                    </Layout>
                  </Layout>

                </HelpProvider>
                : routesClient}
            </MyRouter>
          </ConfigProvider>
        </DataContext.Provider>
      </AuthContext.Provider >

    );
  }
}


export default App;


// const OldApp = () => {
//   const { login, logout, token, userId, role, checkRole } = useAuth();
//   const [isAuth, setIsAuth] = useState(!!token)

//   useEffect(() => {
//     setIsAuth(!!token)
//   }, [token])

//   const routes = useRoutes(isAuth);
//   const routesClient = useClientRoutes(isAuth, checkRole(['customer']))
//   if (!isAuth) {
//     return (
//       <AuthContext.Provider value={{ token, login, logout, userId, isAuth, role, checkRole }}>
//         <MyRouter>
//           {routes}
//         </MyRouter>
//       </AuthContext.Provider>
//     );
//   } else if (isAuth && checkRole(['customer'])) {
//     return <ClientApp>
//       {routesClient}
//     </ClientApp>
//   }
//   else {
//     return (
//       <CompanyApp>
//         {routes}
//       </CompanyApp>
//     )
//   }

// }

// const NotAuthorized = ({ children }) => {
//   const { login, logout, token, userId, ready, role, checkRole } = useAuth();
//   const isAuth = !!token

//   return <AuthContext.Provider value={{ token, login, logout, userId, isAuth, role, checkRole }}>
//     <MyRouter>
//       {children}
//     </MyRouter>
//   </AuthContext.Provider>
// }

// const CompanyApp = ({ children }) => {
//   const [collapsed, setCollapsed] = useState(true);
//   const { login, logout, token, userId, ready, role, checkRole } = useAuth();
//   const isAuth = !!token
//   const isMobile = useBreakpoint().xs;
//   const { settings, changeSettings, customers, contracts, addCustomer, updateCustomer, deleteCustomer, addContract, updateContract, deleteContract } = useLoadData(token);
//   const { branches, loadCustomers, machines, loadContracts } = useLoadData(token);
//   const {
//     token: { colorBgContainer },
//   } = theme.useToken();


//   const menuItems = [
//     checkRole(['admin', 'manager']) && {
//       key: "tasks",
//       label: "Задания",
//       icon: <ProfileFilled />,
//       children: [
//         { key: "plan", label: <NavLink to="/tasks"> Запланированные задания </NavLink> },
//         { key: "actual", label: <NavLink to="/taskssent">Выданные задания</NavLink> },
//         { key: "finished", label: <NavLink to="/tasksdone">Выполненные задания</NavLink> },
//         { key: "finishedwoph", label: <NavLink to="/nodocs"> Задания без фотографий </NavLink> },
//         { key: "alltasks", label: <NavLink to="/alltasks"> Все задания</NavLink> },
//       ],
//     },
//     checkRole(['user']) && {
//       key: "mytasks",
//       label: "Мои задания",
//       icon: <AlignLeftOutlined />,
//       children: [
//         { key: "actual", label: <NavLink to="/mytaskssent">Выданные задания</NavLink> },
//         { key: "finished", label: <NavLink to="/mytasksdone">Мои завершенные задания </NavLink> },
//         { key: "nodocs", label: <NavLink to="/nodocs"> Задания без фотографий </NavLink> },
//         { key: "myalltasks", label: <NavLink to="/myalltasks"> Все задания </NavLink> },
//       ],
//     },
//     { key: "passport", label: <NavLink to="/passports"> Паспорт оборудования </NavLink>, icon: <FileProtectOutlined /> },
//     checkRole(['admin', 'manager']) && {
//       key: "actualdata",
//       label: <NavLink to="/actual"> Актуализация данных</NavLink>,
//       icon: <FormOutlined />,
//     },
//     checkRole(['admin', 'manager']) && {
//       key: "forecast",
//       label: <NavLink to="/forecast"> Планирование работ</NavLink>,
//       icon: <CalendarOutlined />,
//     },
//     checkRole(['admin']) && {
//       key: "users",
//       label: <NavLink to="/users"> Пользователи </NavLink>,
//       icon: <UserOutlined />,
//     },
//     {
//       key: "dictionary",
//       label: "Справочник",
//       icon: <DatabaseOutlined />,
//       children: [
//         checkRole(['admin', 'manager']) && { key: "customer", label: <NavLink to="/customers"> Клиенты </NavLink> },
//         checkRole(['admin', 'manager']) && { key: "machines", label: <NavLink to="/machines"> Техника </NavLink> },
//         checkRole(['admin', 'manager']) && { key: "contracts", label: <NavLink to="/contracts"> Договоры </NavLink> },
//         checkRole(['admin', 'manager']) && { key: "workers", label: <NavLink to="/workers"> Исполнители </NavLink> },
//         { key: "equipment", label: <NavLink to="/equipment"> Комплектующие </NavLink> },
//         { key: "servicecars", label: <NavLink to="/servicecars"> Сервисные автомобили </NavLink> },
//       ],
//     },
//   ].filter(Boolean);

//   if (!ready) {
//     return <Loader size="large" />;
//   }

//   const clickMenu = () => {
//     if (isMobile) setCollapsed(true);
//   };

//   return (
//     <AuthContext.Provider value={{ token, login, logout, userId, isAuth, role, checkRole }}>
//       <DataContext.Provider value={{ contracts, customers, branches, machines, settings, loadCustomers, loadContracts, addCustomer, updateCustomer, deleteCustomer, addContract, updateContract, deleteContract, changeSettings }}>
//         <ConfigProvider locale={locale}>
//           <MyRouter>
//             <Layout
//               style={{
//                 position: 'fixed',
//                 height: '-webkit-fill-available',
//                 width: "100%",
//                 overflow: 'auto',

//               }}
//             >
//               <Layout.Header style={{ padding: 0, background: colorBgContainer }}>
//                 {isAuth && <Navbar collapsed={collapsed} onCollapse={() => setCollapsed(!collapsed)} />}
//               </Layout.Header>
//               <Layout >
//                 {isAuth && (
//                   <Sider
//                     collapsedWidth={0}
//                     width={280}
//                     zeroWidthTriggerStyle={{ display: 'none' }}
//                     collapsed={isMobile ? collapsed : false}
//                     onCollapse={() => setCollapsed(!collapsed)}
//                     style={{ position: 'sticky', top: 64, height: '100%', zIndex: 20 }}
//                   >
//                     <div className="demo-logo-vertical" />
//                     <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" items={menuItems}
//                       style={{ position: 'sticky', paddingBottom: '24px' }}
//                       onClick={clickMenu}
//                     />
//                   </Sider>

//                 )}
//                 <Content style={{ padding: "10px 20px 20px 20px", position: 'fixed', left: isMobile ? '0' : '280px', width: '-webkit-fill-available', zIndex: 15, top: 64, bottom: 0, overflowY: 'scroll', overflowX: 'hidden' }}>
//                   {children}
//                 </Content>
//               </Layout>
//             </Layout>
//           </MyRouter>
//         </ConfigProvider>
//       </DataContext.Provider>
//     </AuthContext.Provider >
//   )



// }

// const ClientApp = ({ children }) => {
//   const { login, logout, token, userId, role, checkRole } = useAuth();
//   const isAuth = !!token
//   return (
//     <AuthContext.Provider value={{ token, login, logout, userId, isAuth, role, checkRole }}>
//       <ConfigProvider locale={locale}>
//         <MyRouter>
//           {children}
//         </MyRouter>
//       </ConfigProvider>
//     </AuthContext.Provider>
//   );
// }
