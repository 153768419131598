import React, { useContext, useEffect, useState } from 'react';
import { Form, Input, Button, message, Modal, Divider, Flex } from 'antd';
import { AuthContext } from '../../context/AuthContext';
import { useHttp } from '../../hooks/http.hook';
import { DoubleLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

export const UserAccountPage = () => {
    const { request } = useHttp();
    const { token, userId, checkRole, role } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(null);
    const [changePasswordVisible, setChangePasswordVisible] = useState(false);
    const [passwordForm] = Form.useForm();
    const navigate = useNavigate()

    useEffect(() => {
        passwordForm.setFieldsValue({
            oldPassword: '',
            newPassword: '',
            confirmPassword: ''
        })
    }, [])

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await request(`o/api/users/${userId}`, 'GET', null,
                    { Authorization: `Bearer ${token}` }
                );
                setUser(response);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        }
        fetchUser()
    }, [userId]);

    const onFinishProfile = async values => {
        setLoading(true);
        try {
            await updateUserProfile(userId, values);
            message.success('Профиль обновлён успешно');
        } catch (error) {
            console.error('Error updating profile:', error);
            message.error('Failed to update profile');
        }
        setLoading(false);
    };

    const updateUserProfile = async (userId, values) => {
        try {
            const response = await fetch(`o/api/myprofile/${userId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(values),
            });

            if (!response.ok) {
                throw new Error('Failed to update user profile');
            }

            const user = await response.json();
            return user;
        } catch (error) {
            throw error;
        }
    }

    const updateUserPassword = async (userId, oldPassword, newPassword) => {
        try {
            const response = await fetch(`o/api/myprofile/${userId}/password`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ oldPassword, newPassword }),
            });

            if (!response.ok) {
                throw new Error('Не удалось изменить пароль');
            }

            const user = await response.json();
            return user;
        } catch (error) {
            throw error;
        }

    }

    const showChangePasswordModal = () => {

        setChangePasswordVisible(true);
    };

    const handleCancelChangePassword = () => {
        passwordForm.resetFields()
        setChangePasswordVisible(false);
    };

    const onFinishChangePassword = async values => {
        setLoading(true);
        try {
            await updateUserPassword(userId, values.oldPassword, values.newPassword);
            message.success('Пароль обновлен успешно!');
            handleCancelChangePassword();
        } catch (error) {
            console.error('Error updating password:', error);
            message.error('Не удалось изменить пароль');
        }
        setLoading(false);
    };


    return (
        <div style={{ width: 'maxContent' }} >
            <Flex justify='space-between' align='center'>
                <h2>Мои данные</h2>
                <Button type='primary' onClick={() => navigate('/')}>
                    <DoubleLeftOutlined />На главную
                </Button>
            </Flex>
            {user && <><Form
                name="updateProfile"
                initialValues={{ firstname: user.firstname, lastname: user.lastname, title: user.title, branch: user.branch, email: user.email }}
                onFinish={onFinishProfile}
                style={{ width: 'auto', maxWidth: '500px' }}
            >
                <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Please input your email' }]}>
                    <Input disabled />
                </Form.Item>
                <Form.Item label="Имя" name="firstname" rules={[{ required: true, message: 'Please input your first name' }]}>
                    <Input />
                </Form.Item>
                <Form.Item label="Фамилия" name="lastname" rules={[{ required: true, message: 'Please input your last name' }]}>
                    <Input />
                </Form.Item>
                <Form.Item label="Должность" name="title" rules={[{ required: false, message: 'Please input your title' }]}>
                    <Input />
                </Form.Item>
                {!checkRole(['customer']) && <Form.Item label="Подразделение" name="branch" rules={[{ required: true, message: 'Please input your branch' }]}>
                    <Input />
                </Form.Item>}

                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Обновить профиль
                    </Button>
                </Form.Item>
            </Form>
                <Divider />

                <Button type="primary" onClick={showChangePasswordModal} >
                    Изменить пароль
                </Button>

                <Modal
                    title="Изменить пароль"
                    open={changePasswordVisible}
                    onCancel={handleCancelChangePassword}
                    footer={null}
                >
                    <Form
                        form={passwordForm}
                        name="changePassword"
                        onFinish={onFinishChangePassword}
                    >
                        <Form.Item
                            style={{ marginTop: '2rem' }}
                            label="Действующий пароль"
                            name="oldPassword"
                            rules={[{ required: true, message: 'Please input your old password' }]}
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item
                            label="Новый пароль"
                            name="newPassword"
                            rules={[{ required: true, message: 'Please input your new password' }]}
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item
                            label="Подтверждение пароля"
                            name="confirmPassword"
                            dependencies={['newPassword']}
                            rules={[
                                { required: true, message: 'Пожалуйста, подтвердите ваш новый пароль' },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('newPassword') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Пароли не совпадают'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item style={{ marginTop: '2rem' }}>
                            <Button type="primary" htmlType="submit" loading={loading}>
                                Обновить пароль
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </>}
        </div>
    );
};


