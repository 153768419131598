import { Drawer, Button, Modal } from 'antd'
import React from 'react'
import { ClientForm } from './ClientForm'
import { useForm } from 'antd/es/form/Form'



export const ClientModal = (props) => {

    const [instForm] = useForm()
    return (
        <Drawer
            open={props.open} onOk={props.onOk} size="sm"
            onClose={props.onOk}
            width={560}
            title={'Заявка на монтаж системы пожаротушения'}
            onCancel={props.onOk}
            footer={
                < Button type="primary" className='noRadius' onClick={instForm.submit}
                    style={{ height: 45 }}>
                    Отправить заявку
                </Button >
            }
        >
            <ClientForm form={instForm} onFinish={props.onFinish} />
        </Drawer >
    )

}

export const ClientPassport = props => {
    return (
        <Modal
            open={props.open} onOk={props.onOk} size="sm" scrollable
            title={'Паспорт заказчика'}
            onCancel={props.onOk}
            footer={
                < Button type="primary" className='noRadius' onClick={props.onOk}
                    style={{ height: 45 }}>
                    Закрыть
                </Button >}
        >
            Паспорт
        </Modal>

    )
}