import React from 'react'

export default function Backg({ style, fill }) {

    return < >
        <div className='parallelogram' />
        <div className='parallelogram'
            style={{ color: 'transparent', left: '36vw' }} />
        <div className='parallelogram' style={{ left: '60vw' }} />
    </>
    // <svg xmlns="http://www.w3.org/2000/svg"
    //     width={style?.width ?? "2417"}
    //     height={style?.height ?? "1317"}
    //     viewBox="0 0 2417 1317"
    //     fill={fill || 'red'}>
    //     <path d="M806.662 73.9877L660.18 220.52L1756.28 1317L1902.76 1170.47L806.662 73.9877Z" fill="white" />
    //     <path d="M146.482 0L0 146.533L1169.79 1316.72L1316.27 1170.19L146.482 0Z"
    //         fill="white" />
    //     <path d="M1393.11 73.2325L1246.62 219.765L2270.52 1244.01L2417 1097.48L1393.11 73.2325Z" fill="white" />
    // </svg>
}