import { Form, Input } from 'antd';
import React from 'react'
import InputMask from 'react-input-mask'
import MaskedInput from 'antd-mask-input'


export const ClientFormTest = (props) => {
    // const [form] = useForm()
    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const emptyFields = {
        person: '',
        phone: '',
        description: '',

    }

    const onFinish = (values) => {
        // console.log('im form Test', values)
        props.onFinish({ ...values, type: 'test' })
        props.form.setFieldsValue(emptyFields)

    }


    return (
        <div style={{
            marginTop: '0px',
        }}>

            <Form
                {...layout}
                form={props.form}
                name="control-test"
                onFinish={onFinish}
                style={{ maxWidth: 600 }}
            >

                <Form.Item name="person" label="Контактное лицо" rules={[{
                    required: true,
                    messsage: 'Введите ваше имя'
                }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="phone" label="Телефон" rules={[{
                    required: true,
                    message: 'Введите номер телефона'
                }]}>
                    <MaskedInput
                        name="formPhone"
                        value={props?.phone ?? ''}
                        mask={'+0 (000) 000-00-00'}
                    />
                    {/* <InputMask

                        autoComplete="off"
                        value={props.phone}
                        mask="+9 (999) 999-99-99"
                    >
                        {() => <Input />}
                    </InputMask> */}
                    {/* <Input /> */}
                </Form.Item>
                <Form.Item name="description" label="Информация"
                    rules={[{ required: false }]}>
                    <Input.TextArea rows={8}
                        placeholder='Можно внести дополнительную информацию для специалистов' />
                </Form.Item>
            </Form>
        </div >
    )

}