import React, { useState, useEffect, useContext } from 'react';
import { Button, Drawer, message, Modal, Flex, Space, Grid } from 'antd';
import { EquipmentForm } from '../components/Equipment/EquipmentForm';
import { EquipmentList } from '../components/Equipment/EquipmentList';
import { useHttp } from '../hooks/http.hook'
import { AuthContext } from '../context/AuthContext'
import { PlusOutlined } from '@ant-design/icons';

const { useBreakpoint } = Grid;


export const EquipmentPage = () => {
    const [visible, setVisible] = useState(false);
    const [equipments, setEquipments] = useState([]);
    const [formInitial, setFormInitial] = useState({})
    const [modalInfo, setModalInfo] = useState({})
    const [showModal, setShowModal] = useState(false)

    const { loading, request } = useHttp()
    const { token, checkRole } = useContext(AuthContext)

    const isMobile = useBreakpoint().xs

    const emptyFields = {
        partno: '',
        description: '',
        unitMeasure: '',
        comment: '',
    }

    useEffect(() => {

        const fetchEquipments = async () => {
            try {
                const response = await request('o/api/equipment', 'GET', null, {
                    Authorization: `Bearer ${token}`
                });
                setEquipments(response);
            } catch (error) {
                console.error('Error fetching equipments:', error);
            }
        };
        fetchEquipments();
    }, []);



    const handleAddEquipment = async (newEquipment) => {
        try {
            const response = await await request('o/api/equipment', 'POST', newEquipment, {
                Authorization: `Bearer ${token}`
            });
            setEquipments([...equipments, response]);
            message.success(`Добавлено оборудование: ${response.partno} ${response.description}`);
        } catch (error) {
            console.error('Ошибка добавления сотрудника:', error);
            message.error('Ошибка записи данных');
        }
    }

    const handleUpdateEquipment = async (id, updatedEquipment) => {
        try {
            const response = await request(`o/api/equipment/${id}`, 'PUT', updatedEquipment, {
                Authorization: `Bearer ${token}`
            });
            const updatedEquipments = equipments.map((eq) => (eq._id === id ? response : eq));
            setEquipments(updatedEquipments);
            message.success('Данные обновлены!');
        } catch (error) {
            console.error('Error updating worker:', error);
            message.error('Ошибка обновления данных');
        }
    }

    const handleDeleteEquipment = async (id) => {
        try {
            await request(`o/api/equipment/${id}`, 'DELETE', null, {
                Authorization: `Bearer ${token}`
            });
            setEquipments(equipments.filter((eq) => eq._id !== id));
            message.success('Запись удалена');
        } catch (error) {
            console.error('Error deleting worker:', error);
            message.error('Ошибка удаления записи');
        }
    }
    const submitForm = (formValues) => {
        if (formValues?._id) {
            handleUpdateEquipment(formValues._id, formValues)
        } else {
            handleAddEquipment(formValues)
        }

    }

    const handleEditEquip = (record) => {
        setFormInitial(record)
        showDrawer()
    }

    const onClose = () => {
        setVisible(false);
        setFormInitial(emptyFields)
    };

    const onModalView = (item) => {
        let modal = {
            title: `Карточка оборудования`,
            info: (
                <>
                    <h2 style={{ marginBottom: '8px' }}>{item.partno} {item.description}</h2>
                    <div>{item.comment}</div>
                    <br />
                    <div><span style={{ fontWeight: '500' }}>Ед.измерения: </span>{item.unitMeasure}</div>
                </>
            )
        }
        setModalInfo(modal)
        setShowModal(true)
    }

    const hideModal = () => {
        setShowModal(false)
    }

    const showDrawer = () => {
        setVisible(true);
    };

    return (
        <div >
            {/* <h2>Комплектующие</h2>
            <Button style={{ marginBottom: '16px' }} type="primary" onClick={showDrawer}>+ ДОБАВИТЬ</Button> */}

            <Flex wrap="wrap" gap="small" align='center' justify='space-between' >
                <Space align='baseline' size={'middle'} >
                    <h2 style={{ marginBottom: 0, marginTop: 0 }}>Комплектующие</h2>
                    {checkRole(['admin']) && <Button type="default"
                        onClick={showDrawer}
                        shape={isMobile ? 'circle' : ''}
                        icon={<PlusOutlined />}
                        style={{ borderRadius: '20px' }}
                    >{isMobile ? '' : 'ДОБАВИТЬ'}</Button>}
                </Space>

            </Flex>

            <Drawer
                title="Добавить элемент"
                width={560}
                open={visible}
                onClose={onClose}
            >
                <EquipmentForm onSubmit={submitForm} onClose={onClose} initialValues={formInitial} />
            </Drawer>
            <EquipmentList equipments={equipments} onEdit={handleEditEquip} onView={onModalView} onDelete={handleDeleteEquipment} loading={loading} />
            <Modal
                title={modalInfo.title}
                centered
                open={showModal}
                onOk={hideModal}
                onCancel={hideModal}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                {modalInfo.info}
            </Modal>
        </div>
    );
};
