import React from 'react'
import Backg from '../../components/Background'
import { Content, Header } from 'antd/es/layout/layout'
import Logo from '../Logo'
import { Layout } from 'antd'

export const NavigationPanel = ({ children }) => {
    return (
        <><Layout>
            <Header
                style={
                    {
                        padding: '10px',
                        display: 'flex',
                        position: 'fixed',
                        width: '100%',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        backgroundColor: 'rgb(5, 66, 150)',
                        zIndex: 50,
                    }
                }>

                <Logo style={{ width: '90px', fill: 'white', position: 'absolute' }} />
                <div className="container headerTitle"
                    style={{
                        color: 'white',
                        fontSize: 'large',
                        top: '34px',
                        zIndex: 99,

                    }}>

                </div>
                <Backg style={{
                    width: '70vw', overflow: 'none',
                }} />

            </Header>
            <Content style={{
                padding: '80px 20px 0px 20px',
                marginBottom: '3em',
                flexGrow: 1,
            }}>
                <div className='container'>
                    {children}
                </div>
            </Content>
        </Layout>
        </>
    )

}