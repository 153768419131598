import { Drawer, Button, Spin, Divider, List, Space } from 'antd'
import React, { useState, useEffect } from 'react'
import { PhotoModule } from './PhotoModule'

export const PassportDrawer = props => {
    const [openDetails, setOpenDetails] = useState(false)
    const [jobPics, setJobPics] = useState([])
    const [attachedFiles, setAttachedFiles] = useState([])

    useEffect(() => {

    }, [jobPics])

    const handleOpenDetails = (value) => {
        setJobPics(value)
        setOpenDetails(value)

    }

    return (
        <>
            <Drawer
                open={props.open} onClose={props.onOk}
                title={'Паспорт системы'}
                onCancel={props.onOk}
                width={560}
                footer={
                    < Button type="primary" className='noRadius' onClick={props.onOk}
                        style={{ height: 45 }}>
                        Закрыть
                    </Button >
                }
            >
                {
                    props.loading
                        ? <Spin spinning={true} />
                        : <>
                            <p>Тип оборудования: <b>{props?.value?.machine}</b></p>
                            <p>Договор монтажа: <b>{props?.value?.installation?.contractName}</b></p>
                            <p>Монтаж системы: {!jobPics ? <Spin loading={true} /> : <b>{new Date(props?.value?.deliveryDate).toLocaleDateString('ru-RU')}</b>}</p>
                            <p>Место монтажа: <b>{props?.value?.installation?.jobsite}</b></p>
                            <p>Окончание гарантии: <b>{new Date(props?.value?.warrantyFinishDate).toLocaleDateString('ru-RU')}</b></p>
                            <Divider />

                            <Space
                                direction='vertical'
                                size={0}
                                style={{
                                    height: 45,
                                    width: '100%',
                                    backgroundColor: '#f0f0f0',
                                    fontSize: 16,
                                    fontWeight: 700,
                                    justifyContent: 'center',
                                    paddingLeft: 10,
                                    marginTop: 10,
                                }}
                            >
                                Проведенные работы
                            </Space>
                            {props?.value?.maintenance?.length > 0 && <List
                                itemLayout="horizontal"
                                dataSource={props?.value?.maintenance}
                                renderItem={(item, index) => (
                                    <List.Item
                                        className='listItem'
                                        key={item._id}

                                    >
                                        <List.Item.Meta
                                            style={{ marginLeft: '5px' }}
                                            avatar={index + 1}
                                            title={<div>{new Date(item.finishDate).toLocaleDateString('ru-RU')} - {item.taskTitle}</div>}
                                            description={(
                                                <>
                                                    <div>Наработка: <b>{item.lastOperationHours}</b>, статус: <b>{item.status}</b></div>
                                                </>
                                            )} />
                                        <Button type="primary"
                                            onClick={() => {
                                                handleOpenDetails({ ...item, machine: props?.value?.machine })
                                                setAttachedFiles(item?.pictures || [])
                                            }
                                            }
                                            className='noRadius'
                                            style={{ marginRight: 12, height: 40 }}
                                        >
                                            Детали
                                        </Button>
                                    </List.Item>
                                )}></List >
                            }
                        </>
                }


            </Drawer >
            <Drawer open={openDetails}
                width={430}
                onClose={() => setOpenDetails(false)}
                title={'Детали'}
                destroyOnClose={true}
            >
                <>

                    <p>Тип оборудования: <b>{jobPics?.machine}</b></p>
                    <p>Вид работ: <b>{jobPics?.taskTitle}</b></p>
                    <p>Дата завершения: <b>{new Date(jobPics?.finishDate).toLocaleDateString('ru-RU')}</b> ({jobPics?.status})</p>
                    <p><u>Заключение:</u><br />{jobPics?.recommend}</p>
                    <Divider />
                    {attachedFiles?.length > 0 &&
                        <>
                            <p><b>Фотоотчет:</b></p>
                            <PhotoModule files={attachedFiles} />
                        </>}
                </>
            </Drawer>
        </>
    )
}

