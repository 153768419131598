import React, { useEffect, useState, useRef, useContext } from 'react';
import { Row, Col, Card, Statistic, Button } from 'antd';
// import { Line, Pie } from '@ant-design/charts';
import { Line, Pie, Column, DualAxes } from '@ant-design/plots';
import ReactToPrint from 'react-to-print';
import { useHttp } from '../../hooks/http.hook';
import { AuthContext } from '../../context/AuthContext';
import { forEach, groupBy } from 'lodash-es';
export const Dashboardv2 = () => {
    const printRef = useRef();
    const { request } = useHttp()
    const { token } = useContext(AuthContext)
    const [taskData, setTaskData] = useState([]);
    const [taskTypes, setTaskTypes] = useState([]);
    const [workerData, setWorkerData] = useState([]);
    const [statistics, setStatistics] = useState({});


    const annotations = [];
    const fetchPeriodReport = async (from, to) => {
        try {
            const reportData = await request(
                "o/api/report/tasksatyear",
                "POST",
                { from, to },
                {
                    Authorization: `Bearer ${token}`,
                }
            );
            //   setReport(reportData);
            setTaskData(reportData.tasks);
            let tps = []
            reportData.tasks.forEach(el => {
                if (tps.findIndex(e => e.type === el.type) >= 0) {
                    //find el
                    let ind = tps.findIndex(e => e.type === el.type)
                    tps[ind].count += el.count
                } else {
                    tps.push({ type: el.type, count: el.count })
                }
            })

            forEach(groupBy(tps, 'type'), (values, k) => {
                const value = values.reduce((a, b) => a + b.value, 0);
                annotations.push({
                    type: 'text',
                    data: [k, value],
                    xField: 'type',
                    yField: 'count',
                    style: {
                        text: `${value}`,
                        textBaseline: 'bottom',
                        position: 'top',
                        textAlign: 'center',
                    },
                    tooltip: false,
                });
            });

            setTaskTypes(tps)
            setWorkerData(reportData.persons)
        } catch (error) {
            console.error("Error fetching tasks:", error);
        }
    };

    useEffect(() => {
        // Sample task data

        let date = new Date();
        let firstDay = new Date(date.getFullYear() - 1, date.getMonth(), 1);
        fetchPeriodReport(firstDay, date)
        const tasks = [
            { month: "2023-06", type: "Installation", count: 20 },
            { month: "2023-06", type: "Maintenance", count: 15 },
            { month: "2023-07", type: "Installation", count: 25 },
            { month: "2023-07", type: "Maintenance", count: 20 },
            { month: "2023-08", type: "Installation", count: 30 },
            { month: "2023-08", type: "Maintenance", count: 25 },
            { month: "2023-09", type: "Installation", count: 35 },
            { month: "2023-09", type: "Maintenance", count: 30 },
            { month: "2023-10", type: "Installation", count: 40 },
            { month: "2023-10", type: "Maintenance", count: 35 },
            { month: "2023-11", type: "Installation", count: 45 },
            { month: "2023-11", type: "Maintenance", count: 40 },
            { month: "2023-12", type: "Installation", count: 50 },
            { month: "2023-12", type: "Maintenance", count: 45 },
            { month: "2024-01", type: "Installation", count: 55 },
            { month: "2024-01", type: "Maintenance", count: 20 },
            { month: "2024-02", type: "Installation", count: 32 },
            { month: "2024-02", type: "Maintenance", count: 55 },
            { month: "2024-03", type: "Installation", count: 65 },
            { month: "2024-03", type: "Maintenance", count: 60 },
            { month: "2024-04", type: "Installation", count: 70 },
            { month: "2024-04", type: "Maintenance", count: 65 },
            { month: "2024-05", type: "Installation", count: 75 },
            { month: "2024-05", type: "Maintenance", count: 70 }
        ];

        // Sample task types data
        const types = [
            { type: "Installation", count: 300 },
            { type: "Maintenance", count: 250 },
            { type: "Repair", count: 100 },
            { type: "Inspection", count: 50 }
        ];

        // Sample worker data
        const workers = [
            { month: "2023-06", worker: "Worker A", count: 10 },
            { month: "2023-06", worker: "Worker B", count: 15 },
            { month: "2023-07", worker: "Worker A", count: 20 },
            { month: "2023-07", worker: "Worker B", count: 25 },
            { month: "2023-08", worker: "Worker A", count: 30 },
            { month: "2023-08", worker: "Worker B", count: 35 },
            { month: "2023-09", worker: "Worker A", count: 40 },
            { month: "2023-09", worker: "Worker B", count: 45 },
            { month: "2023-10", worker: "Worker A", count: 50 },
            { month: "2023-10", worker: "Worker B", count: 55 },
            { month: "2023-11", worker: "Worker A", count: 60 },
            { month: "2023-11", worker: "Worker B", count: 65 },
            { month: "2023-12", worker: "Worker A", count: 70 },
            { month: "2023-12", worker: "Worker B", count: 75 },
            { month: "2024-01", worker: "Worker A", count: 80 },
            { month: "2024-01", worker: "Worker B", count: 85 },
            { month: "2024-02", worker: "Worker A", count: 90 },
            { month: "2024-02", worker: "Worker B", count: 95 },
            { month: "2024-03", worker: "Worker A", count: 100 },
            { month: "2024-03", worker: "Worker B", count: 105 },
            { month: "2024-04", worker: "Worker A", count: 110 },
            { month: "2024-04", worker: "Worker B", count: 115 },
            { month: "2024-05", worker: "Worker A", count: 120 },
            { month: "2024-05", worker: "Worker B", count: 125 }
        ];

        // Sample statistics data
        const stats = {
            contracts: 50,
            passports: 75,
            delayedTasks: 10,
            plannedTasks: 20
        };


        // setTaskData(tasks);
        // setTaskTypes(types);
        // setWorkerData(workers);
        // setStatistics(stats);
    }, []);

    const colorMapping = {
        'Монтаж': '#1f77b4',
        'Техническое обслуживание': '#ff7f0e',
        'Repair': '#2ca02c',
        'Inspection': '#d62728'
    };

    const config = {
        xField: 'month',
        children: [
            {
                data: taskData,

                yField: 'count',
                colorField: 'type',
                seriesField: 'type',
                stack: true,

                style: { maxWidth: 90 },
                // scale: { y: { domainMax: 1200 } },
                interaction: {
                    tooltip: {
                        crosshairs: false,
                        marker: false,
                    },
                },
            },
            //   {
            //     data: taskData,
            //     type: 'line',
            //     yField: 'count',
            //     colorField: () => 'count',
            //     style: { lineWidth: 2 },
            //     axis: { y: { position: 'right' } },
            //     interaction: {
            //       tooltip: {
            //         crosshairs: false,
            //         marker: false,
            //       },
            //     },
            //   },
        ],
    };

    const taskLineConfig = {
        data: taskData,
        xField: 'month',
        yField: 'count',
        colorField: 'type',
        // seriesField: 'type',
        stack: true,
        label: {
            text: 'count',
            textBaseline: 'bottom',
            position: 'inside',
        },
        // xAxis: { title: { text: 'Month' } },
        // yAxis: { title: { text: 'Tasks Completed' } },
        color: { palette: "tableau10" },
        annotations,
    }

    const workerLineConfig = {
        data: workerData,
        xField: 'month',
        yField: 'count',
        colorField: 'worker',
        color: { palette: "tableau10" },
        label: {
            text: 'count',
            style: {
                color: 'black',
                fontSize: 14,
            },
            textBaseline: 'bottom',
            position: 'outside',
        },
        height: 300,
        seriesField: 'worker',
        xAxis: { title: { text: 'Month' } },
        yAxis: { title: { text: 'Tasks Completed' } },
    };

    const taskPieConfig = {
        // appendPadding: 3,
        data: taskTypes,
        angleField: 'count',
        colorField: 'type',
        innerRadius: 0.5,
        height: 300,
        // radius: 1,
        tooltip: (d) => ({
            value: `${d.type}: ${d.count}`,
        }),

        label: {
            text: (d) => `${d.count}`,
            position: 'outside',
        },
        // legend: {
        //     color: {
        //         title: false,
        //         position: 'right',
        //         rowPadding: 5,
        //     },
        // },
    };

    return (
        <>
            <div ref={printRef} >
                {/* <Row gutter={16}>
                <Col span={6}>
                    <Card>
                        <Statistic title="Actual Contracts" value={statistics.contracts} />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic title="Passports on Warranty" value={statistics.passports} />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic title="Delayed Tasks" value={statistics.delayedTasks} />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic title="Planned Tasks" value={statistics.plannedTasks} />
                    </Card>
                </Col>
            </Row> */}
                < Row gutter={16} style={{ marginTop: 20 }} >
                    <Col span={24}>
                        <Card title="Задания, выполненные за последние 12 месяцев">
                            {/* <Line {...taskLineConfig} /> */}
                            <Column {...taskLineConfig} />;
                            {/* <DualAxes {...config} /> */}
                        </Card>
                    </Col>
                </Row >
                <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
                    <Col span={12} xs={24} md={12}>
                        <Card title="Задания по видам работ">
                            <Pie {...taskPieConfig} />
                        </Card>
                    </Col>
                    <Col span={12} xs={24} md={12}>
                        <Card title="Задания по исполнителям">
                            <Column {...workerLineConfig} />
                        </Card>
                    </Col>
                </Row>
                <Row gutter={16} style={{ marginTop: 20 }}>
                    <Col span={24}>
                        <ReactToPrint
                            trigger={() => <Button type="primary" className='noprint'>Печать отчета</Button>}
                            content={() => printRef.current}
                        />
                    </Col>
                </Row>
            </div >
        </>
    );
};


