import React, { useContext, useEffect, useState } from 'react';
import { Button, Drawer, Modal, Flex, Space, Grid, Switch } from 'antd';
import UserList from '../components/User/UserList';
import UserForm from '../components/User/UserForm';

import { PlusOutlined } from '@ant-design/icons';
import { useHttp } from '../hooks/http.hook';
import { AuthContext } from '../context/AuthContext';

const { useBreakpoint } = Grid;

const UserPage = () => {
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [users, setUsers] = useState([])
    const [splitted, setSplitted] = useState([])
    const [client, setClient] = useState(true)
    const [description, setDescription] = useState({})
    const [isModalOpen, setIsModalOpen] = useState(false)
    const isMobile = useBreakpoint().xs

    const { request, loading } = useHttp()
    const { token, checkRole } = useContext(AuthContext)

    useEffect(() => {
        if (client) {
            setSplitted(users.filter(u => !u.role.includes('customer')))
        } else {
            setSplitted(users.filter(u => u.role.includes('customer')))
        }
    }, [client, users])

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await request('o/api/users', 'GET', null, {
                    Authorization: `Bearer ${token}`
                });
                setUsers(response);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        }
        fetchUsers()
    }, [])

    const showDrawer = (user) => {
        setSelectedUser(user);
        setIsDrawerVisible(true);
    };

    const onCloseDrawer = () => {
        setSelectedUser(null);
        setIsDrawerVisible(false);
    };
    const createNewUser = async (user) => {
        try {
            const response = await request('o/api/users', 'POST', user, {
                Authorization: `Bearer ${token}`
            });
            setUsers([...users, response]);
        } catch (error) {
            console.error('Error creating new user:', error);
        }


    }

    const updateUser = async (id, user) => {
        try {
            const response = await request(`o/api/users/${id}`, 'PUT', user,
                { Authorization: `Bearer ${token}` }, { new: true }
            )
            setUsers(users.map(u => u._id === id ? response : u))
        } catch (error) {
            console.error('Error updating user:', error);
        }
    }

    const handleDelete = async (id) => {
        try {
            await request(`o/api/users/${id}`, 'DELETE', null,
                { Authorization: `Bearer ${token}` }
            )
            setUsers(users.filter(u => u._id !== id))
        } catch (error) {
            console.error('Error deleting user:', error);
        }

    }

    const handleShow = (record) => {
        setDescription(record)
        showModal()
    }
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleLock = async (user) => {

        try {
            if (user.status === 'active') {
                user.status = 'locked';
            } else if (user.status === 'locked') {
                user.status = 'active';
            }

            const response = await request(`o/api/users/${user._id}`, 'PUT', user,
                { Authorization: `Bearer ${token}` }
            )
            setUsers(users.map(u => u._id === user._id ? response : u))
        } catch (error) {
            console.error('Error updating user:', error);
        }
    }
    return (
        <div >
            {/* <h2>Управление пользователями</h2>
            <Button type="primary" onClick={() => showDrawer(null)}
                style={{ marginBottom: '1rem' }}
            >+ ДОБАВИТЬ</Button> */}
            <Flex wrap="wrap" gap="small" align='center' justify='space-between' >
                <Space align='baseline' size={'middle'} >
                    <h2 style={{ marginBottom: 0, marginTop: 0 }}>Управление пользователями</h2>
                    {checkRole(['admin']) && <Button type="default"
                        onClick={showDrawer}
                        shape={isMobile ? 'circle' : ''}
                        icon={<PlusOutlined />}
                        style={{ borderRadius: '20px' }}
                    >{isMobile ? '' : 'ДОБАВИТЬ'}</Button>}
                </Space>
                {!isMobile && <Switch checkedChildren="Сотрудники" unCheckedChildren="Клиенты" checked={client} onChange={() => setClient(pre => !pre)} />}
            </Flex>
            <Drawer
                title={selectedUser ? 'Редактирование пользователя' : 'Добавить пользователя'}
                width={560}
                onClose={onCloseDrawer}
                open={isDrawerVisible}
                destroyOnClose={true}
            >
                <UserForm user={selectedUser} onCloseDrawer={onCloseDrawer} createNewUser={createNewUser} updateUserProfile={updateUser} />
            </Drawer>
            <UserList isMobile={isMobile} onEditUser={showDrawer} onShowUser={handleShow} onDeleteUser={handleDelete} onLockUser={handleLock}
                data={splitted} //users
                rowKey="email"
                loading={loading}
                client={client}
                setClient={setClient} />
            <Modal
                okText={"Закрыть"}
                centered
                width='50vw'
                afterClose={() => setDescription(null)}
                open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
                footer={(_, { OkBtn }) => (<OkBtn />)}
            >
                <div>{description && <div>Имя: {description.lastname}, Фамилия: {description.firstname}</div>}</div>
            </Modal>


        </div>
    );
};

export default UserPage;
