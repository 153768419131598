// CarPage.js
import React, { useState, useContext, useEffect } from 'react';
import CarList from '../../components/Cars/CarList';
import CarForm from '../../components/Cars/CarForm';
import { Button, Drawer, Flex, Space, Grid, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useHttp } from '../../hooks/http.hook';
import { AuthContext } from '../../context/AuthContext';

const { useBreakpoint } = Grid;

const CarPage = () => {
    const [visible, setVisible] = useState(false);
    const [edit, setEdit] = useState(null)
    const [cars, setCars] = useState([])

    const { request, loading } = useHttp();
    const { token, checkRole } = useContext(AuthContext)
    const isMobile = useBreakpoint().xs;


    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setEdit(null)
        setVisible(false);
    };

    const newServiceCar = () => {
        showDrawer()
    }

    const fetchServiceCars = async () => {
        try {
            const response = await request('o/api/servicecar', 'GET', null, { Authorization: `Bearer ${token}` });
            setCars(response);
        } catch (error) {
            console.error('Error fetching tasks:', error);
        }
    };


    useEffect(() => {
        if (!cars || cars.length === 0) { fetchServiceCars() }
    }, []);

    const handleFormSubmit = async (newcar) => {
        try {
            const response = await request('o/api/servicecar', 'POST', newcar,
                { 'Authorization': `Bearer ${token}` });
            setCars([...cars, response]);
            message.success('Сервисный автомобиль успешно добавлен');
            onClose(); // Close the Drawer
        } catch (error) {
            console.error('Error adding service car:', error);
            message.error('Ошибка добавления данных. Пожалуйста, попробуйти обновить позднее.');
        }
    };

    const handleFormUpdate = async (updatedCar) => {

        const id = edit._id;
        try {
            const response = await request(`o/api/servicecar/${id}`, 'PUT', updatedCar,
                { Authorization: `Bearer ${token}` }
            )
            setCars(cars.map(u => u._id === id ? response : u))
            setEdit(null);
            message.success('Информация о сервисном автомобиле успешно обновлена');
            onClose(); // Close the Drawer
        } catch (error) {
            console.error('Error updating service car:', error);
            message.error('Не удалось записать изменения. Попробуйте позднее');
        }
    };

    const handleDelete = async (id) => {
        try {
            await request(`o/api/servicecar/${id}`, 'DELETE', null,
                { Authorization: `Bearer ${token}` }
            )
            setCars(cars.filter(u => u._id !== id))
            message.success('Сервисный автомобиль успешно удален');
        } catch (error) {
            console.error('Error deleting service car:', error);
            message.error('Не удалось удалить информацию о сервисном автомобиле. Пожалуйста, повторите операцию позднее');
        }

    }

    const handleEdit = (record) => {
        setEdit(record);
        showDrawer();

    }

    const onFinish = (values) => {

        if (edit?._id) {
            handleFormUpdate(values)
        } else {
            handleFormSubmit(values)
        }
    }

    return (
        <div>
            <Flex wrap="wrap" gap="small" align='center' justify='space-between' >
                <Space align='baseline' size={'middle'} >
                    <h2 style={{ marginBottom: 0, marginTop: 0 }}>Сервисные автомобили</h2>
                    {checkRole(['admin']) && <Button type="default"
                        onClick={newServiceCar}
                        shape={isMobile ? 'circle' : ''}
                        icon={<PlusOutlined />}
                        style={{ borderRadius: '20px' }}
                    >{isMobile ? '' : 'ДОБАВИТЬ'}</Button>}
                </Space>

            </Flex>
            <CarList
                data={cars}
                onDelete={handleDelete}
                onEdit={handleEdit}
                loading={loading}

            />
            <Drawer
                title={edit?._id ? 'Редактирование записи' : "Добавление нового автомобиля"}
                placement="right"
                closable={true}
                onClose={onClose}
                open={visible}
                destroyOnClose={true}
                width={460}
            >
                <CarForm
                    onClose={onClose}
                    onSubmit={onFinish}
                    item={edit}
                />
            </Drawer>
        </div>
    );
};

export default CarPage;
