import React from 'react'
import { useState } from 'react'
import { Table, DatePicker, Space } from 'antd';
import { Link } from 'react-router-dom'
import locale from 'antd/es/date-picker/locale/ru_RU';
const { RangePicker } = DatePicker;


export const OffersList = ({ list }) => {

    const [mylist, setMylist] = useState(list)

    if (!list.length) {
        return (<h5 className="center">Готовых предложений пока нет</h5>)
    }

    const columns = [
        {
            title: 'N',
            dataIndex: 'no',
            width: '4%',
        }, {
            title: 'Наименование',
            dataIndex: 'name',

        }, {
            title: 'Дата',
            dataIndex: 'date',
            sorter: (a, b) => new Date(a.date) - new Date(b.date),
        }, {
            title: 'Ссылка',
            dataIndex: 'link',
        },

    ]
    const data = mylist.map((el, n) => ({
        key: el._id,
        no: n + 1,
        name: el.title,
        date: new Date(el.date).toLocaleString(),
        link: <Link to={`/detail/${el._id}`}>Открыть</Link>,
    }))


    const filterHandle = (e) => {
        if (e) {
            const start = e[0]?.$d ?? new Date('2011-01-01')
            const finish = e[1]?.$d ?? new Date()
            console.log(e[0].$d, e[1].$d)
            const mdata = list.filter(el => (new Date(el.date) >= start) && (new Date(el.date) <= finish))
            setMylist(mdata)
        } else {
            setMylist(list)
        }

    }

    return (
        <>
            <Space direction="vertical" size={12}>
                <RangePicker
                    locale={locale}
                    onChange={filterHandle}
                    style={{ marginBottom: '10px' }}
                />
            </Space>
            <Table columns={columns} dataSource={data} pagination={{ pageSize: 50 }} />

        </>
    )
}

//     <table>
//         <thead>
//             <tr>
//                 <th>N</th>
//                 <th>Наименование</th>
//                 <th>Дата</th>
//                 <th>Ссылка</th>
//             </tr>
//         </thead>

//         <tbody>
//             {list.map((el, n) => {
//                 return (
//                     <tr key={el._id}>
//                         <td>{n + 1}</td>
//                         <td>{el.title}</td>
//                         <td>{new Date(el.date).toLocaleString()}</td>
//                         <td>
//                             <Link to={`/detail/${el._id}`}>Открыть</Link>
//                         </td>
//                     </tr>
//                 )})
//             }

//         </tbody>
//   </table>