import React, { useEffect, useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { useHttp } from '../../hooks/http.hook';
import { LockOutlined } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import { useNavigate } from 'react-router-dom';

export const ForgotPasswordForm = () => {
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(true)
    const { request } = useHttp()
    const [forgot] = useForm();
    const navigate = useNavigate()
    const onFinish = async (values) => {
        setLoading(true);
        try {
            const response = await request('o/api/user/forgot-password', 'POST', values, {})
            message.success('Ссылка для восстановления пароля направлена на электронную почту.');
            forgot.setFieldsValue({ email: '' });
            setShow(false)
            navigate('/login')
        } catch (error) {
            console.error('Error sending forgot password request: ', error);
            message.error('Не удалось сбросить пароль. Попробуйте еще раз немного позднее.');
        }
        setLoading(false);
    };

    return show ? (
        <Form
            name="forgot-password"
            form={forgot}
            onFinish={onFinish}
            layout="vertical"
        >
            <Form.Item
                label="Email"
                name="email"
                rules={[{ required: true, message: 'Пожалуйста, введите ваш email!' }]}
            >
                <Input type="email" />
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                    Сбросить пароль
                </Button>
            </Form.Item>
        </Form>) : (<></>)
};

export const ResetPasswordForm = ({ resetToken }) => {
    const [loading, setLoading] = useState(false);
    const { request } = useHttp()


    const onFinish = async (values) => {
        setLoading(true);
        try {
            const response = await request('o/api/user/reset-password', 'POST',
                { token: resetToken, password: values.password }, {});

            if (response.status === 200) {
                window.location.href = '/'
            }
            message.success('Пароль успешно изменен!')
            window.location.href = '/'
        } catch (error) {
            console.error('Error resetting password:', error);
            message.error('Не удалось сбросить пароль. Попробуйте еще раз немного позднее.');
            window.location.href = '/'
        }
        setLoading(false);
    };

    return (
        <Form
            name="reset-password"
            onFinish={onFinish}
            style={{ maxWidth: '430px', margin: '0 auto' }}
        >
            <Form.Item
                name="password"
                rules={[
                    { required: true, message: 'Пожалуйста, введите новый пароль!' },
                    { min: 6, message: 'Длина пароля должна быть не менее 6 символов!' },
                ]}
            >
                <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder="Новый пароль"
                />
            </Form.Item>

            <Form.Item
                name="confirm"
                dependencies={['password']}
                hasFeedback
                rules={[
                    { required: true, message: 'Пожалуйста, подтвердите пароль!' },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('Пароли не совпадают!'));
                        },
                    }),
                ]}
            >
                <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder="Подтверждение пароля"
                />
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading} block>
                    Сбросить пароль
                </Button>
            </Form.Item>
        </Form>
    );
};


