import { Drawer, Button, Form, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import { useForm } from 'antd/es/form/Form'
// import InputMask from 'react-input-mask'
import MaskedInput from 'antd-mask-input'

export const ClientDrawer = props => {
    const [formM] = useForm()
    const emptyFields = {
        machine: '',
        location: '',
        person: '',
        phone: '',
        description: '',

    }
    const [formValues, setFormValues] = useState(emptyFields)

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };



    const onFinish = (values) => {
        props.onSendRequest({ ...values, type: 'mntc' })
        formM.resetFields()
        props.onClose()
    }

    useEffect(() => {
        if (props && formM) {
            formM.resetFields()
            formM?.setFieldValue('machine', props?.value?.passport || '')
        }

    }, [props])

    return <Drawer
        forceRender
        open={props.open} onClose={props.onClose}
        title={props.title}
        onCancel={props.onOk}
        width={560}
        destroyOnClose={true}
        footer={
            < Button type="primary" className='noRadius' onClick={formM.submit}//{props.onFinish}
                style={{ height: 45 }}>
                Отправить заявку
            </Button >
        }
    >
        {/* <div style={{
            marginTop: '0px',
        }}> */}

        <Form
            {...layout}
            form={formM}
            initialValues={formValues}
            id={"mntcform"}
            name="control-mntc"
            onFinish={onFinish}
            style={{ maxWidth: 600 }}
        >

            <Form.Item name="machine" label="Техника" rules={[{
                required: true,

            }]}                >
                <Input disabled={
                    props?.value?.passport || false
                } />
            </Form.Item>
            <Form.Item name="location" label="Локация" rules={[{
                required: true,
                message: 'Пожалуйста, укажите место нахождения техники'
            }]}>
                <Input
                    placeholder='Место выполнения работ'
                />
            </Form.Item>
            <Form.Item name="person" label="Контактное лицо" rules={[{
                required: true,
                message: 'Укажите контактное лицо'
            }]}>
                <Input />
            </Form.Item>
            <Form.Item name="phone" label="Телефон" rules={[{
                required: true,
                message: 'Введите номер телефона'
            }]}>
                {/* <InputMask

                        autoComplete="off"
                        value={props?.phone ?? ''}
                        mask="+9 (999) 999-99-99"
                    >
                        {() => <Input />}
                    </InputMask> */}
                {/* <Input /> */}
                <MaskedInput
                    name="formPhone"
                    value={props?.phone ?? ''}
                    mask={'+0 (000) 000-00-00'}
                />
            </Form.Item>
            <Form.Item name="description" label="Информация"
                rules={[{ required: false }]}>
                <Input.TextArea rows={5}
                    placeholder='Можно внести дополнительную информацию для специалистов' />
            </Form.Item>
        </Form>
        {/* </div > */}
    </Drawer>
}