import { AutoComplete, Form, Input } from 'antd';
// import { useForm } from 'antd/es/form/Form'
import React, { useEffect } from 'react'
import MaskedInput from 'antd-mask-input'


export const ClientFormMntc = (props) => {
    // const [form] = useForm()
    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const emptyFields = {
        machine: '',
        location: '',
        person: '',
        phone: '',
        description: '',

    }
    const onFinish = (values) => {
        props.onFinish({ ...values, type: 'mntc' })
        props.form.setFieldsValue(emptyFields)

    }

    useEffect(() => {
        props.form.setFieldValue('machine', props?.value?.passport || '')
    }, [props])

    return (
        <div style={{
            marginTop: '0px',
        }}>

            <Form
                {...layout}
                form={props.form}
                name="control-mntc"
                onFinish={onFinish}
                style={{ maxWidth: 600 }}
            >

                <Form.Item name="machine" label="Техника" rules={[{
                    required: true,
                    message: 'Пожалуйста, укажите технику'
                }]}                >
                    <AutoComplete
                        disabled={props?.value?.passport || false}
                        options={props.options}
                        allowClear
                        placeholder="Выберите технику с установленной системой"
                    />
                    {/* <Input disabled={
                        props?.value?.passport || false
                    } /> */}
                </Form.Item>
                <Form.Item name="location" label="Локация" rules={[{
                    required: true,
                    message: 'Пожалуйста, укажите локацию техники'
                }]}>
                    <Input
                        placeholder='Место выполнения работ'
                    />
                </Form.Item>
                <Form.Item name="person" label="Контактное лицо" rules={[{
                    required: true,
                    message: 'Пожалуйста, укажите контактное лицо'
                }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="phone" label="Телефон" rules={[{
                    required: true,
                    message: 'Пожалуйста, укажите номер телефона'
                }]}>
                    <MaskedInput
                        name="formPhone"
                        value={props?.phone ?? ''}
                        mask={'+0 (000) 000-00-00'}
                    />
                    {/* <Input /> */}
                </Form.Item>
                <Form.Item name="description" label="Информация"
                    rules={[{ required: false }]}>
                    <Input.TextArea rows={5}
                        placeholder='Можно внести дополнительную информацию для специалистов' />
                </Form.Item>
            </Form>
        </div >
    )

}