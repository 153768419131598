import React, { useState, useEffect } from 'react';
import { Form, Input, Button } from 'antd';
import { useForm } from 'antd/es/form/Form';

export const EquipmentForm = ({ onSubmit, onClose, initialValues }) => {
    const [formValues, setFormValues] = useState(initialValues)
    const [equipForm] = useForm()

    useEffect(() => {
        equipForm.resetFields()
    }, [initialValues])

    const onFinish = (values) => {
        values.partno = values.partno.trim()
        onSubmit({ _id: initialValues?._id, ...values })
        onClose()
    }
    return (
        <Form
            name="equipment_form"
            onFinish={onFinish}
            initialValues={initialValues}
            layout="vertical"
            form={equipForm}
        >
            <Form.Item
                name="partno"
                label="Артикул"
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="description"
                label="Наименование"
                rules={[{ required: true, message: 'Введите наименование' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="unitMeasure"
                label="Единица измерения"
                rules={[{ required: true, message: 'Введите единицу измерения' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="comment"
                label="Примечание (описание)"

            >
                <Input.TextArea rows={4} />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Записать
                </Button>
            </Form.Item>
        </Form>
    );
};

