import React, { useEffect, useState, useContext } from 'react'
import { PhotoGallery } from '../Task/taskDescription'
import { AuthContext } from '../../context/AuthContext'
import { useHttp } from '../../hooks/http.hook'
import { Descriptions, Spin } from 'antd'

export const ContractDetails = ({ ctr }) => {

    const [contract, setContract] = useState(
        { title: '', customer: { form: '', name: '', inn: '', address: '' }, date: '', startDate: '', endDate: '', signedPdf: [] }
    )
    const [attachedFiles, setAttachedFiles] = useState([])
    const { token } = useContext(AuthContext)
    const { request, loading } = useHttp()

    useEffect(() => {
        const fetchContracts = async () => {
            try {
                const contractsData = await request(`/o/api/contracts/${ctr._id}`, 'GET', null, { //ctr.contract._id
                    Authorization: `Bearer ${token}`
                })
                setContract(contractsData);
                // if (contractsData.signedPdf.length > 0) {
                //     setFiles(contractsData.signedPdf)
                // }
            } catch (error) {
                console.error('Error fetching contracts:', error);
            }
        }
        fetchContracts()
    }, [ctr])

    useEffect(() => {
        setAttachedFiles([...contract?.signedPdf])
    }, [contract])

    // if (loading) {
    //     return <div>Загрузка...</div>
    // }

    return (
        <div>
            <h2>Детали контракта</h2>
            <Spin spinning={loading}>
                <Descriptions
                    size='small'
                    bordered>
                    <Descriptions.Item label="Наименование" span={3}>
                        {contract?.parent
                            ? `Доп.соглашение ${contract?.title} от ${new Date(contract?.date).toLocaleDateString('ru-RU')}`
                            : `Договор ${contract?.title} от ${new Date(contract?.date).toLocaleDateString('ru-RU')}`}
                    </Descriptions.Item>
                    <Descriptions.Item label="Контрагент" >{`${contract?.customer?.form} ${contract?.customer?.name}`}</Descriptions.Item>
                    <Descriptions.Item label="ИНН" >{`${contract?.customer?.inn}`}</Descriptions.Item>

                    <Descriptions.Item label="Адрес" >{`${contract?.customer?.address}`}</Descriptions.Item>

                    <Descriptions.Item label="Срок действия" span={3}>
                        {contract?.startDate ? (new Date(contract?.startDate)).toLocaleDateString('ru-RU') : '...'} по {contract?.endDate ? (new Date(contract?.endDate)).toLocaleDateString('ru-RU') : '...'}
                    </Descriptions.Item>

                    <Descriptions.Item label="Прикрепленные файлы">
                        <PhotoGallery files={attachedFiles} size='small' />
                    </Descriptions.Item>
                </Descriptions>
            </Spin>

        </div>
    )

}