import React from 'react'

export default function Logo({ style, fill }) {
  let css = `
  .fil0 {
    
    fillRule:nonzero;
  }
`
  // fill: ${fill ?? 'white'};
  return (
    <svg style={style}
      className={fill === 'black' ? 'logotype' : ''}
      clipRule="evenodd"
      fillRule="evenodd"
      imageRendering="optimizeQuality"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      version="1.1"
      viewBox="0 0 20100 7900"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg">
      <defs>
        <style type="text/css">{css}</style>
      </defs>



      <polygon className="fil0" fill={fill} points="9261.1 3736 9745.4 3736 9745.4 1788 10150 1788 10150 3736 10634 3736 10634 1333.1 9261.1 1333.1" />
      <polygon className="fil0" fill={fill} points="15223 2579.7 15561 1333.2 15066 1333.2 14840 2336.6 14700 2336.6 14700 1333.2 14272 1333.2 14272 2336.6 14132 2336.6 13906 1333.2 13411 1333.2 13749 2579.7 13383 3736 13878 3736 14125 2819.4 14272 2819.4 14272 3736 14700 3736 14700 2819.4 14847 2819.4 15094 3736 15589 3736" />
      <polygon className="fil0" fill={fill} points="16105 3736 16593 3736 16593 1801.8 17014 1801.8 17014 1333.1 15683 1333.1 15683 1801.8 16105 1801.8" />
      <polygon className="fil0" fill={fill} points="17224 3736 18405 3736 18405 3281.1 17708 3281.1 17708 2760.3 18300 2760.3 18300 2302 17708 2302 17708 1788 18405 1788 18405 1333.1 17224 1333.1" />
      <polygon className="fil0" fill={fill} points="20043 1333.2 19527 1333.2 19301 2420 19078 1333.2 18562 1333.2 18889 2579.7 18562 3736.1 19078 3736.1 19301 2725.6 19527 3736.1 20043 3736.1 19715 2579.7" />
      <polygon className="fil0" fill={fill} points="9261.1 6578.8 9745.4 6578.8 9745.4 4630.8 10150 4630.8 10150 6578.8 10634 6578.8 10634 4175.8 9261.1 4175.8" />
      <path className="fil0" fill={fill} d="m11843 5231.5c0 85.59-20.91 151.08-62.74 196.24-41.84 45.02-109.16 67.64-202.14 67.64h-125.44v-864.66h125.44c92.98 0 160.3 22.63 202.14 67.78 41.83 45.12 62.74 110.61 62.74 196.24v336.76zm-264.88-1055.6h-609.86v2402.9h484.42v-628.49h125.44c250.9 0 439.08-64.81 564.61-194.44 125.4-129.62 188.15-304.36 188.15-524.35v-336.76c0-219.9-62.75-394.77-188.15-524.36-125.53-129.62-313.71-194.53-564.61-194.53z" />
      <polygon className="fil0" fill={fill} points="16186 5373.8 15803 4175.8 15340 4175.8 15340 6578.8 15782 6578.8 15754 5012.8 16075 6165.5 16294 6165.5 16615 5012.8 16587 6578.8 17030 6578.8 17030 4175.8 16566 4175.8" />
      <path className="fil0" fill={fill} d="m11758 1835.8c120.05-68.09 256.15-104 393.61-104 288.57 0 555.97 154.38 697.87 403.02 218.19 382.26 83.57 870.14-300.17 1087.6-120.06 68-256.16 103.93-393.48 103.93-288.58 0-556.11-154.4-697.98-403.05-218.18-382.26-83.47-870.1 300.15-1087.5zm397.83 1941.8c209.77 0 422.35-52.41 617.37-163.02 600.74-340.3 811.54-1104.2 469.99-1702.8-230.66-404.18-655.21-631.23-1091.6-631.23-209.88 0-422.36 52.53-617.47 163.04-600.77 340.4-811.55 1104.3-469.89 1702.8 230.66 404.18 655.2 631.23 1091.6 631.23z" />
      <path className="fil0" fill={fill} d="m14221 6065.2c-120.08 68.09-256.18 104-393.64 104-288.54 0-555.94-154.51-697.84-403.05-105.39-184.74-132.23-399.73-75.38-605.51 56.72-205.81 190.08-377.04 375.52-482.08 120.09-68 256.16-104.03 393.51-104.03 288.68 0 556.08 154.5 697.98 403.15 218.18 382.26 83.44 870.1-300.15 1087.5zm-397.83-1941.8c-209.77 0-422.38 52.41-617.37 162.92-600.77 340.4-811.54 1104.3-470.02 1702.9 230.7 404.18 655.24 631.23 1091.6 631.23 209.91 0 422.39-52.53 617.5-163.04 600.74-340.4 811.55-1104.3 469.89-1702.8-230.69-404.3-655.23-631.23-1091.6-631.23z" />
      <polygon className="fil0" fill={fill} points="5730.5 5360.5 2644.9 2286 2232.3 2697 5318 5771.5" />
      <path className="fil0" fill={fill} d="m3924.3 7208c-1802.6 0-3269.3-1461.3-3269.3-3257.5 0-354.94 57.98-696.49 163.95-1016.4l2847.7 2837.4 412.62-411.01-3008.3-2997.4c0.13-0.1 0.13-0.22 0.23-0.45l-447.85-446.24c-367.69 590.98-580.53 1287.7-580.53 2034.1 0 2136 1737.8 3867.5 3881.4 3867.5 1171.6 0 2221.2-517.98 2932.9-1335.7l-435.02-433.41c-600.2 708.19-1496.9 1159.1-2497.9 1159.1z" />
      <path className="fil0" fill={fill} d="m3924.3 83.1c-1182.4 0-2240.6 527.46-2952.4 1358.4l434.89 433.28c600.06-721.37 1505.9-1181.7 2517.5-1181.7 1802.6 0 3269.3 1461.3 3269.3 3257.5 0 366.8-61.49 719.34-174.39 1048.5l-2723-2713.1-412.49 411.11 2878.3 2867.8c-0.13 0.1-0.13 0.23-0.13 0.23l446.85 445.33c377.68-596.55 597-1302.5 597-2059.9 0-2136-1737.8-3867.5-3881.4-3867.5z" />


    </svg>
  )
}
