import React, { useContext, useState, useEffect } from 'react'
import { useHttp } from '../hooks/http.hook'
import { AuthContext } from '../context/AuthContext'
import { MachinesForm } from '../components/Machines'
import { Button, Table, Drawer, message, Space, Popconfirm, Flex, Input, List, Typography, Grid } from 'antd'
import { DeleteOutlined, EditOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons'
import dayjs from 'dayjs';
import { GetColumnSearchProps } from '../components/SearchLine'
import { useSize } from '../components/Equipment/EquipmentList'
const { useBreakpoint } = Grid
const { Paragraph } = Typography

export const MachinesPage = () => {
  const [list, setList] = useState([])
  const [filteredMachines, setFilteredMachines] = useState(null)
  const [first, setFirst] = useState(true)
  const { loading, request } = useHttp()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const { token, checkRole } = useContext(AuthContext)
  const [vals, setVals] = useState({})
  const [page, setPage] = useState(1);
  const isMobile = useBreakpoint().xs


  const windowSize = useSize();

  useEffect(() => {
    window.dispatchEvent(new Event("resize"))
  }, [])


  useEffect(() => {
    const fetchMachines = async () => {
      if (!(list.length > 0) && first) {
        try {
          const fetched = await request('/o/api/machines', 'GET', null, {
            Authorization: `Bearer ${token}`
          })
          setList(fetched)
          setFirst(false)
          // console.log(fetched)
        } catch (e) { }
      }

    }
    fetchMachines()
  }, [])

  const onDelete = async (key) => {

    try {
      const deleted = await request(`/o/api/machines/${key._id}`, 'DELETE', null, {
        Authorization: `Bearer ${token}`
      })
      let newList = list.filter(el => el._id !== key._id)
      setList(newList)
    } catch (e) {

    }
  }

  const onFinish = async (values, operationHours) => {
    let record = { ...values }
    record.delivery = dayjs(record.delivery)
    record.operationHours = [...operationHours] || []
    record.lastHours = record.operationHours[record.operationHours.length - 1]?.hours ?? null
    if (record.owner) {
      if (typeof (record.owner) === 'object') {
        record.owner = record.owner._id
      }
    }

    if (record.operationHours) {
      record.operationHours.forEach((el, i) => {
        el.hours = Number(el.hours)
        el.date = dayjs(el.date)
      })

    }
    if (values?._id) {
      await handleUpdateMachine(values._id, record)
    } else {
      await handleAddMachine(record)
    }
    closeDrawer()
  }

  const handleAddMachine = async (values) => {
    try {
      let response = await request('/o/api/machines', 'POST', values, {
        Authorization: `Bearer ${token}`
      })
      // console.log('added', response)
      setList([...list, response])
      message.info(`Техника ${response.model} #${response.serialNumber} создана`)
    } catch (error) {
      message.error(error.message)
    }
  }

  const handleUpdateMachine = async (id, values) => {
    // console.log('update')
    try {
      let response = await request(`/o/api/machines/${id}`, 'PUT', values, {
        Authorization: `Bearer ${token}`
      })
      setList(list.map(u => u._id === id ? response : u))
      //updateContext(response)
      message.info(`Техника ${response.model} #${response.serialNumber} изменена`)
    } catch (error) {
      message.error(error.message)
    }
  }

  const editRecord = (record) => {
    record.operationHours = record.operationHours.map(el => ({ date: dayjs(el.date), hours: +el.hours, document: el?.document }))
    record.delivery ? record.delivery = dayjs(record.delivery) : record.delivery = null
    setVals(record)
    setDrawerOpen(true)
  }


  const columns = [
    {
      title: "#",
      key: "index",
      width: (list.length).toString().length * 22,

      render: (value, item, index) => (page - 1) * 10 + index + 1
    },
    {
      title: 'Модель техники',
      dataIndex: 'model',
      key: 'model',
      ...GetColumnSearchProps('model', 'модели'),
    },
    {
      title: 'Серийный номер',
      dataIndex: 'serialNumber',
      key: 'serialNumber',
      ...GetColumnSearchProps('serialNumber', 'серийному номеру'),
    },
    {
      title: 'Гаражный номер',
      dataIndex: 'garageNumber',
      key: 'garageNumber',
      ...GetColumnSearchProps('garageNumber', 'гаражному номеру'),
    },
    {
      title: 'Наработка м.ч.',
      dataIndex: ['lastHours'],
      key: 'hours',

    },
    {
      title: 'Производитель',
      dataIndex: 'brand',
      key: 'brand',
      ...GetColumnSearchProps('brand', 'бренду'),
    },

    {
      title: 'Год',
      dataIndex: 'year',
      key: 'year',
      width: 65,
      ...GetColumnSearchProps('year', 'году выпуска'),
    },
    {
      title: 'Владелец',
      dataIndex: ['owner', 'name'],
      key: 'ownername',
      ...GetColumnSearchProps(['owner', 'name'], 'владельцу'),
      sorter: (a, b) => a?.owner?.name?.toLowerCase().localeCompare(b?.owner?.name.toLowerCase()),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Действия',
      key: 'action',
      width: 120,
      render: (text, record) => (

        <Space size={'middle'}>
          <EditOutlined
            onClick={() => {
              editRecord(record)
            }}
          />
          {checkRole(['admin', 'manager']) && <Popconfirm
            title="Удаление записи"
            description="Вы уверены, что хотите удалить эту запись?"
            okText="Да"
            cancelText="Нет"
            onConfirm={() => { onDelete(record) }}
          >
            <DeleteOutlined style={{ color: 'red' }} />
          </Popconfirm>}
        </Space>

      ),
    },
  ];

  const mobileActions = (record, index) => {
    const actions = [

      (
        <EditOutlined
          onClick={() => {
            editRecord(record)
          }}
        />

      ),
      (<Popconfirm
        title="Удаление записи"
        description="Вы уверены, что хотите удалить эту запись?"
        okText="Да"
        cancelText="Нет"
        onConfirm={() => { onDelete(record) }}
      >
        <DeleteOutlined style={{ color: 'red' }} />
      </Popconfirm>
      )]
    if (checkRole(['admin', 'manager'])) { return actions }
    else {
      return [
        (<SearchOutlined
          onClick={() => {
            console.log(record)


          }}
        />)
      ]

    }
  }

  let mobileComponent = (
    <div>
      <Input.Search
        style={{ marginTop: '1rem' }}
        allowClear
        placeholder='Поиск по всем полям'
        onSearch={(value) => {
          if (value === '') { setFilteredMachines(null) }
          let filtered = list?.filter((item) => {
            const vals = value.split(' ');
            let fil = true
            if (typeof vals === 'object') {
              for (let i in vals) {
                fil = fil && JSON.stringify(item).toString().toLowerCase().includes(vals[i].toLowerCase())
              }
            } else {
              fil = JSON.stringify(item).toString().toLowerCase().includes(value.toLowerCase())
            }

            return fil
          })

          // JSON.stringify(item).toString().toLowerCase().includes(value.toLowerCase()));
          if (filtered) {
            setFilteredMachines(filtered);
          } else {
            setFilteredMachines(null)
          }

        }}
      />
      <p>Всего записей: {filteredMachines?.length ?? list?.length}</p>
      <List
        style={{
          marginTop: '6px',
          height: 'calc(100vh - 230px)',
          overflow: 'auto',
        }}
        loading={loading}
        itemLayout="horizontal"
        dataSource={filteredMachines ?? list}
        renderItem={(record, index) => (
          <List.Item
            actions={mobileActions(record, index)}

          >
            <List.Item.Meta
              // onClick={() => console.log(record)}
              avatar={index + 1}
              title={<span style={{ fontSize: '14px' }}>
                {record?.brand} {record?.model} {record?.serialNumber
                  ?
                  <span style={{ fontWeight: 400, color: 'grey' }}>{record?.serialNumber}</span>
                  : ''}
                {record?.garageNumber
                  ? <span style={{ fontWeight: 300, color: 'grey' }}> / {record?.garageNumber}</span>
                  : ''}
              </span>}
              description={<>
                <Paragraph style={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.45)', margin: 0 }}>Наработка, м.ч.: {record?.lastHours ? <b>{record?.lastHours.toLocaleString()}</b> : ''}</Paragraph>
                <Paragraph style={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.45)', margin: 0 }} ellipsis={{ rows: 1, expandable: false, }}>Заказчик: {record?.owner?.name} </Paragraph>
              </>
              }
            />
          </List.Item>)}


      />

    </div>
  )

  const handleCreateMachine = (ev) => {

    let newone = list.findIndex(el => el._id === ev._id)
    if (newone > 0) {
      // updated
      let upd = [
        ...list.slice(0, newone),
        ev,
        ...list.slice(newone + 1),
      ];
      setList(upd)
      message.info(`Техника ${ev.name} обновлена`)
    } else {
      // new
      message.info(`Техника ${ev.name} создана`)
      let newlist = [...list, ev]
      setList(newlist)
    }
  };

  const openDrawer = () => {
    setDrawerOpen(true)
  }
  const closeDrawer = (e) => {

    setDrawerOpen(false);
  };

  const newMachine = () => {
    setVals({ name: '', operationHours: [] })
    openDrawer()
  }

  return (
    <>
      {( //!loading &&
        <>
          {/* <div style={{ marginBottom: '16px' }}>
            <h2>Техника</h2>
            <Button type="primary" style={{ marginRight: '16px' }} onClick={newMachine}>
              + ДОБАВИТЬ
            </Button>
          </div> */}
          <Flex wrap="wrap" gap="small" align='center' justify='space-between' >
            <Space align='baseline' size={'middle'} >
              <h2 style={{ marginBottom: 0, marginTop: 0 }}>Техника</h2>
              {checkRole(['admin']) && <Button type="default"
                onClick={newMachine}
                shape={isMobile ? 'circle' : ''}
                icon={<PlusOutlined />}
                style={{ borderRadius: '20px' }}
              >{isMobile ? '' : 'ДОБАВИТЬ'}</Button>}
            </Space>

          </Flex>
          <Drawer
            title={vals?._id ? `${vals.model}, #${vals.serialNumber}` : 'Новая техника'}
            width={700}
            placement="right"
            onClose={closeDrawer}
            open={drawerOpen}
            destroyOnClose={true}
          >
            <MachinesForm onFinish={onFinish} edValues={vals} setEdValues={setVals} machines={list} />
          </Drawer>
          {isMobile
            ? mobileComponent
            : (
              <Table
                size={'small'}
                style={{ marginTop: '1rem' }}
                dataSource={(list.length > 0) ? list : []}
                columns={columns}
                scroll={{ x: 980, y: +windowSize[1] - 220, }}
                rowKey="_id"
                loading={loading}
                pagination={false}
                onRow={(record, rowIndex) => {
                  return {
                    onDoubleClick: event => { editRecord(record) },
                  }
                }}
                footer={(data) => {
                  return < div style={{ textAlign: 'end', marginRight: '1rem' }}>Всего записей: {data?.length ?? 0}</div>
                }} // Modified from setting state
              />)}
        </>
      )}
    </>
  )
}