import React from 'react'

export const MyActual = () => {
    return (
        <div>
            <h2>Завершенные задания</h2>
            <p>
                Этот компонент находится в разработке.
            </p>
        </div>
    )

}