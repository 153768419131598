// CarList.js
import React, { useContext, useState, useEffect } from 'react';
import { Table, List, Grid, Space, Tooltip, Typography, Popconfirm, Input, Spin } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { AuthContext } from '../../context/AuthContext';
import { GetColumnSearchProps } from '../SearchLine';
import { useSize } from '../Equipment/EquipmentList'
const { useBreakpoint } = Grid;
const { Text } = Typography;

const CarList = ({ data, onEdit, onDelete, loading }) => {
    const [filteredCars, setFilteredCars] = useState(null);
    const [tableCount, setTableCount] = useState(0);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 30,
        total: data?.length,
        onChange: (page) => {
            setPagination({ ...pagination, current: page });
        },
    });
    const { checkRole } = useContext(AuthContext)
    const screens = useBreakpoint();
    const windowSize = useSize();

    useEffect(() => {
        window.dispatchEvent(new Event("resize"))
    }, [])

    const columns = [
        {
            title: '#',
            width: (data.length).toString().length * 20,
            key: 'num',
            render: (text, record, index) => {
                const currentPage = pagination.current || 1;
                return ((currentPage - 1) * pagination.pageSize) + index + 1;
            },
        },
        {
            title: 'Наименование',
            dataIndex: 'title',
            key: 'title',
            ...GetColumnSearchProps(['title'], 'наименованию'),
            sorter: (a, b) => a?.title?.toLowerCase().localeCompare(b?.title?.toLowerCase()),
            sortDirections: ['ascend', 'descend'],
            render: (text, rec, index) => (<Text ellipsis>{text}</Text>),

        },
        {
            title: 'Гос.номер',
            dataIndex: 'plateNumber',
            key: 'plateNumber',
            width: 120,
            ...GetColumnSearchProps(['plateNumber'], 'гос.номеру'),
        },
        {
            title: 'Год',
            dataIndex: 'yearOfProduction',
            key: 'yearOfProduction',
            width: 65,
            ...GetColumnSearchProps(['yearOfProduction'], 'году выпуска'),
        },
        {
            title: 'VIN',
            dataIndex: 'VIN',
            key: 'VIN',
            width: 170,
            render: (text) => (<Text ellipsis>{text}</Text>),

            ...GetColumnSearchProps(['VIN'], 'VIN'),

        },
        {
            title: 'Подразделение',
            dataIndex: 'branch',
            key: 'branch',
            width: 150,
            ...GetColumnSearchProps(['responsiblePerson'], 'подразделению'),

        },


        {
            title: 'Действия',
            key: 'actions',
            width: 100,
            render: (text, record) => (
                <Space size={'middle'}  >
                    {checkRole(['admin', 'manager']) && <Tooltip title='Редактировать' color={'geekblue'} placement="top">
                        <EditOutlined
                            onClick={() => {
                                onEdit(record)
                            }}
                        />
                    </Tooltip>}

                    {checkRole(['admin', 'manager']) && <Popconfirm
                        title="Удаление записи"
                        description="Вы уверены, что хотите удалить эту запись?"
                        okText="Да"
                        cancelText="Нет"
                        onConfirm={() => onDelete(record._id)}
                    >
                        <DeleteOutlined style={{ color: 'red' }} />
                    </Popconfirm>}

                </Space >
            ),
        },
    ];

    const mobileActions = (record, index) => {
        const actions = [
            (
                <Tooltip title='Редактировать' color={'geekblue'} placement="top">
                    <EditOutlined
                        onClick={() => {
                            onEdit(record)
                        }}
                    />
                </Tooltip>

            ),
            (<Popconfirm
                title="Удаление записи"
                description="Вы уверены, что хотите удалить эту запись?"
                okText="Да"
                cancelText="Нет"
                onConfirm={() => onDelete(record._id)}
            >
                <DeleteOutlined style={{ color: 'red' }} />
            </Popconfirm>)]
        if (checkRole(['admin', 'manager'])) { return actions }
        else { return '' }
    }

    const renderContent = () => {

        if (screens.xs) {
            return (<>
                <Input.Search
                    style={{ marginTop: '1rem' }}
                    allowClear
                    placeholder='Поиск по всем полям'
                    onSearch={(value) => {
                        let filtered = data.filter((item) => JSON.stringify(item).toString().toLowerCase().includes(value.toLowerCase()));
                        if (filtered) {
                            setFilteredCars(filtered);
                        } else {
                            setFilteredCars([])
                        }

                    }}
                />
                <List
                    style={{
                        marginTop: '6px',
                        height: 'calc(100vh - 200px)',
                        overflow: 'auto',
                    }}
                    loading={loading}
                    itemLayout="horizontal"
                    dataSource={filteredCars ?? data}
                    renderItem={(record, index) => (
                        <List.Item
                            actions={mobileActions(record, index)}

                        >
                            <List.Item.Meta

                                avatar={index + 1}
                                title={`${record.title}, ${record.plateNumber} `}
                                description={`VIN: ${record.VIN}, ${record?.branch ?? ''}`}
                            />
                        </List.Item>
                    )}
                />
            </>

            );
        } else {

            return <Table
                rowKey={'_id'}
                loading={{ indicator: <div><Spin /></div>, spinning: loading }}
                style={{ marginTop: '1rem' }}
                dataSource={filteredCars ?? data}
                columns={columns}
                size='small'
                pagination={false}
                scroll={{ x: 750, y: +windowSize[1] - 200, }}
                footer={(data) => {
                    return < div style={{ textAlign: 'end' }}>Всего записей: {data?.length ?? 0}</div>
                }} // Modified from setting state
            />;
        }
    };



    return (
        <>
            {renderContent()}
        </>
    );
};

export default CarList;
