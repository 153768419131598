import { createContext } from "react";

const noop = () => { };

export const DataContext = createContext({
    contracts: null,
    customers: null,
    machines: null,
    addCustomer: noop,
    updateCustomer: noop,
    deleteCustomer: noop,
    loadContracts: noop,
    addContract: noop,
    loadCustomers: noop,
    updateContract: noop,
    deleteContract: noop,
    settings: null,
    branches: null,
    updateSettings: noop,

})