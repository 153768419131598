

export const operationHoursCalculator = (data, delivery, lastMaintenanceDate, period) => {
    let nextMaintenanceDate, averageHoursPerDay;
    // console.log(data, delivery, lastMaintenanceDate, period)
    data = data.filter(el => el?.hours && el?.hours > 0)
    // console.log(data, delivery, lastMaintenanceDate, period)
    if (data?.length !== 0) {
        // Calculate total operation hours
        const sortedOperationHours = data.sort((a, b) => +(new Date(a.date)) - +(new Date(b.date)));
        const totalHours = sortedOperationHours[sortedOperationHours.length - 1]?.hours - sortedOperationHours[0]?.hours;

        // Calculate average operation hours per day
        // let averageHoursPerDay;
        if (data.length <= 1) {
            // Use default average of 6 operation hours per day if there's no data or only one record
            averageHoursPerDay = 6;
        } else {
            const startDate = new Date(data[0].date);
            const endDate = new Date(data[data.length - 1].date);
            const daysElapsed = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));
            averageHoursPerDay = (totalHours / daysElapsed) ?? 1;
        }
        // console.log('averageHoursPerDay', averageHoursPerDay);
        // Calculate next maintenance date
        const firstDate = new Date(delivery.date);
        // console.log('firstDate', firstDate);
        const maintenanceThreshold = +period;
        const nextMaintenanceHours = +delivery.hours + maintenanceThreshold;
        // console.log('nextMaintenanceHours', nextMaintenanceHours);
        const nextMaintenanceDateByHours = new Date(firstDate.getTime() + ((nextMaintenanceHours - delivery.hours) / averageHoursPerDay) * (1000 * 60 * 60 * 24));
        const nextMaintenanceDateByDays = new Date((new Date(lastMaintenanceDate ?? firstDate)).getTime() + 183 * (1000 * 60 * 60 * 24));

        nextMaintenanceDate = Math.min(nextMaintenanceDateByHours, nextMaintenanceDateByDays);
        // console.log(nextMaintenanceDateByHours, nextMaintenanceDateByDays);
    } else {
        averageHoursPerDay = 0.01
        nextMaintenanceDate = new Date((new Date(delivery.date)).getTime() + 183 * (1000 * 60 * 60 * 24));
    }


    return { averageHoursPerDay, nextMaintenanceDate };

}