import { createContext, useState, useContext } from "react";

const HelpContext = createContext()

export const HelpProvider = ({ children }) => {
    const [pageId, setPageId] = useState('');

    return (
        <HelpContext.Provider value={{ pageId, setPageId }}>
            {children}
        </HelpContext.Provider>
    );
};

export const useHelp = () => useContext(HelpContext);