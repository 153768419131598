import React, { useState, useEffect, useContext } from 'react';
import { Drawer, Button, message, Modal, Grid, Flex, Space } from 'antd';
import WorkerList from '../components/Worker/WorkerList';
import WorkerForm from '../components/Worker/WorkerForm';
import { CheckSquareOutlined, CloseSquareOutlined, PlusOutlined } from '@ant-design/icons'
import { useHttp } from '../hooks/http.hook'
import { AuthContext } from '../context/AuthContext'
const { useBreakpoint } = Grid


export const WorkerPage = () => {
    const [visible, setVisible] = useState(false);
    const [workers, setWorkers] = useState([]);
    const [modalInfo, setModalInfo] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [formInitial, setFormInitial] = useState({
        firstname: '',
        lastname: '',
        surname: '',
        title: '',
        branch: '',
        status: 'Работает'

    })
    const { loading, request } = useHttp()
    const { token, checkRole } = useContext(AuthContext)
    const isMobile = useBreakpoint().xs
    const emptyFields = {
        firstname: '',
        lastname: '',
        surname: '',
        title: '',
        branch: '',
        status: 'Работает'

    }

    useEffect(() => {
        const fetchWorkers = async () => {
            try {
                const response = await request('o/api/workers', 'GET', null, {
                    Authorization: `Bearer ${token}`
                });
                setWorkers(response);
            } catch (error) {
                console.error('Ошибка в получении workers', error);
            }
        };
        fetchWorkers();
    }, []);


    const submitForm = (formValues) => {
        if (formValues?._id) {
            handleUpdateWorker(formValues._id, formValues)
            // console.log('update worker', formValues)
        } else {
            handleAddWorker(formValues)
            console.log('add worker')
        }

    }

    const handleEditWorker = (record) => {
        setFormInitial(record)
        showDrawer()
    }

    const handleAddWorker = async (newWorker) => {
        try {
            const response = await await request('o/api/workers', 'POST', newWorker, {
                Authorization: `Bearer ${token}`
            });
            setWorkers([...workers, response]);
            message.success(`Добавлен сотрудник: ${response.lastname} ${response.firstname}`);
        } catch (error) {
            console.error('Ошибка добавления сотрудника:', error);
        }
    };

    const handleUpdateWorker = async (id, updatedWorker) => {
        try {
            const response = await request(`o/api/workers/${id}`, 'PUT', updatedWorker, {
                Authorization: `Bearer ${token}`
            });
            const updatedWorkers = workers.map((worker) => (worker._id === id ? response : worker));
            setWorkers(updatedWorkers);
            message.success('Данные обновлены!');
        } catch (error) {
            console.error('Error updating worker:', error);
            message.error('Ошибка обновления данных');
        }
    };

    const handleDeleteWorker = async (id) => {
        try {
            await request(`o/api/workers/${id}`, 'DELETE', null, {
                Authorization: `Bearer ${token}`
            });
            setWorkers(workers.filter((worker) => worker._id !== id));
            message.success('Запись удалена');
        } catch (error) {
            console.error('Error deleting worker:', error);
            message.error('Ошибка удаления записи');
        }
    };


    const onModalView = (item) => {
        let modal = {
            title: `Карточка специалиста`,
            info: (
                <>
                    <h2 style={{ marginBottom: '0' }}>{item.lastname} {item.firstname} {item.surname}</h2>
                    <div>{item.title}</div>


                    <br />

                    <div><span style={{ fontWeight: '500' }}>Подразделение: </span>{item.branch}</div>
                    <div><span style={{ fontWeight: '500' }}>Контактный телефон: </span>{item.phone}</div>
                    <div><span style={{ fontWeight: '500' }}>Email: </span>{item.email}</div>
                    <br />
                    <div><span style={{ fontWeight: '500' }}>Статус: </span>
                        {item.status === 'Уволен'
                            ? <CloseSquareOutlined style={{ fontSize: '16px', color: 'red' }} />
                            : <CheckSquareOutlined style={{ fontSize: '16px', color: 'green' }} />}
                        &nbsp;{item.status}
                    </div>
                </>
            )
        }
        setModalInfo(modal)
        setShowModal(true)
    }

    const hideModal = () => {
        setShowModal(false)
    }

    const showDrawer = () => {

        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
        setFormInitial(emptyFields)
    };

    return (
        <>
            {/* <div style={{ marginBottom: '16px' }}>
                <h2>Управление исполнителями</h2>
                <Button type="primary" onClick={showDrawer}>
                    + ДОБАВИТЬ
                </Button>
            </div> */}
            <Flex wrap="wrap" gap="small" align='center' justify='space-between' >
                <Space align='baseline' size={'middle'} >
                    <h2 style={{ marginBottom: 0, marginTop: 0 }}>Управление исполнителями</h2>
                    {checkRole(['admin', 'manager']) && <Button type="primary"
                        onClick={showDrawer}
                        shape={isMobile ? 'circle' : ''}
                        icon={<PlusOutlined />}
                        style={{ borderRadius: '20px' }}
                    >{isMobile ? '' : 'ДОБАВИТЬ'}</Button>}
                </Space>
            </Flex>
            <Drawer
                title="Добавление сотрудника"
                placement="right"
                closable={true}
                onClose={onClose}
                open={visible}
                width={600}
                extra={
                    !formInitial._id && <Button onClick={() => setFormInitial(emptyFields)} type="text">
                        Очиcтить форму
                    </Button>}

            >
                <WorkerForm onClose={onClose} onSubmit={submitForm} initialValues={formInitial} />
            </Drawer>
            <WorkerList workers={workers}
                isMobile={isMobile}
                onDelete={handleDeleteWorker}
                onEdit={handleEditWorker} onView={onModalView} loading={loading} />
            <Modal
                title={modalInfo.title}
                centered
                open={showModal}
                onOk={hideModal}
                onCancel={hideModal}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                {modalInfo.info}
            </Modal>
        </>
    );
};


