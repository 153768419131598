import { Button, List } from 'antd'
import Link from 'antd/es/typography/Link'
import React from 'react'


export const ClientList = ({ equipment, handleOpenDrawer, openPassport }) => {
    return (
        <div style={{
            marginTop: 12,
            maxHeight: 665,
            overflow: 'auto',
            marginBottom: 16,
            backgroundColor: '#f9f9f9',
        }}>
            <List
                itemLayout="horizontal"
                dataSource={equipment}
                renderItem={(item, index) => (
                    <List.Item
                        className='listItem'
                        key={item._id}
                        onDoubleClick={() => openPassport(item)}
                    >
                        <List.Item.Meta
                            style={{ marginLeft: '10px' }}
                            avatar={index + 1}
                            title={<Link onClick={() => openPassport(item)}>Паспорт системы {item.passport}</Link>}
                            description={(
                                <>
                                    <div>{item.installation}</div>
                                    <div>Монтаж системы: {new Date(item.instaDate).toLocaleDateString()}</div>
                                    <div
                                        style={{ color: '#626262' }}
                                    >Следующее ТО: {new Date(item.nextMaintenance).toLocaleDateString()}
                                        &nbsp; - {
                                            Math.round((new Date(item.nextMaintenance) - new Date()) / (1000 * 60 * 60 * 24)) < 0
                                                ? <span style={{ color: 'red' }}>просрочено {-Math.round((new Date(item.nextMaintenance) - new Date()) / (1000 * 60 * 60 * 24))} дн.</span>
                                                : `через ${Math.round((new Date(item.nextMaintenance) - new Date()) / (1000 * 60 * 60 * 24))} дн.`
                                        }
                                    </div>
                                </>
                            )}
                        />
                        {item.maintenance &&
                            <Button type="primary" onClick={() => handleOpenDrawer(item)}
                                className='noRadius'
                                style={{ marginRight: 12, height: 50 }}
                            >
                                Заказать ТО
                            </Button>}
                    </List.Item>
                )}>
            </List>
        </div>
    )
}