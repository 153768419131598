
import React from 'react'
import { List, Space } from 'antd'
export const ClientHistory = ({ actions, handleOpenPassport }) => {
    return (<>
        <Space
            direction='vertical'
            size={0}
            style={{
                height: 45,
                width: '100%',
                backgroundColor: 'white',
                fontSize: 16,
                fontWeight: 700,
                justifyContent: 'center',
                paddingLeft: 10,
                marginTop: 10,
            }}
        >
            Последние события
        </Space>
        {/* <h3 style={{
            marginLeft: 10
        }}>
            Последние события</h3> */}
        <div style={{
            paddingLeft: 10,
            maxHeight: 386,
            overflow: 'auto',
            marginBottom: 16,
            backgroundColor: '#f9f9f9',
        }}>

            <List
                itemLayout="horizontal"
                dataSource={actions}
                renderItem={(item, index) => (
                    <List.Item

                    >
                        <List.Item.Meta
                            // avatar={index + 1}
                            title={item.name}
                            description={(
                                <>
                                    <div>{item.equipment}</div>
                                    <div>{item.location}</div>
                                    {item?.hours && <div>Наработка: {item?.hours} м.ч.</div>}
                                    {item?.description && <div>{item?.description}</div>}
                                </>
                            )}
                            onClick={handleOpenPassport(item)}
                        />
                        <div
                            style={{
                                marginRight: 8,
                            }}
                        >{new Date(item.date).toLocaleDateString('ru-RU')}</div>
                    </List.Item>
                )}>


            </List>
        </div>
    </>
    )
}