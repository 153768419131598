import React, { useCallback, useEffect, useState } from 'react';
import { Button, Typography } from 'antd';
import { ForgotPasswordForm, ResetPasswordForm } from './ForgotPasswordForm';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useHttp } from '../../hooks/http.hook';


const { Title } = Typography;

export const ForgotPasswordPage = () => {
    return (
        <div style={{ maxWidth: 400, margin: 'auto', paddingTop: 50 }}>
            <Title level={3}>Восстановление пароля</Title>
            <ForgotPasswordForm />
        </div>
    );
};

export const ResetPasswordPage = () => {
    const [searchParams] = useSearchParams();
    const [avail, setAvail] = useState(false);

    useEffect(() => {
        const checkToken = async () => {
            const token = searchParams.get('token')
            const response = await fetch(`o/api/token/${token}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json()
            setAvail(data.token == token);
        }
        if (!searchParams.get('token')) {
            window.location.href = '/';
        }
        checkToken()
    }, []);



    return avail
        ? (<>
            <div style={{ maxWidth: 400, margin: 'auto', paddingTop: 50 }}>
                <Title level={4}>Восстановление пароля</Title>
                <ResetPasswordForm resetToken={searchParams.get('token')} />
            </div>
        </>) : (<>
            <div style={{ maxWidth: 400, margin: 'auto', paddingTop: 50, textAlign: 'center' }}>
                <Title level={5}>Срок действия ссылки истёк</Title>

            </div >
        </>)

}