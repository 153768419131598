import { Form, Spin, Row, Col, Space, Segmented, AutoComplete, DatePicker, TreeSelect, Select, Popover, message, Input, InputNumber, Button, Divider, Drawer } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { DataContext } from '../../context/DataContext';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs'
import { AuthContext } from '../../context/AuthContext';
import { useHttp } from '../../hooks/http.hook';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { MachinesForm } from '../Machines';

const { Option } = Select;

export const BulkTaskForm = ({ machines, setMachines, workers, onFinish }) => {

    const [title, setTitle] = useState('')
    const [customer, setCustomer] = useState('')
    const [paymentOption, setPaymentOption] = useState([])
    const [selectedContract, setSelectedContract] = useState({})
    const [contractValue, setContractValue] = useState([]);
    const [contractTypes, setContractTypes] = useState([])
    const [selectedTransport, setSelectedTransport] = useState('empty')
    const [carOptions, setCarOptions] = useState([])
    const [optionsBranch, setOptionsBranch] = useState([])
    const [workerItems, setWorkerItems] = useState([])
    const [selectedMachines, setSelectedMachines] = useState([])
    const [contractItems, setContractItems] = useState([])
    const [machinesList, setMachinesList] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [edValues, setEdValues] = useState({})

    const [isDrawerVisible, setIsDrawerVisible] = useState(false)
    const { request } = useHttp()
    const { token } = useContext(AuthContext)

    const { customers, contracts, branches, loadContracts, settings } = useContext(DataContext)
    const [bulkTaskForm] = Form.useForm()

    // const onFinish = (values) => {
    //     console.log('onFinish', values)
    // }

    const handleDeleteMachine = (ind) => {
        setSelectedMachines(selectedMachines.filter((el, i) => i !== ind))
    }

    const checkMachine = (e, i) => {
        // console.log(e, i)
        if (e === 'newmachine') {
            setEdValues({
                owner: bulkTaskForm.getFieldValue('customer'),
                operationHours: [],
                garageNumber: '',
                serialNumber: '', //new
                location: bulkTaskForm.getFieldValue('jobSite') ?? '',
            })
            setIsDrawerVisible(true)
        } else {
            let updated
            selectedMachines[i]
                ? updated = selectedMachines.map((el, ind) => ind === i ? e : el)
                : updated = [...selectedMachines, e]

            setSelectedMachines(updated)
        }
        // console.log(e, selectedMachines, JSON.stringify(selectedMachines).includes(e))
    }



    useEffect(() => {
        setIsSubmitting(selectedMachines?.length > 0)
        // console.log(selectedMachines)
        // console.log(bulkTaskForm.getFieldsValue())
    }, [selectedMachines])

    const checkWorkers = () => { }

    const handleDateChange = () => { }

    const transportOptions = [
        { label: 'Без выезда', value: 'empty', _id: 'empty' },
        { label: 'Сервисный автомобиль', value: 'auto', _id: 'auto' },
        { label: 'Авиа, ж/д, автобус', value: 'transport', _id: 'transport' },
    ]

    const handleSelectBranch = (value) => {
        if (value) {
            let filteredWorkers = workers.filter(el => el.branch === value).filter(el => el.status !== 'Уволен')
            if (workers?.length > 0 && filteredWorkers.length === 0) {
                setWorkerItems([])
            } else {
                setWorkerItems(filteredWorkers)
            }
        } else {
            setWorkerItems(workers.filter(el => el.status !== 'Уволен'))
        }
    }

    const onFinishNewMachine = async (values) => {
        // submit new machine
        try {
            let response = await request('/o/api/machines', 'POST', values, {
                Authorization: `Bearer ${token}`
            })
            let data = [...machines, response]
            setMachinesList([...machinesList, response]) ///
            setMachines(data)
            setMachinesList([...data.filter(el => el?.owner?._id === bulkTaskForm.getFieldValue('customer')), {
                _id: 'newmachine',
                model: 'Добавление новой техники',
            }])

            setSelectedMachines(pre => [...pre, response])
            //
            const mach = bulkTaskForm.getFieldValue(['machines']) || []; // Get the current machines array from the form
            // console.log('onFinishNewMachine', mach)

            const updatedMachines = mach.map((machine, index) =>
                machine?.machine === 'newmachine'
                    ? {
                        machine: `${response?._id}`,
                        jobSite: response?.location
                    }
                    : machine
            );
            bulkTaskForm.setFieldsValue({ machines: updatedMachines });
            message.info(`Техника ${response?.model} #${response?.serialNumber} создана`)
        } catch (error) {
            message.error(error.message)
        }

        setIsDrawerVisible(false)
    }

    useEffect(() => {
        setOptionsBranch(branches)
        fetchAvailableCars()
        setMachinesList([...machines.filter(el => el?.owner?._id === bulkTaskForm.getFieldValue('customer')), {
            _id: 'newmachine',
            model: 'Добавление новой техники',
        }])
        setWorkerItems(workers.filter(w => w?.status !== 'Уволен'))
        if (!contracts.length > 0) { loadContracts() }
        setContractTypes(
            Object.fromEntries(settings?.contractTypes?.map(el => [el.value, el.label]) ?? [])
        )
        bulkTaskForm.setFieldValue('priority', 'Средний')
    }, [])

    useEffect(() => {
        if (title === 'Монтаж') {
            setPaymentOption(['Платно', 'Бесплатно'])
            bulkTaskForm.setFieldValue('paymentForm', 'Платно')
        } else {
            setPaymentOption(['Платно', 'Гарантия'])
            bulkTaskForm.setFieldValue('paymentForm', 'Платно')
        }
    }, [title])

    const resetWorkerFilter = () => {
        setWorkerItems([...workers])
    }

    useEffect(() => {
        let cv = contractItems.map(el =>
        ({
            key: el._id, value: el._id, label: (
                <>Дог.{el.title} от {new Date(el.startDate).toLocaleDateString('ru-RU')}
                    <span style={{
                        color: 'grey', fontStyle: 'italic'
                    }}>&nbsp;{contractTypes[el?.type]}</span>
                </>
            ),
            name: `Дог.${el.title}`,
            parent: !!el.parent,
            children: el.children.map(c => ({
                key: c._id,
                value: c._id,
                parent: `Дог.${el.title}`,
                label: `д/с ${c.title} от ${new Date(c.startDate).toLocaleDateString('ru-RU')}`
            }))
        })).filter(el => !el.parent)
        setContractValue(cv)
    }, [contractItems])

    const fetchAvailableCars = async () => {
        try {

            const response = await request('o/api/servicecar', 'GET', null, {
                Authorization: `Bearer ${token}`
            });
            if (response) {
                setCarOptions(response.map(el => ({ key: el._id, value: el._id, label: `${el.title} гос.№: ${el.plateNumber}` })));
            }
        } catch (error) {
            console.error('Error fetching available machine models:', error);
            message.error('Failed to fetch available machine models.');
        }

    }


    const taskOptions = [
        { value: 'Монтаж' },
        { value: 'Подключение' },
        { value: 'Осмотр' },
        { value: 'Диагностика' },
        { value: 'Дефектовка' },
        { value: 'Ремонт' },
        { value: 'Техническое обслуживание' },
        { value: 'Перезарядка' },
        { value: 'Отключение' },
    ]

    const handleChangeCustomer = (e) => {
        setCustomer(e)
        let filtered = contracts.filter(el => el?.customer?._id === e)
        setContractItems(filtered ?? [])
        bulkTaskForm.setFieldValue('contract', '')
        bulkTaskForm.setFieldValue('machine', '')
        bulkTaskForm.setFieldValue('lastOperationHours', '')
        setMachinesList([...machines.filter(el => el?.owner?._id === bulkTaskForm.getFieldValue('customer')), {
            _id: 'newmachine',
            model: 'Добавление новой техники',
        }])
    }

    return (
        <div>
            <Form
                form={bulkTaskForm}
                name="bulktask_form"
                onFinish={onFinish}
                layout="vertical"
            >
                <Row gutter={16} justify="space-between">
                    <Col span={4}>
                        <Space direction='vertical' style={{ marginBottom: '1rem' }}>
                            <Form.Item
                                name="paymentForm"
                                id='paymentForm'
                                style={{ marginBottom: '0px' }}
                            >
                                <Segmented
                                    value={'Платно'}
                                    size='small'
                                    options={paymentOption}
                                />
                            </Form.Item>
                            <div style={{ fontSize: '10px', textAlign: 'center' }}>Оплата</div>
                        </Space>
                    </Col>
                    <Col>
                        <Space direction='vertical' >
                            <Form.Item
                                name="priority"
                                id='priority'
                                style={{ marginBottom: '0px' }}
                            >
                                <Segmented
                                    value={'Средний'}
                                    size='small'
                                    options={['Высокий', 'Средний', 'Низкий']}
                                />
                            </Form.Item>
                            <div style={{ fontSize: '10px', textAlign: 'center' }}>Приоритет</div>
                        </Space>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="taskTitle"
                            label="Тип задания"
                            rules={[{ required: true, message: 'Выберите из списка или введите новый тип' }]}
                        >
                            <AutoComplete
                                placeholder="Тип задания для исполнителей"
                                options={taskOptions}
                                onChange={(e) => setTitle(e)}
                            />

                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="startDateFinishDate"
                            label="Срок выполнения"
                            rules={[{ required: true, message: 'Выберите дату начала и завершения' }]}
                        >
                            <DatePicker.RangePicker
                                format={'DD.MM.YYYY'}
                                popupClassName='rangeDatePicker'
                                onChange={handleDateChange}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col sm={12} xs={24}>
                        <Form.Item
                            name="customer"
                            label="Заказчик"
                            rules={[{ required: true, message: 'Выберите заказчика' }]}
                        >
                            <Select onChange={handleChangeCustomer}
                                showSearch
                                allowClear
                                filterOption={(input, option) => (option?.children.join() ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {customers.map(customer => (
                                    <Option key={customer._id} value={customer._id}>{customer.name} ИНН: {customer.inn}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col sm={12} xs={24}>
                        <Form.Item name="contract"
                            label={
                                selectedContract?.parent
                                    ? <>Договор&nbsp;
                                        <Link style={{ fontWeight: 600 }}>
                                            <Popover
                                                content={selectedContract?.fullName}
                                                trigger='hover'
                                            >
                                                {selectedContract?.parent}
                                            </Popover>
                                        </Link></>
                                    : <>Договор/соглашение</>
                            }>
                            <TreeSelect
                                // disabled={dis}
                                showSearch
                                // value={selectedContract}
                                style={{ width: '100%', }}
                                dropdownStyle={{
                                    maxHeight: 400,
                                    overflow: 'auto',
                                }}
                                placeholder="Выберите договор"
                                allowClear
                                treeDefaultExpandAll
                                treeData={contractValue}
                                treeLine={true}
                                onSelect={(_, item) => {
                                    setSelectedContract(item)
                                }}


                            >
                            </TreeSelect>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            name={["taskTransport", 'transportType']}
                            label="Вид транспорта для выезда"

                        >
                            <Select
                                defaultValue={selectedTransport}
                                placeholder="Вид транспорта для выезда"
                                options={transportOptions}
                                onChange={(_, v) => setSelectedTransport(v._id)}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        {selectedTransport === 'empty' ?
                            (<></>)
                            : selectedTransport === 'auto' ? (
                                <Form.Item
                                    name={["taskTransport", 'serviceCar', '_id']}
                                    label="Выберите сервисный автомобиль"
                                    rules={[{ required: true, message: 'Выберите сервисный автомобиль' }]}
                                >
                                    <Select
                                        options={carOptions}
                                    />
                                </Form.Item>
                            )
                                : (
                                    <Form.Item
                                        name={["taskTransport", 'travelDateTime']}
                                        label="Дата и время выезда"
                                        rules={[{ required: false, message: 'Выберите дату и время выезда' }]}
                                    >
                                        <DatePicker
                                            format={'DD.MM.YYYY HH:mm'}
                                            showTime
                                            // style={{ width: "100%" }}
                                            value={dayjs(new Date())}
                                        />
                                    </Form.Item>
                                )
                        }
                    </Col>
                </Row>
                <Form.Item
                    name="contactPerson"
                    label="Контактное лицо в месте проведения работ"
                    rules={[{ required: false, message: 'Укажите ФИО и контактный телефон' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="taskDescription"
                    label="Описание задания"
                >
                    <Input.TextArea rows={3} />
                </Form.Item>
                <Row gutter={8}>
                    <Col sm={6} xs={24}>
                        <Form.Item
                            name="branch"
                            label="Подразделение"
                            rules={[{ required: false, message: 'Выберите подраздление' }]}
                        >
                            <AutoComplete
                                options={optionsBranch}
                                allowClear
                                placeholder="Выберите подразделение"
                                onChange={handleSelectBranch}
                                filterOption={(inputValue, option) =>
                                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                } />
                        </Form.Item>
                    </Col>
                    <Col sm={18} xs={24}>
                        <Form.Item
                            name="workers"
                            label="Исполнители"
                            onDoubleClick={() => resetWorkerFilter()}
                            rules={[{ required: true, message: 'Выберите исполнителей' }]}
                        >
                            <Select
                                mode="multiple"
                                placeholder="Укажите исполнителей"
                                filterOption={(input, option) => (option?.children.join() ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                onChange={checkWorkers}>
                                {workerItems.map(worker => (
                                    <Option key={worker?._id} value={worker?._id}>{worker?.lastname} {worker?.firstname} {worker?.surname}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                {customer !== '' && <>
                    <p>Техника</p>
                    <Form.List name="machines">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }, ind) => (
                                    <React.Fragment key={key}>
                                        <Row gutter={8} key={`${key}-row`} align="middle" justify={'space-between'}>
                                            <Col sm={13} xs={24} key={`${key}-col1`}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'machine']}
                                                    rules={[{ required: true, message: '' }]}
                                                    style={{ marginBottom: 5 }}
                                                >
                                                    <Select
                                                        showSearch
                                                        // allowClear
                                                        optionFilterProp="children"
                                                        placeholder="Выберите технику"
                                                        filterOption={(input, option) =>
                                                            (option?.title ?? '').toLowerCase().includes(input.toLowerCase())
                                                        }
                                                        onChange={(value, option) => {
                                                            const jobSite = option?.jobSite || ''
                                                            checkMachine(value, ind); // Call your existing checkMachine function
                                                            const machines = bulkTaskForm.getFieldValue(['machines']) || []; // Get the current machines array from the form
                                                            const updatedMachines = machines.map((machine, index) =>
                                                                index === ind ? { ...machine, jobSite } : machine
                                                            );
                                                            bulkTaskForm.setFieldsValue({ machines: updatedMachines }); // Set the updated machines array back to the form

                                                        }}
                                                        rules={[{ required: true, message: '' }]}
                                                    >
                                                        {machinesList.map((machine) => (
                                                            <Option
                                                                disabled={JSON.stringify(selectedMachines).includes(machine._id)}
                                                                key={machine?._id}
                                                                value={machine?._id}
                                                                title={`${machine?.brand} ${machine?.model} ${machine?.serialNumber}`}
                                                                hours={machine?.lastHours}
                                                                garageNumber={machine?.garageNumber}
                                                                serialNumber={machine?.serialNumber} // new
                                                                jobSite={machine?.location}
                                                            >
                                                                {machine?.brand} {machine?.model}
                                                                {machine?.serialNumber ? `, сер.№: ${machine?.serialNumber}` : ''}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col sm={9} xs={18} key={`${key}-col2`}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'jobSite']}
                                                    style={{ marginBottom: 5 }}
                                                    rules={[{ required: true, message: '' }]}
                                                >
                                                    <Input placeholder="Место выполнения работ"
                                                        allowClear
                                                    />
                                                </Form.Item>
                                            </Col>
                                            {/* <Col sm={6} xs={24} key={`${key}-col2`}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'serialNumber']}
                                                    style={{ marginBottom: 5 }}
                                                    rules={[{ required: false, message: 'Пожалуйста, введите серийный номер' }]}
                                                >
                                                    <Input placeholder="Серийный номер" />
                                                </Form.Item>
                                            </Col>
                                            <Col sm={3} xs={18} key={`${key}-col3`}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'garageNumber']}
                                                    style={{ marginBottom: 5 }}
                                                    rules={[{ required: false, message: 'Пожалуйста, введите гаражный номер' }]}
                                                >
                                                    <Input placeholder="Гар.№" />
                                                </Form.Item>
                                            </Col> */}

                                            <Col sm={2} xs={6} key={`${key}-col4`}>
                                                <Popover
                                                    content={"Удалить запись о технике"}
                                                // title="Удалить запись о технике"
                                                >
                                                    <Button
                                                        danger
                                                        onClick={(e, el) => {
                                                            remove(name)
                                                            handleDeleteMachine(ind)
                                                        }}
                                                        style={{ marginBottom: 5, width: '100%', color: 'white' }}
                                                    >
                                                        <CloseOutlined />
                                                    </Button>
                                                </Popover>
                                            </Col>
                                        </Row>
                                        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                                    </React.Fragment>
                                ))}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        Добавить технику
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </>}

                {/* <p>Раздел находится в разработке <Spin /></p> */}
                <Divider />
                <Form.Item>
                    <Button
                        type={isSubmitting ? "primary" : 'default'}
                        htmlType="submit"
                        disabled={!isSubmitting}
                        style={{ width: 250 }}
                    >Создать задания</Button>
                </Form.Item>
            </Form>
            <Drawer
                title="Добавление новой машины"
                placement="right"
                onClose={() => {
                    const mach = bulkTaskForm.getFieldValue(['machines']) || []; // Get the current machines array from the form
                    const updatedMachines = mach.filter((machine) => machine?.machine !== 'newmachine');
                    bulkTaskForm.setFieldsValue({ machines: updatedMachines });
                    setIsDrawerVisible(false)
                }}
                open={isDrawerVisible}
                destroyOnClose={true}
                width={660}
            >
                <MachinesForm onFinish={onFinishNewMachine}
                    edValues={edValues}
                    setEdValues={setEdValues}
                    forma={'TaskForm'}
                    machines={machines}
                    version={'newmachinetask'} />
            </Drawer>
        </div>
    )

}