import { Form, Input, Button, Space, Select } from 'antd'
import React, { useContext } from 'react'
import {PlusOutlined, MinusCircleOutlined} from '@ant-design/icons';
import { useHttp } from '../hooks/http.hook';
import { AuthContext } from '../context/AuthContext';


export const FormCreatePage = () => {

    const { loading, request } = useHttp()
    const { token } = useContext(AuthContext)

    const onFinish = (values) => {
        submitForm(values)
    }

    const submitForm = async (values) => {
        const response = await request('/o/api/formSettings', 'POST', { values }, { Authorization: `Bearer ${token}`} )
        console.log(response)
    }

    return (
        <div style={{marginBottom: 0 + 'px'}}>
              <Form
                onFinish={onFinish}
                style={{width: '700px'}}
                width={500}
              >
                    <Form.Item 
                        label="Form title" 
                        name="title" 
                        rules={[{ required: true, message: 'Form title required' }]}>
                        <Input />
                    </Form.Item>

                    <Form.List name={"fields"} style={{width:'100%'}}>
                        {(fields, {add, remove}) => (
                            <>
                            {fields.map((field, index) => {
                                return (
                                    <Space key={field.key} direction='horizontal' size={12} >
                                        <Form.Item 
                                            label={`${index + 1}-field`}//{Object.keys(field)[index]} 
                                            name={[field.name, "label"]} 
                                            rules={[{ required: true, message: 'Field label required' }]}
                                        >
                                            <Input placeholder="label name" />
                                        </Form.Item>
                                        <Form.Item 
                                            name={[field.name, "type"]}
                                            rules={[{ required: true, message: 'Field type required' }]}
                                        >
                                            <Select placeholder="type">
                                                {["text", "select"].map((typ)=>{
                                                    return <Select.Option value={typ} key={typ}>{typ}</Select.Option>
                                                })}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item 
                                            name={[field.name, "opt"]} 
                                            rules={[{ required: true, message: 'Is required' }]}
                                        >
                                            <Input placeholder="Input db model or none" />
                                        </Form.Item>
                                        <MinusCircleOutlined 
                                            style={{ fontSize: 20, color: 'red' }}
                                            onClick={()=>{
                                                remove(field.name)
                                            }}
                                        />
                                    </Space>
                                )
                            })}

                        <Form.Item>
                            <Button icon={<PlusOutlined />} type="dashed" block onClick={()=>{
                                    add();
                                }}>
                                Add Field
                            </Button>
                        </Form.Item>
                        </>
                        )}
                    </Form.List>
                    
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
        </div>
    )
}